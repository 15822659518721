import React from "react";
import { Route, Redirect } from "react-router-dom";
import { AuthorizationService } from "./auth";

function PrivateRoute({ component: Component, exact, strict, path, ...rest }) {
  return (
    <Route
      exact={exact}
      strict={strict}
      path={path}
      render={props =>
        AuthorizationService.isAuthenticated() ? (
          <Component {...props} {...rest} />
        ) : (
          <Redirect to="/login" />
        )
      }
    />
  );
}

export default PrivateRoute;
