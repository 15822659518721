export const SIGN_IN = "SIGN_IN";
export const SIGN_OUT = "SIGN_OUT";

export const FETCH_USER = "FETCH_USER";

export const FETCH_QUOTE_BY_ID = "FETCH_QUOTE_BY_ID";
export const CREATE_QUOTE = "CREATE_QUOTE";
export const UPDATE_QUOTE = "UPDATE_QUOTE";
export const UPDATE_QUOTE_LOCK = "UPDATE_QUOTE_LOCK";
export const DELETE_QUOTE = "DELETE_QUOTE";

export const FETCH_QUOTES = "FETCH_QUOTE";
export const FETCH_QUOTE_VERSIONS = "FETCH_QUOTE_VERSIONS";
export const FETCH_REVISED_QUOTES = "FETCH_REVISED_QUOTES";
export const FETCH_QUOTES_BY_USERID = "FETCH_QUOTES_BY_USERID";
export const FETCH_REVISED_QUOTES_BY_USERID = "FETCH_REVISED_QUOTES_BY_USERID";
export const FETCH_QUOTES_BY_USERID_AND_APPROVED =
  "FETCH_QUOTES_BY_USERID_AND_APPROVED";
export const QUOTE_LOCK_CHECK = "QUOTE_LOCK_CHECK";
export const LATEST_QUOTATION_NUMBER = "LATEST_QUOTATION_NUMBER";

export const FETCH_TEMPLATE_BY_ID = "FETCH_TEMPLATE_BY_ID";
export const FETCH_ITEM_TEMPLATES = "FETCH_ITEM_TEMPLATES";
export const FETCH_STEP_TEMPLATES = "FETCH_STEP_TEMPLATES";
export const FETCH_TERMS_TEMPLATES = "FETCH_TERMS_TEMPLATES";
export const FETCH_TEMPLATES = "FETCH_TEMPLATES";
export const CREATE_TEMPLATE = "CREATE_TEMPLATE";
export const UPDATE_TEMPLATE = "UPDATE_TEMPLATE";
export const DELETE_TEMPLATE = "DELETE_TEMPLATE";

export const SHOW_HIDE_TOPNAV = "SHOW_HIDE_TOPNAV";
export const SHOW_HIDE_SIDEBAR = "SHOW_HIDE_SIDEBAR";
export const SHOW_HIDE_FOOTER = "SHOW_HIDE_FOOTER";
export const SHOW_HIDE_DISCOUNT_PERCENTAGE = "SHOW_HIDE_DISCOUNT_PERCENTAGE";


export const FILTER_SEARCH = "FILTER_SEARCH";
export const FILTER_ARRAY = "FILTER_ARRAY";
export const FILTER_RESET = "FILTER_RESET";
export const FILTER_INIT = "FILTER_INIT";


export const FETCH_USERS = "FETCH_USERS";
export const UPDATE_USER = "UPDATE_USER";
export const FETCH_USER_FROM_DB = "FETCH_USER_FROM_DB";
export const FETCH_USER_SETTINGS = "FETCH_USER_SETTINGS";
export const UPDATE_USER_SETTINGS = "UPDATE_USER_SETTINGS";

export const SEND_EMAIL = "SEND_EMAIL";
export const FETCH_CURRENCIES = "FETCH_CURRENCIES";

