import React from 'react';
import axios from 'axios';
import Title from '../components/Title';
//redux
import { connect } from 'react-redux';
import { saveUser, fetchUser, fetchUserFromDB } from '../../actions';

//components
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Loading from '../components/Loading';

class UserEdit extends React.Component {
    state = {
        user: this.props.user,
        authenticated: this.props.auth,
        isLoaded: false,
        file: '',
        imagePreviewUrl: '',
        saved: false
    };
    async componentDidMount() {
        await this.props.fetchUser();
        if (this.props.user.id !== -1) {
            this.setState({
                user: this.props.user,
                isLoaded: true
            });
        }
    }
    handleSubmitFile = async (event) => {
        event.preventDefault();

        console.log('handle uploading-', this.state.file);
        const data = new FormData();
        data.append('file', this.state.file);
        const image_upload = await axios.post(
            process.env.REACT_APP_API + 'images/upload.php',
            data
        );
        const response = image_upload.data;
        if (response.status === 'success') {
            let userTmp = { ...this.state.user };
            userTmp.signature.url =
                process.env.REACT_APP_API + '/images/signatures/' + response.filename;
            this.setState({
                user: userTmp
            });
        }
    };

    handleImageChange(e) {
        e.preventDefault();

        let reader = new FileReader();
        let file = e.target.files[0];

        reader.onloadend = () => {
            this.setState({
                file: file,
                imagePreviewUrl: reader.result
            });
        };

        reader.readAsDataURL(file);
    }

    handleChange = (event) => {
        let name = event.target.id;
        let userTmp = { ...this.state.user };
        userTmp[name] = event.target.value;
        this.setState({
            user: userTmp
        });
    };

    handleChangeSignatureSize = (e) => {
        let name = e.target.id;
        let signatureTmp = {...this.state.user.signature}
        if(e.target.value !== "") {
            signatureTmp[name] = parseInt(e.target.value);
        }
        else {
            signatureTmp[name] = ""
        }
        let userTmp = { ...this.state.user };
        userTmp.signature = signatureTmp;
        this.setState({
            user: userTmp
        });
    }

    handleSave = async (event) => {
        event.preventDefault();
        await this.props.saveUser(this.state.user);
        console.log(this.props.updated_user)
        if (this.props.updated_user) {
            this.setState({
                saved: true
            });
            this.props.fetchUserFromDB(this.state.user.id);
        }
    };

    render() {
        let { user, isLoaded, saved } = this.state;

        let imagePreviewUrl = this.state.user.signature.url
            ? this.state.user.signature.url
            : this.state.imagePreviewUrl;
        let $imagePreview = null;
        if (imagePreviewUrl) {
            $imagePreview = <img alt="" style={{height: this.state.user.signature.height === 0 ? "auto" : this.state.user.signature.height + "px"}} src={imagePreviewUrl} />;
        } else {
            $imagePreview = <div className='previewText'>Please select an Image for preview</div>;
        }

        if (!isLoaded) {
            return <Loading gif='/images/stepworks.gif' message='Loading..' />;
        } else {
            return (
                <div className='container-fluid'>
                    <Title title='Edit account' />
                    <div className='card shadow'>
                        <div className='card-body'>
                            <div className='row'>
                                <div className='col-lg-12'>
                                    <div className='p-5'>
                                        <div className='text-center'>
                                            {saved && 'User has been succesfully saved!'}
                                            <br />
                                        </div>
                                        <form className='user'>
                                            <div className='form-group row'>
                                                <div className='col-sm-6 mb-3 mb-sm-0'>
                                                    <input
                                                        type='text'
                                                        className='form-control form-control-user'
                                                        id='firstname'
                                                        placeholder='First Name'
                                                        value={user.firstname}
                                                        onChange={this.handleChange}
                                                    />
                                                </div>
                                                <div className='col-sm-6'>
                                                    <input
                                                        type='text'
                                                        className='form-control form-control-user'
                                                        id='lastname'
                                                        placeholder='Last Name'
                                                        value={user.lastname}
                                                        onChange={this.handleChange}
                                                    />
                                                </div>
                                            </div>
                                            <div className='form-group row'>
                                                <div className='col-sm-6 mb-3 mb-sm-0'>
                                                    <input
                                                        type='text'
                                                        className='form-control form-control-user'
                                                        id='email'
                                                        placeholder='Email'
                                                        value={user.email}
                                                        onChange={this.handleChange}
                                                    />
                                                </div>
                                                <div className='col-sm-6'>
                                                    <input
                                                        type='text'
                                                        className='form-control form-control-user'
                                                        id='tel'
                                                        placeholder='Telephone number'
                                                        value={user.tel}
                                                        onChange={this.handleChange}
                                                    />
                                                </div>
                                            </div>
                                            <div className='form-group row'>
                                                <div className='col-sm-6 mb-3 mb-sm-0'>
                                                    <input
                                                        type='text'
                                                        className='form-control form-control-user'
                                                        id='jobtitle'
                                                        placeholder='Job Title'
                                                        value={user.jobtitle}
                                                        onChange={this.handleChange}
                                                    />
                                                </div>
                                                <div className='col-sm-6'>
                                                    <input
                                                        type='text'
                                                        className='form-control form-control-user'
                                                        id='exampleLastName'
                                                        placeholder='Telephone number'
                                                        value={
                                                            user.permission === '1' ? (
                                                                'Administrator'
                                                            ) : (
                                                                'Account Manager'
                                                            )
                                                        }
                                                        disabled
                                                    />
                                                </div>
                                            </div>
                                            <br />
                                            {/* <h3>Change password</h3>
                                            <div className='form-group row'>
                                                <div className='col-sm-6 mb-3 mb-sm-0'>
                                                    <input
                                                        type='password'
                                                        className='form-control form-control-user'
                                                        id='oldPassword'
                                                        placeholder='Old Password'
                                                    />
                                                </div>
                                            </div>
                                            <div className='form-group row'>
                                                <div className='col-sm-6 mb-3 mb-sm-0'>
                                                    <input
                                                        type='password'
                                                        className='form-control form-control-user'
                                                        id='password'
                                                        placeholder='Password'
                                                        onChange={this.handleChange}
                                                    />
                                                </div>
                                                <div className='col-sm-6'>
                                                    <input
                                                        type='password'
                                                        className='form-control form-control-user'
                                                        id='repeatPassword'
                                                        placeholder='Repeat Password'
                                                    />
                                                </div>
                                            </div>
                                            <br /> */}
                                        </form>
                                        <h3>Signature</h3>
                                        <div className='form-group row'>
                                            <div className='col-sm-6 mb-3 mb-sm-0'>
                                                <div className='previewComponent'>
                                                    <form
                                                        onSubmit={(e) => this.handleSubmitFile(e)}>
                                                        <input
                                                            className='fileInput'
                                                            type='file'
                                                            onChange={(e) =>
                                                                this.handleImageChange(e)}
                                                        />
                                                        <button
                                                            className='submitButton'
                                                            type='submit'>
                                                            Upload Image
                                                        </button>
                                                    </form>
                                                    <br />
                                                    <div
                                                        className='imgPreview'
                                                        style={{ width: user.signature ? user.signature.width + "px": '120px'}}>
                                                        {$imagePreview}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='form-group row'>
                                                <div className='col-sm-6 mb-3 mb-sm-0'>
                                                    <label>Signature width:</label>
                                                    <input
                                                        type='text'
                                                        className='form-control form-control-user'
                                                        id='width'
                                                        placeholder='Signature width'
                                                        value={user.signature.width}
                                                        onChange={this.handleChangeSignatureSize}
                                                    />
                                                </div>
                                                </div>
                                                <div className='form-group row'>
                                                <div className='col-sm-6'>
                                                <label>Signature height:</label>
                                                    <input
                                                        type='text'
                                                        className='form-control form-control-user'
                                                        id='height'
                                                        placeholder='Signature height'
                                                        value={user.signature.height}
                                                        onChange={this.handleChangeSignatureSize}
                                                    />
                                                </div>
                                            </div>
                                        <button className='float-right' onClick={this.handleSave}>
                                            <FontAwesomeIcon icon='save' />
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            );
        }
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.auth.user,
        auth: state.auth.isSignedIn,
        updated_user: state.user.updated_user
    };
};

export default connect(mapStateToProps, { saveUser, fetchUser, fetchUserFromDB })(UserEdit);
