import { combineReducers } from 'redux';
import authReducer from './authReducer';
import quoteReducer from './quoteReducer';
import templateReducer from './templateReducer';
import globalReducer from './globalReducer';
import filterReducer from './filterReducer';
import userReducer from './userReducer';
export default combineReducers({
    auth: authReducer,
    quotes: quoteReducer,
    templates: templateReducer,
    global: globalReducer,
    filter: filterReducer,
    user: userReducer
});
