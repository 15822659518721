import React from 'react';

//redux
import { connect } from 'react-redux';
import { fetchUser } from '../../actions';

//components
import { Link } from 'react-router-dom';
import Title from '../components/Title';
import Loading from '../components/Loading';

//assets
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

class UserProfile extends React.Component {
    state = {
        isLoaded: false
    };

    componentDidMount() {
        this.props.fetchUser();
    }
    componentDidUpdate(prevProps) {
        if (this.props.user !== prevProps.user) {
            this.setState({
                isLoaded: true
            });
        }
    }

    render() {
        let { isLoaded } = this.state;
        let { user } = this.props;
        if (!isLoaded) {
            return <Loading gif='/images/stepworks.gif' message='Loading..' />;
        } else {
            return (
                <div className='container-fluid'>
                    <h2 className='h3 mb-2 text-gray-800 heading'>
                        {user.firstname}, here is your{' '}
                    </h2>
                    <Title title='Profile account' />
                    <div className='card shadow'>
                        <div className='card-body'>
                            <div className='row'>
                                <div className='col-lg-12'>
                                    <div className='p-5'>
                                        <div className='text-center'>
                                            <br />
                                        </div>
                                        <Link
                                            to={{
                                                pathname: '/useredit/' + user.id
                                            }}>
                                            <button className='float-right' primary="true">
                                                <FontAwesomeIcon icon='cogs' />
                                            </button>
                                        </Link>
                                        <div className='row'>
                                            <div className='col-sm-6 mb-3 mb-sm-0'>
                                                <p>
                                                    First name: <strong>{user.firstname}</strong>
                                                </p>
                                                <p>
                                                    Last name: <strong>{user.lastname}</strong>
                                                </p>
                                                <p>
                                                    Email: <strong>{user.email}</strong>
                                                </p>
                                            </div>
                                            <div className='col-sm-6 mb-3 mb-sm-0'>
                                                <p>
                                                    Job Title: <strong>{user.jobtitle}</strong>
                                                </p>
                                                <p>
                                                    Telephone number: <strong>{user.tel}</strong>
                                                </p>
                                                <p>
                                                    Role:{' '}
                                                    <strong>
                                                        {user.permission === '1' ? (
                                                            'Administrator'
                                                        ) : (
                                                            'Account Manager'
                                                        )}
                                                    </strong>
                                                </p>
                                            </div>
                                            <div className='col-sm-12 mb-6'>
                                                <p>
                                                    Signature:
                                                    <img
                                                        style={{ width: user.signature ? user.signature.width + 'px' : "120px"}}
                                                        src={user.signature.url}
                                                        alt='User&#39;s signature'
                                                    />
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            );
        }
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.auth.user
    };
};

export default connect(mapStateToProps, { fetchUser })(UserProfile);
