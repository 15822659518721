import React from "react";
import { commaFormatting, commaFormattingWithDecimals } from "../../../utils/commaformatting";

class TotalsSection extends React.Component {
    render() {
      const { title, excl, currencyCode, amount, discountCopy, entity } = this.props;
  
      return (
        <section className={`grandtotal ${this.props.className}`} style={this.props.style}>
          <div className="row">
            <h2>
              {title}&nbsp;
              <span className="excl">{excl}</span>
              <span className="price">
                <span className="currency_code">
                  {currencyCode}
                </span>
                <span>{entity === "sg" ? commaFormattingWithDecimals(amount) : commaFormatting(amount)}</span>
              </span>
            </h2> 
            {discountCopy}
          </div>
        </section>
      );
    }
  }

  export default TotalsSection;