import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { handlePercentageText } from "../../actions";
import { commaFormatting } from "../utils/commaformatting";

function Discount(props) {

  const [percentage, setPercentage] = useState(0);

  useEffect(() => {

    let discount_percentage = 0;

    let grand_total = parseFloat(props.quote.duties_percentage / 100) * parseFloat(props.quote.total) + parseFloat(props.quote.total)

    discount_percentage = (parseFloat(props.quote.discount) * 100) / grand_total

    if(discount_percentage <= 0.9 && discount_percentage > 0) {
        discount_percentage = discount_percentage.toFixed(1)
    }
    else {
        discount_percentage = discount_percentage.toFixed(0)
    }

    if(!isNaN(discount_percentage) && props.quote.discount > 0) {
        setPercentage(discount_percentage);
    }
    else {
        setPercentage(0);
    }

  }, [props.quote]);

  let handleChangePercentage = (e) => {

    let per = e.currentTarget.textContent;
    let grand_total = parseFloat(props.quote.duties_percentage / 100) * parseFloat(props.quote.total) + parseFloat(props.quote.total)

    let discount = parseFloat(per / 100) * grand_total

    props.handleChangeDiscountPercentage(discount, per);

  }

  return (
    <section className="discount">
      <div className="row">
        <div className="discount">
          <div className="">
            <h4>
            <svg onClick={() => props.handlePercentageText()} data-icon="eye-slash" className="discount_eye_icon svg-inline--fa fa-eye-slash fa-w-15" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512"><path fill="currentColor" d="M320 400c-75.85 0-137.25-58.71-142.9-133.11L72.2 185.82c-13.79 17.3-26.48 35.59-36.72 55.59a32.35 32.35 0 0 0 0 29.19C89.71 376.41 197.07 448 320 448c26.91 0 52.87-4 77.89-10.46L346 397.39a144.13 144.13 0 0 1-26 2.61zm313.82 58.1l-110.55-85.44a331.25 331.25 0 0 0 81.25-102.07 32.35 32.35 0 0 0 0-29.19C550.29 135.59 442.93 64 320 64a308.15 308.15 0 0 0-147.32 37.7L45.46 3.37A16 16 0 0 0 23 6.18L3.37 31.45A16 16 0 0 0 6.18 53.9l588.36 454.73a16 16 0 0 0 22.46-2.81l19.64-25.27a16 16 0 0 0-2.82-22.45zm-183.72-142l-39.3-30.38A94.75 94.75 0 0 0 416 256a94.76 94.76 0 0 0-121.31-92.21A47.65 47.65 0 0 1 304 192a46.64 46.64 0 0 1-1.54 10l-73.61-56.89A142.31 142.31 0 0 1 320 112a143.92 143.92 0 0 1 144 144c0 21.63-5.29 41.79-13.9 60.11z"></path></svg>
              <span
                data-ref="discount_percentage"
                contentEditable="true"
                suppressContentEditableWarning={true}
                placeholder="0"
                className={'discountcut ' + props.discountPercentageShow}
                onBlur={(e) => handleChangePercentage(e)}
              >
                {percentage}
              </span>
              <span className={'discountcut ' + props.discountPercentageShow}>%{" "}</span>
              <span
                data-ref="discount_text"
                contentEditable="true"
                suppressContentEditableWarning={true}
                placeholder={`${props.discountPercentageShow === 'visible' ? 'd' : 'D'}iscount (rounded-up)`}
                onBlur={props.handleChange}
              >
                {props.quote.discount_text && props.quote.discount_text}
              </span>
              <span className="note">
                (this line will not appear if amount is zero)
              </span>
              <span className="price">
                {" "}
                <span
                  contentEditable="true"
                  suppressContentEditableWarning={true}
                  className="number"
                  data-ref="discount"
                  placeholder="0"
                  onBlur={props.handleChangeNumber}
                  data-converted={
                    props.quote.currency.name !== "HKD"
                      ? "HKD" +
                        commaFormatting(
                          props.quote.discount / props.quote.currency.rate
                        )
                      : ""
                  }
                >
                  {commaFormatting(props.quote.discount * 1)}
                </span>
                <span className="currency_symbol" style={{ float: "right" }}>
                  {props.quote.currency.symbol}
                </span>
              </span>
            </h4>
            <p><span
            contentEditable="true"
            suppressContentEditableWarning={true}
            data-ref="discount_copy"
            className="discount_copy"
            placeholder="[Add one-time discount reason here. State ‘one-time’ to reduce the chance of the client negotiating on price next time]"
            onBlur={props.handleChange}
            >{props.quote.discount_copy && props.quote.discount_copy}</span></p>
          </div>
        </div>
      </div>
    </section>
  );
}

const mapStateToProps = state => {
  return {
    discountPercentageShow: state.global.discountPercentageShow,
  };
};

export default connect(mapStateToProps, { handlePercentageText })(Discount);
