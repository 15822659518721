import React from "react";
import { Link } from "react-router-dom";

import { months, days } from "../utils/time";

class Footer extends React.Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    let d = new Date();
    this.state = {
      day: d.getDay(),
      month: d.getMonth(),
      date: d.getDate(),
      year: d.getFullYear(),
      time: d.toLocaleTimeString()
    };
  }

  updateTime = () => {
    let d = new Date();
    if(this._isMounted) {
      this.setState({
        day: d.getDay(),
        month: d.getMonth(),
        date: d.getDate(),
        year: d.getFullYear(),
        time: d.toLocaleTimeString()
      });
    }
  };

  componentDidMount() {
    this._isMounted = true;
    setInterval(this.updateTime, 1000);
  }
  componentWillUnmount() {
    this._isMounted = false;
    clearInterval(this.updateTime);
  }

  render() {
    return (
      <div className="footer-wrapper" id="footer">
        <footer className="footer">
          <div>
            <Link
              className="sidebar-brand d-flex align-items-center justify-content-center"
              to={"/dashboard"}
            >
              <div className="stepworks_logo">
                <img src="/images/logo.jpg" alt="Stepworks logo" />
                <small>
                  Version: <strong>{process.env.REACT_APP_VERSION}</strong>
                </small>
              </div>
            </Link>
          </div>
          <div>
            <ul>
              <li>
                {days[this.state.day]}, {months[this.state.month]}{" "}
                {this.state.date}, {this.state.year} {this.state.time}
              </li>
            </ul>
          </div>
        </footer>
      </div>
    );
  }
}

export default Footer;
