import React from "react";
import { Link } from "react-router-dom";

//redux
import { connect } from "react-redux";

//assets
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

class Sidebar extends React.Component {
  render() {
    if (!this.props.isAuthenticated) {
      return <div />;
    } else {
      return (
        <div className="sidebar-sticky">
          <ul
            className="navbar-nav bg-gradient-primary sidebar sidebar-dark accordion"
            id="accordionSidebar"
          >
            <Link
              className="sidebar-brand d-flex align-items-center justify-content-center"
              to={"/dashboard"}
            >
              <div className="stepworks_logo">
                <img src="/images/logo.jpg" alt="Stepworks logo"/>
              </div>
            </Link>

            <li className="nav-item active">
              <Link className="nav-link" to={"/dashboard"}>
                <FontAwesomeIcon icon="tachometer-alt" />
                &nbsp;
                <span>Dashboard</span>
              </Link>
            </li>
            <br />
            <div className="sidebar-heading">Quote</div>
            <li className="nav-item">
              <Link className="collapse-item" to={"/quote"}>
                <FontAwesomeIcon icon="edit" /> New
              </Link>
            </li>
            <li className="nav-item">
              <Link
                className="collapse-item"
                to={"/quoteslist/" + this.props.user.id}
              >
                <FontAwesomeIcon icon="list" /> My quotes
              </Link>
            </li>
            <li className="nav-item">
              <Link
                className="collapse-item"
                to={"/quoteslist/" + this.props.user.id + "/approved"}
              >
                <FontAwesomeIcon icon="list" /> My approved
              </Link>
            </li>
            <li className="nav-item">
              <Link className="collapse-item" to={"/quoteslist"}>
                <FontAwesomeIcon icon="list" /> Collective
              </Link>
            </li>
            <br />
            <div className="sidebar-heading">Templates</div>
            {
              <li className="nav-item grey-small">
                <Link className="collapse-item" to={"/template"}>
                  <FontAwesomeIcon icon="edit" />
                  New Template
                </Link>
              </li>
            }
            <li className="nav-item grey-small">
              <Link className="collapse-item" to={"/templateslist"}>
                <FontAwesomeIcon icon="list" /> Template list
              </Link>
            </li>
            <br />
            <div className="sidebar-heading">Profile</div>

            <li className="nav-item grey-small">
              <Link className="nav-link" to={"/userprofile"}>
                <FontAwesomeIcon icon="user" />
                &nbsp;
                <span>User profile</span>
              </Link>
            </li>

            <li className="nav-item grey-small">
              <Link className="nav-link" to={"/settings"}>
                <FontAwesomeIcon icon="cogs" />
                &nbsp;
                <span>Settings</span>
              </Link>
            </li>
          </ul>
        </div>
      );
    }
  }
}
const mapStateToProps = state => {
  return {
    isAuthenticated: state.auth.isSignedIn,
    user: state.auth.user
  };
};

export default connect(mapStateToProps, {})(Sidebar);
