import React from 'react';

//components
import Loading from '../components/Loading';
import Title from '../components/Title';

//redux
import { connect } from 'react-redux';
import { fetchUserFromDB, fetchUser, saveUser } from '../../actions';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

class Settings extends React.Component {
    state = {
        isLoaded: false,
        user: this.props.user,
        saved: false
    };

    async componentDidMount() {
        await this.props.fetchUser();
        if (this.props.user.id !== -1) {
            this.setState({
                user: this.props.user,
                isLoaded: true
            });
        }
    }

    handleAutoSaveSwitch = async (event) => {
        let userTmp = { ...this.state.user };
        userTmp.settings.autosave = !userTmp.settings.autosave;
        this.setState({
            user: userTmp
        });
    };

    handleAutoSaveSeconds = async (event) => {
        let value = event.target.value;
        let userTmp = { ...this.state.user };
        userTmp.settings.autosave_interval = value;
        this.setState({
            user: userTmp
        });
    };

    handleSaveUserSettings = async (event) => {
        event.preventDefault();
        await this.props.saveUser(this.state.user);
        if (this.props.updated_user) {
            this.setState({
                saved: true
            });
            await this.props.fetchUserFromDB(this.props.user.id);
        }
    };

    render() {
        let { isLoaded, user, saved } = this.state;
        if (!isLoaded) {
            return <Loading message='Loading ...' gif='/images/stepworks.gif' />;
        } else {
            return (
                <div>
                    <Title title='Settings' />
                    {saved && 'User has been succesfully saved!'}
                    <br />
                    <div className='card shadow'>
                        <div className='card-body '>
                            <div className='row'>
                                <div className='col-lg-12'>
                                    <div className='p-5'>
                                        <div className='text-center'>
                                            <br />
                                        </div>
                                        <div className='row'>
                                            <div className='col-sm-8 mb-3 mb-sm-0'>
                                                <table>
                                                    <tbody>
                                                        <tr>
                                                            <td>Autosave: </td>
                                                            <td>
                                                                <label className='switch'>
                                                                    <input
                                                                        type='checkbox'
                                                                        checked={
                                                                            user.settings.autosave
                                                                        }
                                                                        onChange={
                                                                            this
                                                                                .handleAutoSaveSwitch
                                                                        }
                                                                    />
                                                                    <span className='slider round' />
                                                                </label>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <br />
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>Autosave interval (in seconds):</td>
                                                            <td
                                                                contentEditable={true}
                                                                suppressContentEditableWarning={
                                                                    true
                                                                }>
                                                                <input
                                                                    value={
                                                                        user.settings
                                                                            .autosave_interval
                                                                    }
                                                                    type='number'
                                                                    className='settings-input'
                                                                    onChange={
                                                                        this.handleAutoSaveSeconds
                                                                    }
                                                                />
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <button className='float-right' onClick={this.handleSaveUserSettings}>
                                <FontAwesomeIcon icon='save' />
                            </button>
                            <br />
                        </div>
                    </div>
                </div>
            );
        }
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.auth.user,
        auth: state.auth.isSignedIn,
        updated_user: state.user.updated_user
    };
};

export default connect(mapStateToProps, { saveUser, fetchUser, fetchUserFromDB })(Settings);
