import React from "react";
import ReactHtmlParser from "react-html-parser";
import ReactQuill from "react-quill";

import ItemPreview from "./ItemPreview";
import { commaFormatting } from "../../../utils/commaformatting";

class StepPreview extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      content: this.props.content,
      currency: this.props.currency,
      step_index: this.props.step_index,
      num_of_steps: this.props.num_of_steps,
    };
  }

  handleChangeQuill = (name) => (event) => {
    let contentTmp = { ...this.state.content };
    contentTmp[name] = event;
    this.setState({
      content: contentTmp,
    });
    //this.props.handleContentChange(this.state.content);
  };

  modules = {
    toolbar: [
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      [("bold", "underline")],
      ["clean"],
    ],
    clipboard: {
      matchVisual: false,
    },
  };

  render() {
    let { content, currency, num_of_steps } = this.state;
    return (
      <div id="tpl-step" className="step">
        <div>
          <div className="no_page_break">
            {num_of_steps !== 1 ? (
              <h3>
                Step{" "}
                <span className="order">{content.order}</span> -{" "}
                <span>{content.title}</span>
              </h3>
            ) : (
              content.title !== "" && (
                <h3>
                  <span>{content.title}</span>
                </h3>
              )
            )}
          </div>
          <div className="step-content">
            {content.intro.length > 15 && (
              <div className="step-intro">
                <span>
                  {this.props.compare === "true" ? (
                    ReactHtmlParser(content.intro)
                  ) : (
                    <ReactQuill
                      theme="bubble"
                      name="intro"
                      modules={this.modules}
                      value={content.intro}
                      onChange={this.handleChangeQuill}
                      placeholder="(this will not appear if empty)"
                    />
                  )}
                </span>
              </div>
            )}
            <div className="items">
              {content.blocks.map((item, i) => {
                return (
                  <ItemPreview
                    key={i}
                    step_index={this.state.step_index}
                    block={item}
                    currency={currency}
                    num_of_steps={num_of_steps}
                    compare={this.props.compare}
                  />
                );
              })}
            </div>
            {content.deliverables.length > 15 && (
              <div className="step_deliverables">
                <span className="underline">Deliverables:</span>
                <br />
                <span>
                  {this.props.compare === "true" ? (
                    ReactHtmlParser(content.deliverables)
                  ) : (
                    <ReactQuill
                      theme="bubble"
                      name="deliverables"
                      modules={this.modules}
                      value={content.deliverables}
                      onChange={this.handleChangeQuill}
                      placeholder="(this will not appear if empty)"
                    />
                  )}
                </span>
                <br />
              </div>
            )}
          </div>
          {/* <div className="no_page_break_small"> */}
            <div className="step-total">
              {num_of_steps !== 1 ? (
                <div>
                  <strong>Subtotal for Step&nbsp;</strong>
                  <strong>
                    <span className="order">{content.order}</span>&nbsp;
                  </strong>
                </div>
              ) : (
                <strong>
                  Subtotal {content.title !== "" && "for " + content.title}
                </strong>
              )}
              <span className="amount">
                <span className="currency_symbol">{currency.symbol}</span>
                <span>{commaFormatting(content.base_price)}</span>
              </span>
            </div>
          </div>
        {/* </div> */}
      </div>
    );
  }
}

export default StepPreview;
