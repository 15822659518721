import React, { Component } from "react";

//redux
import { connect } from "react-redux";
import {
  fetchQuotes,
  fetchRevisedQuotes,
  fetchQuotesByUserId,
  fetchRevisedQuotesByUserId,
  fetchQuotesByUserIdAndApproved,
  filterInit,
} from "../../actions";

//components
import Loading from "../components/Loading";
import Title from "../components/Title";
import QuoteTable from "../components/QuoteTable";
import Filter from "../layout/Filter";

//assets
import "react-table/react-table.css";

class QuoteList extends Component {
  constructor() {
    super();
    this.state = {
      isLoaded: false,
      showRevised: false,
      lockchecker: "",
      prevQuotes: [],
      isMounted: false,
    };
  }

  async componentDidMount() {
    const { id, type } = this.props.match.params;

    this.setState({
      isMounted: true,
    });

    if (id !== undefined && type === undefined) {
      await this.fetchByUserId();
    } else if (id !== undefined && type !== undefined) {
      await this.fetchByUserIdAndApproved();
    } else {
      await this.fetchAll();
    }
    this.props.filterInit(); //send quotes to filter
    this.setState({
      lockchecker: setInterval(this.checkLockedQuotes, 60000),
    });
  }

  async componentDidUpdate(prevProps) {
    const { id, type } = this.props.match.params;
    if (
      id !== prevProps.match.params.id ||
      type !== prevProps.match.params.type
    ) {
      clearInterval(this.state.lockchecker);
      this.setState({
        isLoaded: false,
      });
      if (id !== undefined && type === undefined) {
        await this.fetchByUserId();
      }
      if (id !== undefined && type !== undefined) {
        await this.fetchByUserIdAndApproved();
      }
      if (id === undefined && type === undefined) {
        await this.fetchAll();
      }
      this.props.filterInit(); //send quotes to filter
      this.setState({
        lockchecker: setInterval(this.checkLockedQuotes, 60000),
      });
    }
  }

  componentWillUnmount() {
    this.setState({
      isMounted: false,
    });
    clearInterval(this.state.lockchecker);
  }

  checkLockedQuotes = async (event) => {
    if (!this.state.showRevised) {
      if (
        this.props.match.params.id !== undefined &&
        this.props.match.params.type === undefined
      )
        this.fetchByUserId();
      else if (
        this.props.match.params.id !== undefined &&
        this.props.match.params.type !== undefined
      )
        this.fetchByUserIdAndApproved();
      else {
        this.fetchAll();
      }
    }
  };

  // quoteEquivalency(a, b) {
  //     // If lenght of array is different,
  //     // arrays are not equivalent
  //     if (a.length !== b.length) {
  //         return false;
  //     }
  //     for (var i = 0; i < a.length; i++) {
  //         //     // If values of same property are not equal,
  //         //     // objects are not equivalent
  //         if (a[i]['locked'] !== b[i]['locked']) {
  //             return false;
  //         }
  //     }
  //     // If we made it this far, objects
  //     // are considered equivalent
  //     return true;
  // }

  async fetchByUserId() {
    const { id } = this.props.match.params;
    await this.props.fetchQuotesByUserId(id);
    if (this.state.isMounted) {
      if (this.props.quotes) {
        this.setState({
          isLoaded: true,
          showRevised: false,
        });
      }
    }
  }

  async fetchByUserIdAndApproved() {
    const { id } = this.props.match.params;
    await this.props.fetchQuotesByUserIdAndApproved(id);
    if (this.state.isMounted) {
      if (this.props.quotes) {
        this.setState({
          isLoaded: true,
          showRevised: false,
        });
      }
    }
  }

  async fetchAll() {
    await this.props.fetchQuotes();
    if (this.state.isMounted) {
      if (this.props.quotes) {
        this.setState({
          isLoaded: true,
          showRevised: false,
        });
      }
    }
  }

  async fetchRevisedQuotes() {
    await this.props.fetchRevisedQuotes();
    console.log(this.props.revised_quotes);
    if (this.props.revised_quotes) {
      this.setState({
        isLoaded: true,
        showRevised: true,
      });
    }
  }

  async fetchRevisedQuotesByUserId() {
    const { id } = this.props.match.params;
    await this.props.fetchRevisedQuotesByUserId(id);
    if (this.props.quotes) {
      this.setState({
        isLoaded: true,
        showRevised: true,
      });
    }
  }

  render() {
    let { isLoaded, showRevised } = this.state;
    if (!isLoaded) {
      return (
        <div>
          <Loading message="Loading ..." gif="/images/stepworks.gif" />
        </div>
      );
    } else {
      return (
        <div>
          {this.props.match.params.id !== undefined &&
            this.props.match.params.type === undefined && (
              <Title title="My Quote List" />
            )}
          {this.props.match.params.id !== undefined &&
            this.props.match.params.type !== undefined && (
              <Title title="My Approved List" />
            )}
          {this.props.match.params.id === undefined &&
            this.props.match.params.type === undefined && (
              <Title title="Quote List" />
            )}
          <br />
          <p className="mb-4" />
          <div className="content-flex">
            <div className="shadow quotes-table">
              <div className="card-header">
                <h4 className="m-0 font-weight-bold text-primary">
                  Quote records
                  {this.props.match.params.id !== undefined &&
                  this.props.match.params.type === undefined ? (
                    !showRevised ? (
                      <button
                        className="float-right lists_button"
                        onClick={() => {
                          this.setState({ isLoaded: false });
                          this.fetchRevisedQuotesByUserId();
                        }}
                      >
                        Archived Quotes From You
                      </button>
                    ) : (
                      <button
                        className="float-right lists_button"
                        onClick={(e) => {
                          this.setState({ isLoaded: false });
                          this.fetchByUserId();
                        }}
                      >
                        All Quotes From You
                      </button>
                    )
                  ) : (
                    ""
                  )}
                  {this.props.match.params.id === undefined &&
                  this.props.match.params.type === undefined ? (
                    !showRevised ? (
                      <button
                        className="float-right lists_button"
                        onClick={(e) => {
                          this.setState({ isLoaded: false });
                          this.fetchRevisedQuotes();
                        }}
                      >
                        Archived Quote List From All
                      </button>
                    ) : (
                      <button
                        className="float-right lists_button"
                        onClick={(e) => {
                          this.setState({ isLoaded: false });
                          this.fetchAll();
                        }}
                      >
                        Quote List From All
                      </button>
                    )
                  ) : (
                    ""
                  )}
                </h4>
              </div>
              {!showRevised ? (
                <QuoteTable quotes={this.props.filtered_quotes} />
              ) : (
                <QuoteTable quotes={this.props.revised_quotes} />
              )}
            </div>
            {!showRevised ? (
              <Filter quotes={this.props.quotes} />
            ) : (
              <Filter quotes={this.props.revised_quotes} />
            )}
          </div>
        </div>
      );
    }
  }
}

const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.auth.isSignedIn,
    user: state.auth.user,
    quotes: state.quotes.quotes,
    revised_quotes: state.quotes.revised_quotes,
    filtered_quotes: state.filter.filterResults,
  };
};

export default connect(mapStateToProps, {
  fetchQuotes,
  fetchRevisedQuotes,
  fetchQuotesByUserId,
  fetchRevisedQuotesByUserId,
  fetchQuotesByUserIdAndApproved,
  filterInit,
})(QuoteList);
