import React from "react";
//redux
import { connect } from "react-redux";
import {fetchTemplateById} from "../../actions"
import {saveTemplate} from "../../actions"
import {createTemplate} from "../../actions"
import {deleteTemplate} from "../../actions"
//components
import { Block } from "../objects/Block";
import Step from "../quotes/blocks/Step";
import ReactQuill from "react-quill";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import Loading from "../components/Loading";
import Title from "../components/Title";

//assets
// import "react-quill/dist/quill.bubble.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { commaFormatting } from "../utils/commaformatting";

class Template extends React.Component {
    state = {
      template: new Block(),
      date: new Date(),
      saved: false,
      user: this.props.user
    };

  modules = {
    toolbar: [
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      [("bold", "underline")],
      [{ 'color': ['#48c800', '#ffdb01', '#ff3838', '#fcae3a', '#2dccff', '#000000', '#FFFFFF'] }, {'background': ['#48c800', '#ffdb01', '#ff3838', '#fcae3a', '#2dccff', '#000000', '#FFFFFF'] }],
      ["clean"],
    ],
    clipboard: {
      matchVisual: false
    }
  };

  async componentDidMount() {
    const { template_id } = this.props.match.params;
    if (template_id !== undefined) {
      await this.props.fetchTemplateById(template_id);
      if(this.props.template) {
        let templateTmp = {...this.props.template}
        templateTmp.base_price = commaFormatting(
          parseFloat(templateTmp.base_price)
        );
        this.setState({
          isLoaded: true,
          template: templateTmp,
          date: new Date()
        });    
      }
    }
    else {
      this.setState({
        isLoaded: true
      });    
    }
  }

  async componentDidUpdate(prevProps) {
    if (this.props.match.params.template_id !== prevProps.match.params.template_id) {
      const { template_id } = this.props.match.params;
    if (template_id !== undefined) {
      await this.props.fetchTemplateById(template_id);
      if(this.props.template) {
        let templateTmp = {...this.props.template}
        templateTmp.base_price = commaFormatting(
          parseFloat(templateTmp.base_price)
        );
        this.setState({
          isLoaded: true,
          template: templateTmp,
          date: new Date()
        });
      }
    }
    else {
      this.setState({
        template: new Block(),
        isLoaded: true
      });    
    }
  }
}

  handleSave = async event => {
    if (event) event.preventDefault();
    const { template_id } = this.props.match.params;
    let id = -1;
    if (template_id !== undefined) {
      id = template_id;
      let price_string = this.state.template.base_price + "";
      this.state.template.base_price = parseFloat(
        price_string.replace(/,/g, "")
      );
      console.log("Saving template:", id);
      let templateTmp = {...this.state.template}
      templateTmp.last_modified_by = this.props.user;
      this.setState({
        template:templateTmp
      })  
      console.log(templateTmp);
      await this.props.saveTemplate(templateTmp);
      if(this.props.saved_template) {
        this.setState({
          saved: true
        });
      }

    } else {
      let templateTmp = {...this.state.template}
      templateTmp.last_modified_by = this.props.user;
      this.setState({
        template:templateTmp
      }) 
      await this.props.createTemplate(templateTmp);
      if(this.props.created_template_id) {
        let templateTmp = {...this.state.template};
        templateTmp.id = this.props.created_template_id;
          this.setState({
            template: templateTmp,
            saved: true
          });
          this.props.history.push("/template/" + this.state.template.id);
      }
  };
}

  handleDelete = async event => {
    const r = window.confirm("Do you really want to delete this template?");
    if (r === true) {
      let templateTmp = {...this.state.template}
      templateTmp.deleted = 1;
      this.setState({
        template: templateTmp
      });
      this.props.deleteTemplate(templateTmp);
      await this.props.saveTemplate(templateTmp);
      if(this.props.saved_template) {
        this.setState({
          saved: true
        });
        this.props.history.push("/templateslist");
      }
    }
  };

  handleChangeQuill = name => event => {
    let templateTmp = {...this.state.template};
    templateTmp[name] = event;
    this.setState({
      template: templateTmp
    });
  };

  handleChange = event => {
    let name = event.currentTarget.dataset.ref;
    let templateTmp = {...this.state.template};
    templateTmp[name] = event.currentTarget.textContent;;
    this.setState({
      template: templateTmp
    });
  };
  handleChangePrice = event => {
    let name = event.currentTarget.dataset.ref;
    let price_string = event.currentTarget.textContent;
    let templateTmp = {...this.state.template};
    templateTmp[name] = parseFloat(price_string.replace(/,/g, ""));
    this.setState({
      template: templateTmp
    });
  };
  handleChangeType = event => {
    let name = event.currentTarget.dataset.ref;
    let templateTmp = {...this.state.template};
    templateTmp[name] = event.currentTarget.value;
    this.setState({
      template: templateTmp
    });
  };

  handleContentChange = (content) => {
    this.setState({
      template: content
    });
  }
  removeStep = () => {}
  render() {
    let { template, saved, isLoaded } = this.state;
    let { user } = this.props;
    if (!isLoaded) {
      return <Loading gif="/images/stepworks.gif" message="Loading.." />;
    } else {
      return (
        <div>
            {this.props.match.params.template_id === undefined ? (
              <Title title="New template" />
            ) : (
              <Title title="Edit template" />
            )}

          <div className="container template">
            <div className="quote_header">
              <div className="container">
                <div className="content">
                  <button
                    onClick={() => {
                      this.props.history.push("/templateslist");
                    }}
                  >
                    <FontAwesomeIcon icon="arrow-left" />
                  </button>
                  <button className="float-right" onClick={this.handleDelete}>
                    <FontAwesomeIcon icon="trash" />
                  </button>
                  <button className="float-right" onClick={this.handleSave}>
                    <FontAwesomeIcon icon="save" />
                  </button>

                  <div className="notification_box">
                    {saved && <p>Template was successfully saved</p>}
                  </div>
                </div>
              </div>
            </div>
            <div id="quote" className="content">
              <h1>{template.title !== "" && template.title + " template"}</h1>
              {
                <section>
                  <div className="row basic types">
                    <table>
                      <tbody>
                        <tr>
                          <td>
                            <span data-ref="title" placeholder="[Type]">
                              Type:
                            </span>
                          </td>
                          <td>
                            <label> Step
                            <input
                            defaultChecked
                              type="radio"
                              name="type"
                              data-ref="type"
                              onChange={this.handleChangeType}
                              value="step"
                            />
                            </label>
                            <label> Item
                            <input
                              type="radio"
                              name="type"
                              data-ref="type"
                              onChange={this.handleChangeType}
                              value="item"
                            /></label>
                             {/* <label> Quote
                              <input
                              type="radio"
                              name="type"
                              data-ref="type"
                              onChange={this.handleChangeType}
                              value="quote"
                            /></label> */}
                            <label> Terms
                              <input
                              type="radio"
                              name="type"
                              data-ref="type"
                              onChange={this.handleChangeType}
                              value="terms"
                            /></label>

                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </section>
              }
              {template.type === "step" && (
                <DragDropContext onDragEnd={this.onDragEnd}>
                  <Droppable droppableId={1}>
                    {provided => (
                      <div {...provided.droppableProps} ref={provided.innerRef}>
                        <Step
                          key={1}
                          updateContent={this.handleContentChange}
                          currency="HK$"
                          removeStep={this.removeStep}
                          content={template}
                          step_index={1}
                          index={1}
                        ></Step>
                      </div>
                    )}
                  </Droppable>
                </DragDropContext>
              )
              }
              {template.type === "item" && (
                <div>
                  <section>
                    <strong>
                      <span
                        data-ref="title"
                        contentEditable="true"
                        suppressContentEditableWarning={true}
                        placeholder="[Title]"
                        onBlur={this.handleChange}
                      >
                        {template.title}
                      </span>
                    </strong>
                    <div className="height20"></div>
                    <div className="row intro">
                      <h2>Intro</h2>
                      <ReactQuill
                        theme="bubble"
                        value={template.intro}
                        modules={this.modules}
                        onChange={this.handleChangeQuill("intro")}
                        placeholder="(this will not appear if empty)"
                      />
                    </div>
                  </section>

                  <section>
                    <div className="row intro">
                      <h2>Notes</h2>
                      <ReactQuill
                        theme="bubble"
                        value={template.notes}
                        modules={this.modules}
                        onChange={this.handleChangeQuill("notes")}
                        placeholder="(this will not appear if empty)"
                      />
                    </div>
                  </section>

                  <section>
                    <div className="row intro">
                      <h2>Deliverables</h2>
                      <ReactQuill
                        theme="bubble"
                        value={template.deliverables}
                        modules={this.modules}
                        onChange={this.handleChangeQuill("deliverables")}
                        placeholder="(this will not appear if empty)"
                      />
                    </div>
                  </section>
                </div>
              )}
              {template.type === "terms" && (
                <section>
                    <div className="row intro">
                    <strong>
                      <span
                        data-ref="title"
                        contentEditable="true"
                        suppressContentEditableWarning={true}
                        placeholder="[Terms & Conditions title]"
                        onBlur={this.handleChange}
                      >
                        {template.title}
                      </span>
                    </strong>
                    <br/>
                    <br/>
                      <ReactQuill
                        theme="bubble"
                        value={template.intro}
                        modules={this.modules}
                        onChange={this.handleChangeQuill("intro")}
                        placeholder="(this will not appear if empty)"
                      />
                    </div>
                  </section>
              )}
              <div className="row">
                <table>
                  <tbody>
                    {template.type === "item" && (
                      <>
                        <tr>
                          <td>Base price:</td>
                        </tr>
                        <tr>
                          <td>
                            <span
                              data-ref="base_price"
                              contentEditable="true"
                              suppressContentEditableWarning={true}
                              placeholder="[Price]"
                              onBlur={this.handleChangePrice}
                            >
                              {commaFormatting(template.base_price)}
                            </span>
                          </td>
                        </tr>
                      </>
                    )}
                    <tr className="height20" />
                    <tr>
                      <td>
                        <input
                          type="checkbox"
                          data-ref="is_disabled"
                          checked={template.is_disabled === "0" ? false : true}
                          onChange={() => {
                            let templateTmp = {...this.state.template}
                            if (templateTmp.is_disabled === "0") {
                            templateTmp.is_disabled = "1";
                            }
                            else {
                              templateTmp.is_disabled = "0";
                            }
                            this.setState({ template: templateTmp });
                          }}
                        />{" "}
                        Disabled
                      </td>
                    </tr>
                    {user.permission === "1" && (
                      <tr>
                        <td></td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      );
    }
  }
}

const mapStateToProps = state => {
  return {
    user: state.auth.user,
    template:state.templates.template,
    saved_template: state.templates.saved_template,
    created_template_id: state.templates.created_template_id
  };
};

export default connect(mapStateToProps, {fetchTemplateById, saveTemplate, createTemplate, deleteTemplate})(Template);
