import React from "react";
import { commaFormatting } from "../utils/commaformatting";
import ReactHtmlParser from "react-html-parser";
import IntroComponent from "./IntroComponent";
function ProjectManagement(props) {

  let discount = ((props.quote.duties_percentage / 100) * props.quote.total).toFixed(0) - (((props.quote.duties_percentage / 100) * props.quote.total).toFixed(0) * (props.discount_percentage / 100)).toFixed(0)

  //quill configuration
  let modules = {
    toolbar: [
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      [("bold", "underline")],
      [{ 'color': ['#48c800', '#ffdb01', '#ff3838', '#fcae3a', '#2dccff', '#000000', '#FFFFFF'] }, {'background': ['#48c800', '#ffdb01', '#ff3838', '#fcae3a', '#2dccff', '#000000', '#FFFFFF'] }],
      ["clean"],
    ],
    clipboard: {
      matchVisual: false,
    },
  };
  return (
    <section className="pm">
                <div className="row">
                  <div className="duties">
                    <div className="">
                      <h3>Project and account management</h3>
                      <p>
                        <div>
                        <IntroComponent
                modules={modules}
                type="account_management_text"
                handleChangeQuill={props.handleChangeQuill}
                scope={props.quote.account_management_text}
                placeholder="[Project and account management text here]"
              />
                        </div>
                      Overall project planning, project management and account
                      management duties charged at&nbsp;
                      <span
                        data-ref="duties_percentage"
                        contentEditable="true"
                        suppressContentEditableWarning={true}
                        placeholder="15"
                        className="pmcut"
                        onBlur={props.handleChangeNumber}
                      >
                        {props.quote.duties_percentage}
                      </span>
                      % <br />
                      of the total services fee ({props.quote.currency.symbol}
                      {commaFormatting(props.quote.total)}
                      <span className="currency_symbol" />
                      <span className="subtotal" />)
                      </p>
                      <br/>
                      <br/>
                      <div
                        data-ref="pmprice"
                        className="number"
                        placeholder="0"
                        data-converted={
                          props.quote.currency.name !== "HKD"
                            ? "HKD" +
                              commaFormatting(
                                (
                                  (props.quote.duties_percentage / 100) *
                                  props.quote.total /
                                  props.quote.currency.rate
                                ).toFixed(0)
                              )
                            : ""
                        }
                        data-discount={ props.discount_percentage ?
                          commaFormatting(discount) : ''
                        }
                        style={{display: "flex",
                          justifyContent: "space-between",
                          borderBottom: "1px solid black", float:"none"}}
                          
                      > <span>Subtotal for project and account management </span>
                        <span>
                        {props.quote.currency.symbol}
                        {commaFormatting(
                          (
                            (props.quote.duties_percentage / 100) *
                            props.quote.total
                          ).toFixed(0)
                        )}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
  );
}

export default ProjectManagement;