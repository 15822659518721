import {
  FETCH_REVISED_QUOTES,
  FETCH_QUOTES,
  FETCH_QUOTES_BY_USERID,
  FETCH_REVISED_QUOTES_BY_USERID,
  FETCH_QUOTES_BY_USERID_AND_APPROVED,
  FETCH_QUOTE_BY_ID,
  CREATE_QUOTE,
  UPDATE_QUOTE,
  UPDATE_QUOTE_LOCK,
  FETCH_QUOTE_VERSIONS,
  QUOTE_LOCK_CHECK,
  LATEST_QUOTATION_NUMBER,
} from "../actions/types";

export default (state = [], action) => {
  switch (action.type) {
    case FETCH_QUOTES:
      return {
        ...state,
        quotes: action.payload,
      };
    case FETCH_REVISED_QUOTES:
      return {
        ...state,
        revised_quotes: action.payload,
      };
    case FETCH_QUOTE_VERSIONS:
      return {
        ...state,
        quote_versions: action.payload,
      };
    case FETCH_QUOTES_BY_USERID:
      return {
        ...state,
        quotes: action.payload.filter((u_q) => u_q.author.id === action.userId),
      };
    case FETCH_REVISED_QUOTES_BY_USERID:
      return {
        ...state,
        revised_quotes: action.payload.filter(
          (u_r_q) => u_r_q.author.id === action.userId
        ),
      };
    case FETCH_QUOTES_BY_USERID_AND_APPROVED:
      return {
        ...state,
        quotes: action.payload.filter(
          (u_r_q) =>
            u_r_q.author.id === action.userId && u_r_q.status === "approved"
        ),
      };
    case FETCH_QUOTE_BY_ID:
      return {
        ...state,
        quote: action.payload,
      };
    case LATEST_QUOTATION_NUMBER:
      return {
        ...state,
        latest_quotation_number:
          action.payload < 999
            ? "0" + (action.payload + 1)
            : action.payload + 1,
      };
    case CREATE_QUOTE:
      return {
        ...state,
        created_quote_id: action.payload,
      };
    case UPDATE_QUOTE:
      return {
        ...state,
        saved_quote: action.payload,
      };
    case UPDATE_QUOTE_LOCK:
      return {
        ...state,
        saved_quote_lock: action.payload,
      };
    case QUOTE_LOCK_CHECK:
      return {
        ...state,
        quote_locked: action.payload,
      };
    default:
      return state;
  }
};
