import React from 'react';
import ReactQuill, { Quill } from "react-quill";
import ImageUploader from "quill-image-uploader";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Draggable } from 'react-beautiful-dnd';

import { commaFormatting } from '../../utils/commaformatting';

Quill.register("modules/imageUploader", ImageUploader);
class Item extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            block: this.props.block,
            currency: this.props.currency,
            step_index: this.props.step_index,
            collapsed: false,
        };
        this.handleChange = this.handleChange.bind(this);
        this.remove_item = this.remove_item.bind(this);
    }

    quillRefIntro = React.createRef(null);
    quillRefDelivrables = React.createRef(null);
    quillRefNotes = React.createRef(null);

    modules = {
        toolbar: [
            [
              { list: "ordered" },
              { list: "bullet" },
              { indent: "-1" },
              { indent: "+1" },
            ],
            [("bold", "underline")],
            [{ 'color': ['#48c800', '#ffdb01', '#ff3838', '#fcae3a', '#2dccff', '#000000', '#FFFFFF'] }, {'background': ['#48c800', '#ffdb01', '#ff3838', '#fcae3a', '#2dccff', '#000000', '#FFFFFF'] }],
            ["clean"],
          ],
        imageUploader: {
            upload: file => {
              return new Promise((resolve, reject) => {
                console.log('handle uploading-', file);
                const formData = new FormData();
                formData.append("image", file);
                fetch(
                        process.env.REACT_APP_API + 'images/image-api-upload.php',
                        {
                            method: "POST",
                            body: formData
                        } 
                )
                .then(response => response.json())
                .then(result => {
                    console.log(result);
                    let result_url = process.env.REACT_APP_API + '/images/quote-images/' + result.filename;
                    resolve(result_url);
                  })
                  .catch(error => {
                    reject("Upload failed");
                    console.error("Error:", error);
                  });
              });
            }
          },
        clipboard: {
            matchVisual: false
        }
    };

    componentDidUpdate(prevProps) {
        if (this.props.block !== prevProps.block) {
            this.setState({
                block: this.props.block
            });
        }
        if (this.props.currency !== prevProps.currency) {
            console.log()
            this.setState({
              currency: this.props.currency,
            });
          }
    }

    remove_item = (event) => {
        const r = window.confirm(
            'Do you really want to delete this item (' + this.state.block.title + ')?'
        );
        if (r === true) {
            this.props.removeItem(this.state.block);
        }
    };

    handleChange = (event) => {
        let name = event.currentTarget.dataset.ref;
        let blockTmp = { ...this.state.block };
        blockTmp[name] = event.currentTarget.textContent;
        this.props.updateBlock(blockTmp);
    };
    handleChangePrice = (event) => {
        let name = event.currentTarget.dataset.ref;
        let price_string = event.currentTarget.textContent;
        price_string = price_string.replace(/,/g, '');
        let blockTmp = { ...this.state.block };
        if (price_string !== '' && !isNaN(price_string)) {
            blockTmp[name] = parseFloat(price_string);
        } else {
            blockTmp[name] = price_string;
        }
        this.setState(
            {
                block: blockTmp
            },
            () => {
                this.props.updateBlock(this.state.block);
            }
        );
    };
    handleChangeQuill = (name, quill) => (event) => {
        let blockTmp = { ...this.state.block };
        blockTmp[name] = event;
        this.setState({
            block: blockTmp
        },
        () => {
            this.props.updateBlock(this.state.block, quill);
        }
        );
    };

    collapseItem = (event) => {
        event.preventDefault();
        this.setState({
            collapsed: !this.state.collapsed
        });
    };

    render() {
        let { block, currency, step_index, collapsed } = this.state;
        return (
            <Draggable
                draggableId={block.title + block.id}
                index={this.props.index}
                key={block.title}>
                {(provided) => (
                    <div id='tpl-item'>
                        <div className='item'>
                            <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}>
                                <h4>
                                    <p className='remove' id={block.id} onClick={this.remove_item}>
                                        <FontAwesomeIcon icon='minus-circle' />
                                    </p>
                                    <span className='order'>
                                        <span className='step_order'>{step_index}.</span>
                                        {block.order}.
                                    </span>
                                    &nbsp;
                                    <span
                                        contentEditable='true'
                                        suppressContentEditableWarning={true}
                                        placeholder='[Title]'
                                        data-ref='title'
                                        onBlur={this.handleChange}>
                                        {block.title}
                                    </span>
                                    <span className='price'>
                                        <span className='currency_symbol'>
                                            {block.base_price !== '' &&
                                            !isNaN(block.base_price) &&
                                            block.base_price !== null ? (
                                                currency.symbol
                                            ) : (
                                                ''
                                            )}
                                        </span>
                                        <span
                                            contentEditable='true'
                                            suppressContentEditableWarning={true}
                                            data-ref='base_price'
                                            name='base_price'
                                            className='number'
                                            placeholder='[Price]'
                                            data-converted={
                                                currency.name !== 'HKD' ? (
                                                    'HKD' +
                                                    commaFormatting(
                                                        block.base_price / currency.rate
                                                    )
                                                ) : (
                                                    ''
                                                )
                                            }
                                            data-discount= { this.props.discount_percentage ?
                                                commaFormatting(parseInt(block.base_price) - (parseInt(block.base_price) * (this.props.discount_percentage/ 100)).toFixed(0)) : ''
                                            }
                                            onBlur={this.handleChangePrice}>
                                            {commaFormatting(block.base_price)}
                                        </span>
                                    </span>
                                    <p
                                        className='float-right remove'
                                        id={block.id}
                                        onClick={this.collapseItem}>
                                        <FontAwesomeIcon icon={!collapsed ? 'eye-slash' : 'eye'} />
                                    </p>
                                </h4>
                            </div>
                            {!collapsed && (
                                <div className='item-content'>
                                    <ReactQuill
                                        ref={this.quillRefIntro}
                                        theme='bubble'
                                        name='intro'
                                        scrollingContainer='html'
                                        modules={this.modules}
                                        value={block.intro}
                                        onChange={this.handleChangeQuill('intro', this.quillRefIntro)}
                                        placeholder='(this will not appear if empty)'
                                    />
                                    <span className='underline'>Notes:</span>
                                    <ReactQuill
                                        ref= {this.quillRefNotes}
                                        theme='bubble'
                                        name='intro'
                                        scrollingContainer='html'
                                        modules={this.modules}
                                        value={block.notes}
                                        onChange={this.handleChangeQuill('notes', this.quillRefNotes)}
                                        placeholder='(this will not appear if empty)'
                                    />
                                    <span className='underline'>Deliverables:</span>
                                    <ReactQuill
                                        ref={this.quillRefDelivrables}
                                        theme='bubble'
                                        name='intro'
                                        scrollingContainer='html'
                                        modules={this.modules}
                                        value={block.deliverables}
                                        onChange={this.handleChangeQuill('deliverables', this.quillRefDelivrables)}
                                        placeholder='(this will not appear if empty)'
                                    />
                                </div>
                            )}
                        </div>
                    </div>
                )}
            </Draggable>
        );
    }
}

export default Item;
