import { User } from "./User";
import { Block } from "./Block";

export class Quote_Object {
  id = -1;
  title = ""; //"[Title]";
  addressee = ""; //"[Addressee]";
  company = ""; //"[Company name]";
  company_address = ""; //"[Company address]";
  attn = ""; //"[Client name]";
  project_name = ""; //"[Project_name]";
  author = new User();
  client = ""; //"[Client]";
  date_created = ""; //"[Date created]";
  date_modified = ""; //"[Date modified]";
  last_modified_by = new User();
  reference = {
    company_acronym: "[REF]",
    quotation_number: "0000",
    quotation_date: "YEARMMDD"
  };
  scope = ""; //"[Add Overview text here]";
  objectives = ""; //"[Add Objectives text here]";
  key_dates = ""; //"[Add Key dates here]";
  content = [];
  optional_items = [];
  status = "active";
  currency = {
    name: "HKD",
    rate: 1,
    symbol: "$"
  }
  tags = "";
  duties_percentage = 15;
  account_management_text = `Dedicated account manager who will direct our entire team and be responsible for the agreed project objectives. Project management duties will also be covered by one or more team members for the smooth running of the project, coordinating between various parties, meeting agreed timelines, and avoiding project delays`;
  discount = 0;
  discount_text = ""//[Add Discount text here];
  discount_copy = ""//[Add Discount explanation text here];
  total = 0;
  terms = new Block();
  revision_version = 0; //when approved quote needs to be edited
  version_number = 0; //new version of the quote
  revised_quote_id = 0; //quote archived
  version_quote_id = 0; //new version
  deleted = 0;
  locked = "";
  nda = false;
}
