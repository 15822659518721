export class Block {
    id = 1;
    title = ''; //"[Add block title]";
    order = 0;
    type = 'step';
    blocks = [];
    intro = ''; //"[Add intro text here]";
    notes = ''; //"[Add notes text here]";
    deliverables = ''; //"[Add deliverables text here]";
    base_price = 0;
    is_disabled = 0;
    deleted = 0;
    last_modified_date = '';
    last_modified_by = {};
}
