import { SHOW_HIDE_FOOTER, SHOW_HIDE_TOPNAV, SHOW_HIDE_SIDEBAR, SHOW_HIDE_DISCOUNT_PERCENTAGE, FETCH_CURRENCIES } from "../actions/types";

const INITIAL_STATE = {
  footerShow: true,
  topNavShow: true,
  sidebarShow: true,
  discountPercentageShow: "visible",
  currencies: [
    {code: "HKD", id: "1", last_modified: "2023-01-11 00:00:00", name: "HKD", rate: "1.0000", symbol: "$"},
    {code: "USD", id: "2", last_modified: "2023-01-11 00:00:00", name: "USD", rate: "7.8291", symbol: "$"},
    {code: "CNY", id: "4", last_modified: "2023-01-11 00:00:00", name: "CNY", rate: "1.1543", symbol: "$"},
    {code: "SGD", id: "3", last_modified: "2023-01-11 00:00:00", name: "SGD", rate: "5.9114", symbol: "$"},
    {code: "GBP", id: "5", last_modified: "2023-01-11 00:00:00", name: "GBP", rate: "9.6553", symbol: "$"},
  ]
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SHOW_HIDE_FOOTER:
      return {
        ...state,
        footerShow: action.payload
      };
    case SHOW_HIDE_TOPNAV:
      return {
        ...state,
        topNavShow: action.payload
      };
    case SHOW_HIDE_SIDEBAR:
      return {
        ...state,
        sidebarShow: action.payload
      };
    case SHOW_HIDE_DISCOUNT_PERCENTAGE:
      return {
        ...state,
        discountPercentageShow: action.payload
      };
    case FETCH_CURRENCIES:
      return {
        ...state,
        currencies: action.payload
      };  
    default:
      return state;
  }
};
