import React from "react";

import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ParseDate from "../utils/ParseDate";

const QuoteTable = ({ quotes }) => {
  return (
    <div className="card-body">
      <div className="table-responsive data-table">
        {!quotes ? (
          <div>
            <br></br>
            <br></br>Nothing found here.<br></br>
            <br></br>
          </div>
        ) : (
          <table
            className="table table-bordered"
            id="dataTable"
            width="100%"
            cellSpacing="0"
          >
            <thead>
              <tr>
                <th>Ref</th>
                <th>Project name</th>
                <th>Author</th>
                <th>Date created / modified</th>
                <th>Status</th>
              </tr>
            </thead>
            <tfoot>
              <tr>
                <th>Ref</th>
                <th>Project name</th>
                <th>Author</th>
                <th>Date created / modified</th>
                <th>Status</th>
              </tr>
            </tfoot>
            <tbody>
              {quotes &&
                quotes.map((quote, i) => {
                  return (
                    <tr key={i}>
                      <td data-label="Ref">
                        <Link
                          to={{
                            pathname: "/quote/" + quote.version_quote_id
                          }}
                        >
                          Q{quote.reference.quotation_number}
                          {parseInt(quote.revision_version) !== 0 &&
                            "." + quote.revision_version}{" "}
                          {quote.locked && (
                            <span className="float-right">
                              <FontAwesomeIcon icon="lock"></FontAwesomeIcon>
                            </span>
                          )}
                        </Link>
                      </td>
                      <td data-label="Project name">
                        <Link
                          to={{
                            pathname: "/quote/" + quote.version_quote_id
                          }}
                        >
                          {quote.project_name}
                        </Link>
                      </td>
                      <td data-label="Author">
                        <Link
                          to={{
                            pathname: "/quote/" + quote.version_quote_id
                          }}
                        >
                          {quote.author.firstname} {quote.author.lastname}
                        </Link>
                      </td>
                      <td data-label="Date created / modified">
                        <Link
                          to={{
                            pathname: "/quote/" + quote.version_quote_id
                          }}
                        >
                          <ParseDate date={quote.date_created} /><br/>
                          <ParseDate date={quote.date_modified} />
                        </Link>
                      </td>
                      {/* <td data-label="Date modified">
                        <Link
                          to={{
                            pathname: "/quote/" + quote.version_quote_id
                          }}
                        >
                          <ParseDate date={quote.date_modified} />
                        </Link>
                      </td> */}
                      <td data-label="Status">
                        <Link
                          to={{
                            pathname: "/quote/" + quote.version_quote_id
                          }}
                        >
                          {quote.status === "active" && (
                            <div className="status btn-drafting text">
                              Drafting
                            </div>
                          )}
                          {quote.status === "approved" && (
                            <div className="status btn-approved text">
                              Approved by client
                            </div>
                          )}
                          {quote.status === "review" && (
                            <div className="status btn-internal text">
                              Ready for internal review
                            </div>
                          )}
                          {quote.status === "non-active" && (
                            <div className="status btn-archive text">
                              Archive
                            </div>
                          )}
                          {quote.status === "checked" && (
                            <div className="status btn-reviewed text">
                              Reviewed internally
                            </div>
                          )}
                          {quote.status === "archive" && (
                            <div className="status btn-archive text">
                              Archive
                            </div>
                          )}
                          {quote.status === "issued" && (
                            <div className="status btn-issued text">
                              Issued to client
                            </div>
                          )}
                          {quote.status === "lost" && (
                            <div className="status btn-lost text">
                              Lost
                            </div>
                          )}
                        </Link>
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        )}
      </div>
    </div>
  );
};

export default QuoteTable;
