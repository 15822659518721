import { FILTER_ARRAY, FILTER_SEARCH, FILTER_RESET, FILTER_INIT } from '../actions/types';

const INITIAL_STATE = {
    filterResults: [],
    filterSearch: '',
    filterArray: []
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case FILTER_INIT:
            return {
                ...state,
                filterResults: action.payload //getting quotes from props quotes
            };
        case FILTER_SEARCH:
            return {
                ...state,
                filterSearch: action.payload.query, //setting filter search query
                filterResults: action.payload.filterResults //setting fiter results
            };
        case FILTER_ARRAY:
            return {
                ...state,
                filterArray: action.payload.options, //setting filter statuses array
                filterResults: action.payload.filterResults //setting results
            };
        case FILTER_RESET:
            return {
                ...state,
                filterSearch: '', //reseting query
                filterArray: [], //reseting statuses array
                filterResults: action.payload //showing init quotes
            };
        default:
            return state;
    }
};
