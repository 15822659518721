import { months_short } from "../utils/time";

const ParseDate = ({ date }) => {
  let current_date = new Date(date.replace(/-/g, "/"));
  return (
    current_date.getDate() +
    " " +
    months_short[current_date.getMonth()] +
    " " +
    current_date.getFullYear()
  );
};

export default ParseDate;
