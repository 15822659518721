import React from "react";

const Signature = props => {
  if (props.user) {
    return (
      <section className="signature">
        <div>
          <div className="signature">
            <div className="stepworks_signature">
              <div>
                For and on behalf of
                <br />
                {props.entity === "sg" ? "Stepworks Pte. Ltd." : "Stepworks Company Limited"}
              </div>
            </div>

            <div className="clients_signature">
              <div>
                <div className="accepted_by">
                  Accepted by
                  <br />{" "}
                </div>
              </div>
            </div>

            <div className="signature">
              <div className="stepworks_signature">
                <div className="signature_img" style={{width: props.user?.signature?.width !== 0 ? props.user?.signature?.width + "px": "120px", height: props.user?.signature?.height !== 0 ? props.user?.signature?.height + "px": "auto" }}>
                  <img src={typeof props.user.signature === "object" ? props.user.signature.url : props.user.signature} alt={`${props.user.firstname}'s Signature`} />
                </div>
                <hr />
                {props.user.firstname} {props.user.lastname}
                <div>{props.user.jobtitle}</div>
              </div>

              <div className="clients_signature">
                <hr />
                <div>
                  Please sign with company chop
                  <br />
                  and return by email
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
  return <div></div>;
};

export default Signature;
