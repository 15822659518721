export function commaFormatting(x) {
    if (!isNaN(x) && x !== '' && x !== null) {
        let formatting = x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        formatting = parseFloat(formatting.replace(/[^\d\.\-]/g, '')).toFixed(0);
        return formatting.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    } else return x;
}

export function commaFormattingWithDecimals(x) {
    if (!isNaN(x) && x !== '' && x !== null) {
        let formatting = x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        formatting = parseFloat(formatting.replace(/[^\d\.\-]/g, '')).toFixed(2);
        return formatting.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    } else return x;
}