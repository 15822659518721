import React from "react";
import { Link } from "react-router-dom";
//redux
import { connect } from "react-redux";
import {fetchQuoteVersions} from "../../actions"
//assets
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { months_short } from "../utils/time";

//components
import Loading from "../components/Loading";
import Title from "../components/Title";

class RevisedQuoteList extends React.Component {
  constructor() {
    super();
    this.state = {
      quotes: [],
      isLoaded: false,
      sorted: [],
      page: 0,
      pageSize: 5,
      resized: [],
      filtered: []
    };
  }

  async componentDidMount() {
    await this.props.fetchQuoteVersions(this.props.match.params.id);

    if(this.props.versions) {
      this.setState({
        isLoaded: true,
        quotes: this.props.versions
      });
    }
    
  }

  parseDate(date) {
    let current_date = new Date(date);
    return (
      current_date.getDate() +
      " " +
      months_short[current_date.getMonth()] +
      " " +
      current_date.getFullYear()
    );
  }

  render() {
    let { isLoaded, quotes } = this.state;

    if (!isLoaded) {
      return <Loading message="Loading ..." gif="/images/stepworks.gif" />;
    } else {
      let quoteTitle =
        // quotes[0].reference.company_acronym +
        "Q" +
        quotes[0].reference.quotation_number +
        "-" +
        quotes[0].reference.quotation_date;
      return (
        <div>
          <h2 className="h3 mb-2 text-gray-800 heading">Quote Revised List</h2>

          <Title title={quoteTitle} />

          <p className="mb-4" />
          <div className="content-flex">
            <div className="shadow">
              <div className="card-header">
                <h4 className="m-0 font-weight-bold text-primary">
                  Quote versions
                </h4>
              </div>
              <div className="card-body">
                <div className="table-responsive data-table">
                  {quotes.length === 0 ? (
                    <div>
                      <br></br>
                      <br></br>No results. Please reset filter or try with
                      different queries.<br></br>
                      <br></br>
                    </div>
                  ) : (
                    <table
                      className="table table-bordered"
                      id="dataTable"
                      width="100%"
                      cellSpacing="0"
                    >
                      <thead>
                        <tr>
                          <th>Ref</th>
                          <th>Project name</th>
                          <th>Author</th>
                          <th>Created at</th>
                          <th>Last modified</th>
                          <th>Version</th>
                        </tr>
                      </thead>
                      <tbody>
                        {quotes.map((quote, i) => {
                          return (
                            <tr key={i}>
                              <td data-label="Ref">
                                <Link
                                  to={{
                                    pathname:
                                      "/comparequotes/" +
                                      quote.id +
                                      "/" +
                                      quote.version_quote_id
                                  }}
                                >
                                  Q{quote.reference.quotation_number}
                                  {parseInt(quote.revision_version) !== 0 &&
                                    "." + quote.revision_version}{" "}
                                  {quote.locked && (
                                    <span className="float-right">
                                      <FontAwesomeIcon icon="lock"></FontAwesomeIcon>
                                    </span>
                                  )}
                                </Link>
                              </td>
                              <td data-label="Project name">
                                <Link
                                  to={{
                                    pathname:
                                      "/comparequotes/" +
                                      quote.id +
                                      "/" +
                                      quote.version_quote_id
                                  }}
                                >
                                  {quote.project_name}
                                </Link>
                              </td>
                              <td data-label="Author">
                                <Link
                                  to={{
                                    pathname:
                                      "/comparequotes/" +
                                      quote.id +
                                      "/" +
                                      quote.version_quote_id
                                  }}
                                >
                                  {quote.author.firstname}{" "}
                                  {quote.author.lastname}
                                </Link>
                              </td>
                              <td data-label="Date created">
                                <Link
                                  to={{
                                    pathname:
                                      "/comparequotes/" +
                                      quote.id +
                                      "/" +
                                      quote.version_quote_id
                                  }}
                                >
                                  {this.parseDate(quote.date_created)}
                                </Link>
                              </td>
                              <td data-label="Date modified">
                                <Link
                                  to={{
                                    pathname:
                                      "/comparequotes/" +
                                      quote.id +
                                      "/" +
                                      quote.version_quote_id
                                  }}
                                >
                                  {this.parseDate(quote.date_modified)}
                                </Link>
                              </td>
                              <td data-label="Status">
                                <Link
                                  to={{
                                    pathname:
                                      "/comparequotes/" +
                                      quote.id +
                                      "/" +
                                      quote.version_quote_id
                                  }}
                                >
                                  <div className="status btn-danger text">
                                    {quote.version_number}
                                  </div>
                                </Link>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
  }
}

const mapStateToProps = state => {
  return {
    isAuthenticated: state.auth.isSignedIn,
    user: state.auth.user,
    versions: state.quotes.quote_versions
  };
};

export default connect(mapStateToProps, {fetchQuoteVersions})(RevisedQuoteList);
