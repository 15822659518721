export class User {
  id = -1;
  firstname = "[First Name]";
  lastname = "[Last Name]";
  email = "[Email]";
  tel = "[Tel]";
  signature = "[Signature]";
  password = "[Password]";
  date_created = "[Date created]";
  job_title = "[Job Title]";
  last_login = "[Last Login]";
}
