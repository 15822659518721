import React from 'react';

import { connect } from 'react-redux';
import { filterReset } from '../../actions';
import { filterArray } from '../../actions';
import { filterSearch } from '../../actions';

class Filter extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            statuses: [],
            filterStatus: [],
            filterSearch: '',
            quotes: this.props.quotes
        };
    }

    componentDidMount() {
        let statusesArray = [];
        if (this.state.quotes) {
            this.state.quotes.map((quote) => {
                if (!statusesArray.includes(quote.status)) {
                    statusesArray.push(quote.status);
                }
            });
            
            let sortingArr = [
                "active", "non-active", "review", "checked", "issued", "approved", "archive", "lost"
            ];

            statusesArray.sort(function(a, b){  
                return sortingArr.indexOf(a) - sortingArr.indexOf(b);
              });

            this.setState({
                statuses: statusesArray
            });
        }
    }

    componentWillUnmount() {
        this.props.filterReset();
    }

    handleSearchChange = (event) => {
        let value = event.target.value;
        this.setState({
            filterSearch: value
        });
        this.props.filterSearch(value);
    };

    handleStatusChange = (event) => {
        let statusesArray = this.state.filterStatus;
        let checkbox = event.target.checked;

        if (checkbox) {
            statusesArray.push(event.target.value);
        } else {
            statusesArray.splice(statusesArray.indexOf(event.target.value), 1);
        }

        this.setState({
            filterStatus: statusesArray
        });
        this.props.filterArray(this.state.filterStatus);
    };

    handleResetFilter = (event) => {
        event.target.value = 0;
        this.props.filterReset();
        this.setState({
            filterSearch: '',
            filterArray: []
        });
    };

    render() {
        let { statuses, filterSearch } = this.state;
        return (
            <div className='filter'>
                <div className='shadow'>
                    <div className='card-header'>
                        <h4 className='m-0 font-weight-bold text-primary'>
                            Filter{' '}
                            <button
                                className='float-right lists_button'
                                onClick={this.handleResetFilter}>
                                Reset
                            </button>
                        </h4>
                    </div>
                    <div className='card-body'>
                        <div className='table-responsive filter-table'>
                            <table
                                className='table table-bordered'
                                id='dataTable'
                                width='100%'
                                cellSpacing='0'>
                                <tbody>
                                    <tr className='label'>
                                        <td>
                                            <b>Search</b>
                                        </td>
                                    </tr>
                                    <tr className='search'>
                                        <td>
                                            <input
                                                type='text'
                                                id='search'
                                                value={filterSearch}
                                                placeholder='Search for quotes by name or reference number...'
                                                onChange={this.handleSearchChange}
                                            />
                                        </td>
                                    </tr>
                                    <tr className='label'>
                                        {statuses.length > 1 && (
                                            <td>
                                                <b>Status</b>
                                            </td>
                                        )}
                                    </tr>
                                    
                                    {statuses.length > 1 &&
                                    
                                        statuses.map((s, i) => {
                                            return (
                                                <tr key={i}>
                                                    <td>
                                                        <input
                                                            type='checkbox'
                                                            name='status'
                                                            id='status'
                                                            value={s}
                                                            onChange={this.handleStatusChange}
                                                        />{' '}
                                                        {s === "active" && "Drafting"}
                                                        {s === "non-active" && "Archive (inactive)"}
                                                        {s === "review" && "Ready for internal review"}
                                                        {s === "checked" && "Reviewed internally"}
                                                        {s === "issued" && "Issued to client"}
                                                        {s === "approved" && "Approved by client"}
                                                        {s === "lost" && "Lost"}
                                                        {s === "archive" && "Archive"}
                                                    </td>
                                                </tr>
                                            );
                                        })}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        filterArray: state.filter.filterArray,
        filterSearch: state.filter.filterSearch
    };
};

export default connect(mapStateToProps, { filterReset, filterSearch, filterArray })(Filter);
