import React from "react";
import ReactQuill from "react-quill";

//redux
import { connect } from "react-redux";
import { fetchTemplateById } from "../../../actions";
import { fetchTemplatesByType } from "../../../actions";

//components
import { Block } from "../../objects/Block";
import Item from "./Item";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Draggable } from "react-beautiful-dnd";
import { DragDropContext, Droppable } from "react-beautiful-dnd";

//assets
// import "react-quill/dist/quill.bubble.css";
import { commaFormatting } from "../../utils/commaformatting";

class Step extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      content: this.props.content,
      template: "",
      quill_ref: "",
      currency: this.props.currency
        ? this.props.currency
        : { name: "HKD", rate: 1, symbol: "$" },
      item_results: [],
      isLoaded: false,
      search: "",
      item_index: 1,
      step_index: this.props.step_index,
      collapsed: false,
      price_match: true,
      focused: false,
    };
    this.handleChange = this.handleChange.bind(this);
  }

  quillRef = React.createRef(null);

  modules = {
    toolbar: [
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      [("bold", "underline")],
      [{ 'color': ['#48c800', '#ffdb01', '#ff3838', '#fcae3a', '#2dccff', '#000000', '#FFFFFF'] }, {'background': ['#48c800', '#ffdb01', '#ff3838', '#fcae3a', '#2dccff', '#000000', '#FFFFFF'] }],
      ["clean"],
    ],
    clipboard: {
      matchVisual: false,
    },
  };

  async componentDidMount() {
    if (this.state.content.id === "") {
      this.setState({
        content: new Block(),
        template: "",
      });
    } else {
      if (this.state.content.blocks.length > 0) {
        let highestId = this.state.content.blocks.reduce((maxId, item) => Math.max(item.id, maxId), -1);
        this.setState({
          item_index: highestId + 1
        });
      }
    }
    let item_sum = 0;
    if (this.state.content.blocks.length > 0) {
      this.state.content.blocks.map((item) => {
        if (
          !isNaN(item.base_price) &&
          item.base_price !== "" &&
          item.base_price !== null
        )
          item_sum += parseFloat(item.base_price);
      });
      let contentTmp = { ...this.state.content };
      contentTmp.base_price = item_sum;
      this.setState({
        content: contentTmp,
      });
    }
    //populate item list
    await this.props.fetchTemplatesByType("item");
    if (this.props.items) {
      this.setState({
        item_results: this.props.items,
        isLoaded: true,
      });
    }



  }

  componentDidUpdate(prevProps) {
    if (this.props.content !== prevProps.content) {
      let contentTmp = { ...this.props.content };
      contentTmp.order = this.props.step_index;
      this.setState({
        content: contentTmp,
      });
    }
    if (this.props.currency !== prevProps.currency) {
      console.log()
      this.setState({
        currency: this.props.currency,
      });
    }
  }

  remove_step = (event) => {
    event.preventDefault();
    this.props.removeStep(this.state.content);
  };

  handleChange = (event) => {
    let name = event.currentTarget.dataset.ref;
    let contentTmp = { ...this.state.content };
    contentTmp[name] = event.currentTarget.textContent;
    this.setState(
      {
        content: contentTmp,
      },
      () => {
        this.props.updateContent(contentTmp);
      }
    );
  };

  handleChangePrice = (event) => {
    let name = event.currentTarget.dataset.ref;
    let price_string = event.currentTarget.textContent;
    let contentTmp = { ...this.state.content };
    contentTmp[name] = parseFloat(price_string.replace(/,/g, ""));

    //check price check with items if not change the color of step price
    let sum__item_base_price = parseFloat(0);
    contentTmp.blocks.map((item) => {
      sum__item_base_price += item.base_price;
    });
    if (sum__item_base_price !== contentTmp.base_price) {
      this.setState({
        price_match: false,
      });
    } else {
      this.setState({
        price_match: true,
      });
    }
    this.setState(
      {
        content: contentTmp,
      },
      () => {
        this.props.updateContent(this.state.content);
      }
    );
  };
  //change on Quill tag, normal handle event is not working with quill
  handleChangeQuill = (name, quill) => (event) => {
    // console.log(e);
    let contentTmp = { ...this.state.content };
    contentTmp[name] = event;
    this.setState(
      {
        content: contentTmp,
      },
      () => {
        this.props.updateContent(this.state.content, quill);
      }
    );
  };

  handleBlockChange = (block, quill) => {
    let contentTmp = { ...this.state.content };
    //getting the block id
    contentTmp.blocks.map((item, index) => {
      if (item.id === block.id) {
        contentTmp.blocks[index] = block;
      }
    });
    let item_sum = 0;
    contentTmp.blocks.map((item) => {
      if (
        !isNaN(item.base_price) &&
        item.base_price !== "" &&
        item.base_price !== null
      )
        item_sum += parseFloat(item.base_price);
    });
    contentTmp.base_price = item_sum;
    this.setState(
      {
        content: contentTmp,
      },
      () => {
        this.props.updateContent(this.state.content, quill);
      }
    );
  };
  //select template from list of items check <datalist>
  handleSelect = (event) => {
    let tpl = this.state.item_results.find(
      (item) => item.title === event.target.value
    );
    this.setState({
      search: event.target.value,
    });
    if (tpl !== undefined)
      this.setState({
        template: tpl,
      });
    else
      this.setState({
        template: "",
      });
  };

  addItem = async (event) => {
    event.persist(); //keeping the original synthetic event around
    let item = "";
    if (this.state.template === "") {
      item = new Block();
      item.id = this.state.item_index++;
      item.type = "item"; //setting type to step
      item.order = this.state.content.blocks.length + 1;
    } else {
      await this.props.fetchTemplateById(this.state.template.id);
      if (this.props.template) {
        item = this.props.template;
        item.id = this.state.item_index++;
        item.order = this.state.content.blocks.length + 1;
      }
    } // order nubmer for steps
    event.preventDefault();
    
    let contentTmp = { ...this.state.content };
    contentTmp.blocks.push(item);
    console.log(item);
    this.setState({
      content: contentTmp,
      search: "",
      template: "",
    });
    let item_sum = 0;
    contentTmp.blocks.map((item) => {
      if (
        !isNaN(item.base_price) &&
        item.base_price !== "" &&
        item.base_price !== null
      )
        item_sum += parseInt(item.base_price);
    });
    contentTmp.base_price = item_sum;
    this.setState(
      {
        content: contentTmp,
      },
      () => {
        this.props.updateContent(contentTmp);
      }
    );
  };

  collapseStep = (event) => {
    event.preventDefault();
    this.setState({
      collapsed: !this.state.collapsed,
    });
  };

  removeItem = async (item) => {
    let tmp_block = [];
    let tmp_index = 1;
    let contentTmp = { ...this.state.content };
    contentTmp.block = this.state.content.blocks.filter((block) => {
      // console.log(block);
      // console.log(item);
      if (block.id !== parseInt(item.id)) {
        let index = tmp_index++;
        block.id = index;
        block.order = index;
        tmp_block.push(block);
      }
    });
    contentTmp.blocks = tmp_block;
    // console.log(contentTmp);
    //calculate new price
    let item_sum = 0;
    contentTmp.blocks.map((item) => {
      if (
        !isNaN(item.base_price) &&
        item.base_price !== "" &&
        item.base_price !== null
      )
        item_sum += parseInt(item.base_price);
    });
    contentTmp.base_price = item_sum;
    this.setState({
      content: contentTmp,
      item_index: tmp_index,
    });
    this.props.updateContent(contentTmp);
  };

  onDragEnd = (result) => {
    const { destination, source } = result;

    if (!destination) {
      return;
    }

    if (
      destination.droppableId === source.draggableId &&
      destination.index === source.index
    )
      return;
    const items = this.state.content.blocks;

    const dragged = this.state.content.blocks[source.index];

    items.splice(source.index, 1);
    items.splice(destination.index, 0, dragged);
    let contentTmp = { ...this.state.content };
    contentTmp.blocks = items;

    //reorder
    let tmp_block = [];
    contentTmp.blocks.map((block, i) => {
      block.order = i + 1;
      tmp_block.push(block);
    });
    contentTmp.blocks = tmp_block;

    this.setState({
      content: contentTmp,
    });
  };

  render() {
    let { content, currency, item_results, isLoaded, step_index, collapsed } = this.state;

    return (
      <Draggable
        draggableId={content.title + content.id}
        index={this.props.index}
        key={content.title}
      >
        {(provided) => (
          <div id="tpl-step" className="step">
            <div>
              <p
                className="optional_items_remove"
                id={content.id}
                onClick={this.remove_step}
              >
                <FontAwesomeIcon icon="minus-circle" /> <span style={{color:"#999", fontStyle:"italic"}}>Delete whole section</span>
              </p>
              <h3
                ref={provided.innerRef}
                {...provided.draggableProps}
                {...provided.dragHandleProps}
              >
                <p
                  className="remove"
                  id={content.id}
                  onClick={this.remove_step}
                >
                  <FontAwesomeIcon icon="minus-circle" />
                </p>
                <span className="step_static_title">
                  Step <span className="order">{content.order}</span> -{" "}
                </span>
                <span
                  contentEditable="true"
                  suppressContentEditableWarning={true}
                  placeholder="[Title]"
                  data-ref="title"
                  onBlur={this.handleChange}
                >
                  {content.title}
                </span>
                <p
                  className="float-right remove"
                  id={content.id}
                  onClick={this.collapseStep}
                >
                  <FontAwesomeIcon icon={!collapsed ? "eye-slash" : "eye"} />
                </p>
              </h3>
              {!collapsed && (
                <div className="step-content">
                  <div>
                    <ReactQuill
                      id="intro"
                      theme="bubble"
                      scrollingContainer='html'
                      ref={this.quillRef}
                      modules={this.modules}
                      value={content.intro}
                      placeholder="[Add Intro text here]"
                      onChange={this.handleChangeQuill("intro", this.quillRef)}
                    />
                  </div>
                  <DragDropContext onDragEnd={this.onDragEnd}>
                    <Droppable droppableId={"1"}>
                      {(provided) => (
                        <div
                          className="items"
                          {...provided.droppableProps}
                          ref={provided.innerRef}
                        >
                          {content.blocks.map((item, i) => {
                            return (
                              <Item
                                key={i}
                                updateBlock={this.handleBlockChange}
                                removeItem={this.removeItem}
                                step_index={step_index}
                                block={item}
                                index={i}
                                currency={currency}
                                discount_percentage = {this.props.discount_percentage}
                              />
                            );
                          })}
                          {provided.placeholder}
                          <div className="add">
                            <input
                              type="text"
                              className="new-item"
                              list="itemlist"
                              data-type="item"
                              placeholder="Title"
                              onChange={this.handleSelect}
                              value={this.state.search}
                            />
                            <button onClick={this.addItem}>Add item</button>
                          </div>
                        </div>
                      )}
                    </Droppable>
                  </DragDropContext>
                  <div className="step_deliverables">
                    <span className="underline">Deliverables:</span>
                    <ReactQuill
                      theme="bubble"
                      name="intro"
                      scrollingContainer='html'
                      modules={this.modules}
                      value={content.deliverables}
                      onChange={(e) => this.handleChangeQuill(e, "deliverables")}
                      placeholder="[Description of the deliverables]"
                    />
                  </div>
                  <br />
                </div>
              )}
              <datalist id="itemlist">
                {isLoaded === true ? (
                  item_results.map((item, i) => {
                    return (
                      <option key={i} value={item.title} data-id={item.id} />
                    );
                  })
                ) : (
                  <option>No results.</option>
                )}
              </datalist>

              <div className="step-total">
                <strong>
                  Subtotal{" "}
                  <span className="step_static_title">
                    for Step&nbsp;
                    <span className="order">{content.order}</span>&nbsp;
                  </span>
                </strong>
                <span className="amount">
                  <span className="currency_symbol">{currency.symbol}</span>
                  <span
                    contentEditable={true}
                    suppressContentEditableWarning={true}
                    onBlur={this.handleChangePrice}
                    placeholder="[Price]"
                    className="number"
                    style={this.state.price_match ? {} : { color: "red" }}
                    data-ref="base_price"
                    data-converted={
                      currency.name !== "HKD"
                        ? "HKD" +
                          commaFormatting(content.base_price / currency.rate)
                        : ""
                    }
                    data-discount = { this.props.discount_percentage ?
                      commaFormatting(parseInt(content.base_price) - ((parseInt(content.base_price) * (this.props.discount_percentage / 100)).toFixed(0))) : ''
                    }
                  >
                    {commaFormatting(parseInt(content.base_price))}
                  </span>
                </span>
              </div>
            </div>
            {provided.placeholder}
          </div>
        )}
      </Draggable>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    items: state.templates.item_templates,
    steps: state.templates.step_templates,
    template: state.templates.template,
  };
};

export default connect(mapStateToProps, {
  fetchTemplateById,
  fetchTemplatesByType,
})(Step);
