import { SIGN_IN, SIGN_OUT, FETCH_USER } from "../actions/types";
import { User } from "../quote-engine/objects/User";

const INITIAL_STATE = {
  isSignedIn: false,
  user: new User()
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SIGN_IN:
      return {
        ...state,
        isSignedIn: true,
        user: action.payload
      };
    case SIGN_OUT:
      return {
        ...state,
        isSignedIn: false,
        user: new User()
      };
    case FETCH_USER:
      return {
        ...state,
        isSignedIn: action.isSignedIn,
        user: action.payload
      };
    default:
      return state;
  }
};
