import React from "react";
//router
import history from "../history";
import { Route, Redirect, Switch, Router } from "react-router-dom";
//assets
import { library } from "@fortawesome/fontawesome-svg-core";
// import "@progress/kendo-theme-material/dist/all.css";
import "./App.css";
import "../assets/fonts/font.css";
import "../assets/css/main.css";
import "bootstrap-4-grid/css/grid.min.css";
//components
import Sidebar from "./layout/Sidebar";
import QuoteList from "./quotes/QuotesList";
import Quote from "./quotes/Quote";
import TopNav from "./layout/TopNav";
import Footer from "./layout/Footer";
import UserProfile from "./user/UserProfile";
import UserEdit from "./user/UserEdit";
import TemplatesList from "./templates/TemplatesList";
import Template from "./templates/Template";
import Login from "./login/Login";
import Dashboard from "./layout/Dashboard";
import Settings from "./settings/Settings";
import QuotePreview from "./quotes/QuotePreview";
import RevisedQuoteList from "./quotes/RevisedQuotesList";
import CompareQuotes from "./quotes/CompareQuotes";
import PrivateRoute from "./utils/PrivateRoute";
//redux
import { signIn, fetchUser, fetchCurrencies} from "../actions";
import { connect } from "react-redux";
//icons
import {
  faTachometerAlt,
  faEdit,
  faList,
  faSearch,
  faFile,
  faEnvelope,
  faBell,
  faFileExport,
  faUser,
  faSave,
  faCogs,
  faSignOutAlt,
  faSignInAlt,
  faMinusCircle,
  faPrint,
  faToggleOn,
  faToggleOff,
  faEyeSlash,
  faEye,
  faArrowLeft,
  faCheck,
  faTimes,
  faTrash,
  faTools,
  faSortNumericDown,
  faLock,
  faHistory,
  faBug,
  faExclamationTriangle
} from "@fortawesome/free-solid-svg-icons";
library.add(
  faTachometerAlt,
  faEdit,
  faList,
  faSearch,
  faBell,
  faEnvelope,
  faFile,
  faFileExport,
  faUser,
  faSave,
  faCogs,
  faSignOutAlt,
  faSignInAlt,
  faMinusCircle,
  faPrint,
  faToggleOn,
  faToggleOff,
  faEyeSlash,
  faEye,
  faArrowLeft,
  faCheck,
  faTimes,
  faTrash,
  faTools,
  faSortNumericDown,
  faLock,
  faHistory,
  faBug,
  faExclamationTriangle
);

class App extends React.Component {
  componentDidMount() {
    this.props.fetchUser();
  }

  render() {
    return (
      <Router history={history}>
        <div id="wrapper">
          {this.props.sidebar && <Sidebar />}
          <div id="content-wrapper" className="d-flex flex-column">
            <div id="content">
              {this.props.topNav && <TopNav />}
              <div className="container-fluid">
                <Switch>
                  <Route
                    path="/login"
                    render={props =>
                      !this.props.isAuthenticated ? (
                        <Login {...props} />
                      ) : (
                        <Redirect to={"/"} />
                      )
                    }
                  />

                  <PrivateRoute
                    path={["/", "/dashboard"]}
                    exact
                    component={Dashboard}
                  ></PrivateRoute>
                  <PrivateRoute
                    path="/quotepreview/:quote_id?"
                    exact
                    component={QuotePreview}
                  ></PrivateRoute>
                  <PrivateRoute
                    path="/quote/:quote_id?"
                    exact
                    component={Quote}
                  ></PrivateRoute>
                  <PrivateRoute
                    path="/quoteslist/:id?/:type?/"
                    exact
                    component={QuoteList}
                  ></PrivateRoute>
                  <PrivateRoute
                    path="/revisedquoteslist/:id?/"
                    exact
                    component={RevisedQuoteList}
                  ></PrivateRoute>
                  <PrivateRoute
                    path="/comparequotes/:id?/:version?/"
                    exact
                    component={CompareQuotes}
                    user={this.props.user}
                  ></PrivateRoute>
                  <PrivateRoute
                    path="/userprofile"
                    exact
                    component={UserProfile}
                  ></PrivateRoute>
                  <PrivateRoute
                    path="/settings"
                    exact
                    component={Settings}
                    user={this.props.user}
                  ></PrivateRoute>
                  <PrivateRoute
                    path="/useredit/:user_id"
                    exact
                    component={UserEdit}
                    user={this.props.user}
                  ></PrivateRoute>
                  <PrivateRoute
                    path="/templateslist"
                    exact
                    component={TemplatesList}
                    user={this.props.user}
                  ></PrivateRoute>
                  <PrivateRoute
                    path="/template/:template_id?"
                    exact
                    component={Template}
                    user={this.props.user}
                  ></PrivateRoute>
                </Switch>
              </div>
            </div>
          </div>
        </div>
        {this.props.footer && <Footer />}
      </Router>
    );
  }
}
const mapStateToProps = state => {
  return {
    isAuthenticated: state.auth.isSignedIn,
    user: state.auth.user,
    topNav: state.global.topNavShow,
    sidebar: state.global.sidebarShow,
    footer: state.global.footerShow,
    currencies: state.global.currencies
  };
};

export default connect(mapStateToProps, { fetchUser, fetchCurrencies, signIn })(App);
