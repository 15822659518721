import React from "react";
import { Link } from "react-router-dom";
//Redux
import { connect } from "react-redux";
import { signOut } from "../../actions";

//Components
import Collapse from "react-bootstrap/Collapse";
import Search from "./Search";

//assets
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { months, days } from "../utils/time";
import { AuthorizationService } from "../utils/auth";

class TopNav extends React.Component {
  _isMounted = false
  constructor(props) {
    super(props);
    let d = new Date();
    this.state = {
      day: d.getDay(),
      month: d.getMonth(),
      date: d.getDate(),
      year: d.getFullYear(),
      time: d.toLocaleTimeString(),
      open_dropdown_user: false,
      
    };
  }

  componentDidMount() {
    this._isMounted = true;
    setInterval(this.updateTime, 1000);
  }

  // handleClickOutside(event) {
  //   if (this._isMounted) {
  //   if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
  //     this.setState({
  //       open_dropdown_user: !this.state.open_dropdown_user
  //     });
  //   }
    
  // }

  updateTime = () => {
    let d = new Date();
    if (this._isMounted) {
      this.setState({
        day: d.getDay(),
        month: d.getMonth(),
        date: d.getDate(),
        year: d.getFullYear(),
        time: d.toLocaleTimeString()
      });
    }
  };

  componentWillUnmount() {
    this._isMounted = false;
    clearInterval(this.updateTime);
  }

  logout = () => {
    this.props.signOut();
    AuthorizationService.logout();
  };

  render() {
    const { open_dropdown_user } = this.state;

    if (!this.props.isAuthenticated) {
      return (
        <div>
          <nav className="navbar navbar-expand navbar-light bg-white topbar mb-4 static-top">
            <ul className="navbar-nav ml-auto">
              <div className="topbar-divider d-none d-sm-block" />
              <li className="nav-item dropdown no-arrow">
                <a className="nav-link dropdown-toggle" >
                  <span className="mr-2 d-none d-lg-inline text-gray-600 small">
                    {days[this.state.day]}, {months[this.state.month]}{" "}
                    {this.state.date}, {this.state.year} {this.state.time}
                  </span>
                </a>
              </li>
            </ul>
          </nav>
        </div>
      );
    }
    return (
      <div>
        <nav
          id="topnav"
          className="navbar navbar-expand navbar-light bg-white topbar mb-4 static-top"
        >
          <button
            id="sidebarToggleTop"
            className="btn btn-link d-md-none rounded-circle mr-3"
          >
            <i className="fa fa-bars" />
          </button>
          <Search />
          <ul className="navbar-nav ml-auto">
            <li className="nav-item dropdown no-arrow d-sm-none">
              <a
                className="nav-link dropdown-toggle"
                id="searchDropdown"
                role="button"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <FontAwesomeIcon icon="search" />
              </a>
              <div
                className="dropdown-menu dropdown-menu-right p-3 shadow animated--grow-in"
                aria-labelledby="searchDropdown"
              >
                <form className="form-inline mr-auto w-100 navbar-search">
                  <div className="input-group">
                    <input
                      type="text"
                      className="form-control bg-light border-0 small"
                      placeholder="Search for..."
                      aria-label="Search"
                      aria-describedby="basic-addon2"
                    />
                    <div className="input-group-append">
                      <button className="btn btn-primary" type="button">
                        <i className="fas fa-search fa-sm" />
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </li>

            <div className="topbar-divider d-none d-sm-block border-left-primary" />

            <li className="nav-item dropdown no-arrow ">
              <a
                className="nav-link dropdown-toggle"
                id="userDropdown"
                role="button"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <span
                  className="mr-2 d-none d-lg-inline text-gray-600 small"
                  onClick={() =>
                    this.setState({ open_dropdown_user: !open_dropdown_user })
                  }
                >
                  &nbsp; {this.props.user.firstname} {this.props.user.lastname}
                </span>
              </a>
              <Collapse in={this.state.open_dropdown_user}>
                <div
                  className="dropdown-menu dropdown-menu-right shadow animated--grow-in"
                  aria-labelledby="userDropdown"
                >
                  <Link className="dropdown-item" to="/userprofile">
                    <FontAwesomeIcon icon="user" />
                    &nbsp; User Profile
                  </Link>
                  <Link className="dropdown-item" to="/settings">
                    <FontAwesomeIcon icon="cogs" />
                    &nbsp; Settings
                  </Link>
                  <div className="dropdown-divider" />
                  <Link
                    className="dropdown-item"
                    onClick={this.logout}
                    to="/login"
                  >
                    <FontAwesomeIcon icon="sign-out-alt" />
                    &nbsp; Logout
                  </Link>
                </div>
              </Collapse>
            </li>
          </ul>
        </nav>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    isAuthenticated: state.auth.isSignedIn,
    user: state.auth.user
  };
};

export default connect(mapStateToProps, { signOut })(TopNav);
