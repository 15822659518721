import React from "react";
import ReactQuill from "react-quill";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { event } from "jquery";

class Terms extends React.Component {
  state = {
    terms: this.props.terms,
    selectedTerm: '',
    collapsed: false,
  };

  modules = {
    toolbar: [
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      [("bold", "underline")],
      [{ 'color': ['#48c800', '#ffdb01', '#ff3838', '#fcae3a', '#2dccff', '#000000', '#FFFFFF'] }, {'background': ['#48c800', '#ffdb01', '#ff3838', '#fcae3a', '#2dccff', '#000000', '#FFFFFF'] }],
      ["clean"],
    ],
  };

  componentDidUpdate(prevProps) {
    if (this.props.terms !== prevProps.terms) {
        if (!this.props.terms) {
          this.setState({
            terms: null,
          });
        }
        else if (prevProps.terms === null)  {
            this.fetchTerms();
        }
    }
  }

  componentDidMount() {
    if (!this.props.terms) {
        this.fetchTerms();
    }
    else {
      if(this.props.terms.type === "step") {
        this.fetchTerms();
      }
      else {
        this.setState({
          terms: this.props.terms
        })
      }
    }
  }

  fetchTerms() {
    let id = this.props.terms ? this.props.terms.id : 999

    if(this.props.terms) {
      if(this.props.terms.type === "step") {
        id = 999;
      }
    }
    
    fetch(
      process.env.REACT_APP_API + "/block/get.php?id=" + id
    ).then((res) =>
      res.json().then((json) => {
        if (json.status === "success") {
          this.setState({
            terms: json.data,
            isLoaded: true,
          });
        }
      })
    );
  }

  handleSelect = (event) => {
    let selectT = this.props.termsList.find(
      (term) => term.title === event.target.value
    );
    this.setState({
      selectedTerm: selectT
    })
  }

  handleChangeQuill = (name) => (event) => {
    let termsTmp = { ...this.state.terms };
    termsTmp.intro = event;
    this.setState({
      terms: termsTmp,
    });
    this.props.updateTerms(termsTmp);
  };

  collapseItem = (event) => {
    event.preventDefault();
    this.setState({
      collapsed: !this.state.collapsed,
    });
  };

  render() {
    let { collapsed, terms } = this.state;
    return (
      <section className="terms">
        <div id="tpl-item">
          <div className="item">
            <h4>
              {terms !== null && (
                <span
                  className="remove"
                  onClick={() => this.props.removeTerms(terms)}
                >
                  <FontAwesomeIcon icon="minus-circle" />{" "}
                </span>
              )}
              Terms and conditions
              {terms !== null && (
                <a className="float-right remove" onClick={this.collapseItem}>
                  <FontAwesomeIcon icon={!collapsed ? "eye-slash" : "eye"} />
                </a>
              )}
            </h4>
            {terms !== null ? (
              !collapsed && (
                <div className="item-content">
                  <ReactQuill
                    theme="bubble"
                    name="intro"
                    scrollingContainer='html'
                    modules={this.modules}
                    value={terms.intro}
                    onChange={this.handleChangeQuill("intro")}
                    placeholder="[Add Terms and conditions text here]"
                  />
                </div>
              )
            ) : (
              <div className="add">
                <input
                  type="text"
                  className="new-term"
                  list="termlist"
                  data-type="step"
                  placeholder="Terms Title"
                  onChange={this.handleSelect}
                />
                <button data-ref="content" onClick={() => this.props.addTerms(this.state.selectedTerm)}>
                  Add Terms
                </button>
              </div>
            )}
          </div>
        </div>
      </section>
    );
  }
}

export default Terms;
