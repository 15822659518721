import { FETCH_USERS, UPDATE_USER, FETCH_USER_SETTINGS } from '../actions/types';

export default (state = [], action) => {
    switch (action.type) {
        case FETCH_USERS:
            return {
              ...state,
              users: action.payload,
        };
        case UPDATE_USER:
            return {
                ...state,
                updated_user: action.payload
            };
        case FETCH_USER_SETTINGS:
            return {
                ...state,
                settings: action.payload
            };    
        default:
            return state;
    }
};
