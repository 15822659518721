import React from "react";
import ReactHtmlParser from "react-html-parser";
import ReactQuill from "react-quill";

import { commaFormatting } from "../../../utils/commaformatting";

class ItemPreview extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      block: this.props.block,
      currency: this.props.currency,
      step_index: this.props.step_index,
      num_of_steps: this.props.num_of_steps
    };
  }
  handleChangeQuill = name => event => {
    let blockTmp = {...this.state.block}
    blockTmp[name] = event;
    this.setState({
      block: blockTmp
    });
  };

  modules = {
    toolbar: [
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" }
      ],
      [("bold", "underline")],
      ["clean"]
    ],
    clipboard: {
      matchVisual: false
    }
  };

  render() {
    let { block, currency, step_index, num_of_steps } = this.state;
    console.log(
      block.base_price + "kaj: " + isNaN(this.props.block.base_price)
    );
    console.log("Cena: " + block.base_price);
    return (
      <div id="tpl-item quote_preview">
        <div className="item">
          <div className="no_page_break">
            <h4>
              <span className="order">
                <span className="step_order">
                  {num_of_steps > 1 && step_index + "."}
                </span>
                {block.order}.
              </span>
              <span className="item_title">{block.title}</span>
              <span className="price">
                <span className="currency_symbol">
                  {block.base_price !== "" &&
                  !isNaN(block.base_price) &&
                  block.base_price !== null
                    ? currency.symbol
                    : ""}
                </span>
                <span>{commaFormatting(block.base_price)}</span>
              </span>
            </h4>
          </div>
          <div className="item-content">
            {block.intro.length > 15 ? (
              <div>
                <span>
                  {this.props.compare === "true" ? (
                    ReactHtmlParser(block.intro)
                  ) : (
                    <ReactQuill
                      theme="bubble"
                      name="intro"
                      modules={this.modules}
                      value={block.intro}
                      onChange={this.handleChangeQuill("intro")}
                      placeholder="(this will not appear if empty)"
                    />
                  )}
                </span>
                <br />
              </div>
            ) : (
              ""
            )}
            {block.notes.length > 15 ? (
              <div>
                <div className="no_page_break_small">
                  <span className="underline">Notes:</span>
                </div>
                <span>
                  {this.props.compare === "true" ? (
                    ReactHtmlParser(block.notes)
                  ) : (
                    <ReactQuill
                      theme="bubble"
                      name="intro"
                      modules={this.modules}
                      value={block.notes}
                      onChange={this.handleChangeQuill("notes")}
                      placeholder="(this will not appear if empty)"
                    />
                  )}
                </span>
                <br />
              </div>
            ) : (
              ""
            )}
            {block.deliverables.length > 15 ? (
              <div>
                <div className="no_page_break_small">
                  <span className="underline">Deliverables:</span>
                </div>
                <span>
                  {this.props.compare === "true" ? (
                    ReactHtmlParser(block.deliverables)
                  ) : (
                    <ReactQuill
                      theme="bubble"
                      name="intro"
                      modules={this.modules}
                      value={block.deliverables}
                      onChange={this.handleChangeQuill("deliverables")}
                      placeholder="(this will not appear if empty)"
                    />
                  )}
                </span>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default ItemPreview;
