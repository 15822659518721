import {
  FETCH_TEMPLATES,
  FETCH_TEMPLATE_BY_ID,
  CREATE_TEMPLATE,
  UPDATE_TEMPLATE,
  DELETE_TEMPLATE,
  FETCH_STEP_TEMPLATES,
  FETCH_TERMS_TEMPLATES,
  FETCH_ITEM_TEMPLATES,
} from "../actions/types";

export default (state = [], action) => {
  switch (action.type) {
    case FETCH_TEMPLATES:
      return {
        ...state,
        templates: action.payload,
      };
    case FETCH_TEMPLATE_BY_ID:
      return {
        ...state,
        template: action.payload,
      };
    case FETCH_ITEM_TEMPLATES:
      return {
        ...state,
        item_templates: action.payload,
      };
    case FETCH_STEP_TEMPLATES:
      return {
        ...state,
        step_templates: action.payload,
      };
    case FETCH_TERMS_TEMPLATES:
      return {
        ...state,
        terms_templates: action.payload,
      };
    case CREATE_TEMPLATE:
      return {
        ...state,
        created_template_id: action.payload,
      };
    case UPDATE_TEMPLATE:
      return {
        ...state,
        saved_template: action.payload,
      };

    case DELETE_TEMPLATE:
      return {
        ...state,
        deleted_template: action.payload,
      };

    default:
      return state;
  }
};
