import React from "react";
import ReactHtmlParser from "react-html-parser";
import ReactQuill from "react-quill";

class TermsPreview extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      terms: this.props.terms,
      compare: this.props.compare,
      collapsed: false
    };
  }

  modules = {
    toolbar: [
      [{ header: [1, 2, 3, false] }],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" }
      ],
      [("bold", "italic", "underline")],
      ["clean"]
    ]
  };
  componentDidUpdate(prevProps) {
    if (this.props.terms !== prevProps.terms) {
      this.setState({
        terms: this.props.terms
      });
    }
  }

  handleChangeQuill = event => {
      let termsTmp = {...this.state.terms}
      termsTmp.intro = event;
    this.setState({
      terms: termsTmp
    });
    this.props.handleTermsChange(termsTmp);
  };

  render() {
    let { collapsed, terms, compare } = this.state;
    {console.log(terms)}
    return (
      <section className="terms">
        {terms !== null && (
          
          terms.intro !== "<p><br></p>" && (
            
        <div id="tpl-item">
          <div className="item">
            <span className="no_page_breaks">
              <h4>Terms and conditions</h4>
            </span>
            {!collapsed && (
              <div className="item-content">

                  <span>
                    {compare === "true" ? (
                      ReactHtmlParser(terms.intro)
                    ) : (
                      <ReactQuill
                        theme="bubble"
                        name="intro"
                        modules={this.modules}
                        value={terms.intro}
                        onChange={this.handleChangeQuill}
                        placeholder="[Add Terms and conditions text here]"
                      />
                    )}
                  </span>
              </div>
            )}
          </div>
        </div>
          )
        )}
      </section>
    );
  }
}

export default TermsPreview;
