import React from "react";

import { Prompt, Link } from "react-router-dom";

//redux
import { connect } from "react-redux";
import { fetchUser } from "../../actions";
import { fetchUsers } from "../../actions";
import { fetchQuoteById } from "../../actions";
import { createQuote } from "../../actions";
import { saveQuote } from "../../actions";
import { saveQuoteLock } from "../../actions";
import { fetchTemplateById } from "../../actions";
import { fetchTemplatesByType } from "../../actions";
import { quoteLockCheck } from "../../actions";
import { latestQuotationNumber } from "../../actions";
import { sendEmail } from "../../actions";

//components
import Step from "./blocks/Step";
import Terms from "./blocks/Terms";
import Signature from "./blocks/Signature";
import Loading from "../components/Loading";
import { Block } from "../objects/Block";
import { Quote_Object } from "../objects/Quote";
import ReactQuill from "react-quill";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import Title from "../components/Title";

//assets

import { months } from "../utils/time";
import "react-quill/dist/quill.bubble.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import IntroComponent from "../components/IntroComponent";
import Discount from "../components/Discount";
import GrandTotal from "../components/GrandTotal";
import ProjectManagement from "../components/ProjectManagement";
import CurrencySelector from "../components/CurrencySelector";

class Quote extends React.Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      quote: new Quote_Object(),
      user: props.user,
      users: [],
      saved: false,
      error: false,
      errorMessage: "",
      created: false,
      template: "",
      date: new Date(),
      test: "",
      duties: 0,
      total: 0,
      terms_results: [],
      step_results: [],
      item_results: [],
      isLoaded: false,
      isLoadedSteps: false,
      isLoadedItems: false,
      search: "",
      notification: false,
      step_index: 1,
      opt_index: 1,
      last_saved: "",
      show_settings: false,
      initial_status: "", //status with no changes (so that I can check if status has been changed from inital one, so that I can send an email)
      q_status: "",
      q_duplicate: false,
      entity: "hk",
      // autosaver: "",
      lockchecker: "",
      quotationNumber: props.latest_quotation_number,
      deleteQuote: false,
      locked: false,
      change: false,
      nda: false,
      cursor: 0,
      q_author: "",
      discount_percentage: 0,
      currency: { name: "HKD", rate: 1, symbol: "$" }
      //   areChildrenLoaded: false,
    };
  }

  quillRefAdd = React.createRef();
  quillRefAdd1 = React.createRef();
  quillRefAtn = React.createRef();
  quillRefScope = React.createRef();
  quillRefObjectives = React.createRef();
  quillRefKeyDates = React.createRef();

  //quill configuration
  modules = {
    toolbar: [
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      [("bold", "underline")],
      [{ 'color': ['#48c800', '#ffdb01', '#ff3838', '#fcae3a', '#2dccff', '#000000', '#FFFFFF'] }, {'background': ['#48c800', '#ffdb01', '#ff3838', '#fcae3a', '#2dccff', '#000000', '#FFFFFF'] }],
      ["clean"],
    ],
    clipboard: {
      matchVisual: false,
    },
  };
  notoolbar = {
    toolbar: false,
    clipboard: {
      matchVisual: false,
    },
  };

  async componentDidMount() {
    this._isMounted = true;
    let fetchingUser = null;
    let fetchingLatestQuotationNumber = null;
    let fetchQuote = null;
    try {
      fetchingUser = await this.props.fetchUser();
    } catch (e) {
      console.error(e);
    }
    if (fetchingUser) {
      // console.log(fetchingUser);
      try {
        fetchingLatestQuotationNumber =
          await this.props.latestQuotationNumber();
      } catch (e) {
        console.error(e);
      }
    }
    // console.log(fetchingLatestQuotationNumber.data.data);
    if (fetchingLatestQuotationNumber.data.data) {
      console.log(
        "fetchingLatestQuotationNumber -",
        fetchingLatestQuotationNumber.data.data
      );
      if (this.props.match.params.quote_id !== undefined) {
        fetchQuote = await this.fetchQuote();
        if (fetchQuote) {
          // console.log("FQ", fetchQuote);
          // console.log("locked", this.state.locked);
          // console.log(this.props.quote.locked.id === this.props.user.id)
          if (!this.state.locked) {
            console.log("componentDidMount - locking quote");
            this.lockQuote(this.props.user);
          }
        }
        else {
          this.setState({
            error: true,
            errorMessage: "Quote cannot be retrieved. Please ask administrator for more details."
          })
        }
      } else {
        let quoteTmp = new Quote_Object();
        quoteTmp.date_created = this.getDateString();
        let reference_date = this.state.date
          .toISOString()
          .substr(0, 10)
          .replace(/-/g, "");
        quoteTmp.reference.quotation_date = reference_date;
        quoteTmp.reference.quotation_number =
          this.props.latest_quotation_number;
        //LOCK QUOTE HERE

        this.setState({
          quote: quoteTmp,
          currency: quoteTmp.currency,
          locked: false,
          nda: quoteTmp.nda,
        });
      }
    }

    //populate step list
    await this.props.fetchTemplatesByType("step");
    if (this.props.steps) {
      this.setState({
        step_results: this.props.steps,
        isLoadedSteps: true,
      });
    }

    //populate term list
    await this.props.fetchTemplatesByType("terms");
    if (this.props.terms) {
      this.setState({
        terms_results: this.props.terms,
        isLoadedTerms: true,
      });
    }

    let fetchingUsers = null;
    try {
      fetchingUsers = await this.props.fetchUsers();
    } catch (e) {
      console.error(e);
    }
    if (this.props.users) {
      this.setState({
        users: this.props.users,
      });
    }

    // let set_autosave = "";
    // if (this.props.user.settings.autosave) {
    //   set_autosave = setInterval(
    //     this.autoSave,
    //     this.props.user.settings.autosave_interval * 1000
    //   );
    // }

    document.addEventListener("keydown", this.handleKeyDownSave);
    window.addEventListener("beforeunload", this.handleWindowBeforeUnload);
    window.addEventListener("unload", this.handleUnload);

    setTimeout(() => {
      this.setState({
        change: false,
      });
    }, 500);

    setTimeout(() => {
    this.setState({
      isLoaded: true,
    });
    }, 400);

    console.log(this.state.quote);

  }

  async componentDidUpdate(prevProps) {
    let fetchQuote = null;
    let fetchingUser = null;
    let fetchingLatestQuotationNumber = null;
    let unlockOldQuote = null;
    if (this.props.match.params.quote_id !== prevProps.match.params.quote_id) {
      try {
        fetchingUser = await this.props.fetchUser();
      } catch (e) {
        console.error(e);
      }
      if (fetchingUser) {
        // console.log(fetchingUser);
        try {
          fetchingLatestQuotationNumber =
            await this.props.latestQuotationNumber();
        } catch (e) {
          console.error(e);
        }
      }
      if (
        fetchingLatestQuotationNumber.data.data &&
        this.props.match.params.quote_id !== undefined
      ) {
        fetchQuote = await this.fetchQuote();
        if (fetchQuote) {
          // console.log(fetchQuote);
          if (!this.state.locked) {
            console.log("componentDidMount - locking quote");
            this.lockQuote(this.props.user);
            setTimeout(() => {
              this.setState({
                isLoaded: true,
              });
            }, 400);
          }
        }
      } else {
        unlockOldQuote = await this.unlockQuote();
        if (unlockOldQuote) {
          let quoteTmp = new Quote_Object();
          quoteTmp.date_created = this.getDateString();
          let reference_date = this.state.date
            .toISOString()
            .substr(0, 10)
            .replace(/-/g, "");
          //console.log(this.state.date);
          quoteTmp.reference.quotation_date = reference_date;
          quoteTmp.reference.quotation_number =
            this.props.latest_quotation_number;

          let dp =
            (parseFloat(this.state.quote.discount) * 100) /
            (parseFloat(this.state.quote.duties_percentage / 100) *
              parseFloat(this.state.quote.total) +
              parseFloat(this.state.quote.total));

          this.setState({
            discount_percentage: dp,
            quote: quoteTmp,
            currency: quoteTmp.currency,
            isLoaded: true,
            locked: false,
            change: false,
            saved: false,
            created: false,
            nda: quoteTmp.nda,
          });
        }
      }

      this.setState({
        error: false //resetting the error state
      });
      // let autosaveTmp = "";
      // if (this.props.user.settings.autosave) {
      //   clearInterval(this.state.autosaver);
      //   autosaveTmp = setInterval(
      //     this.autoSave,
      //     this.props.user.settings.autosave_interval * 1000
      //   );
      // }

      // this.setState({
      //   autosaver: autosaveTmp,
      // });
    }
  }

  componentWillUnmount() {
    // console.log(this.state.saved);
    // console.log(this.state.change);
    // console.log(this.state.deleteQuote);
    //  console.log(this.state.locked);
    // console.log(this.state.quote.id !== -1);
    this._isMounted = false;
    // let clear_autosave = "";
    // if (this.props.user.settings.autosave) {
    //   clear_autosave = clearInterval(this.state.autosaver);
    // }
    // this.setState({
    //   saved: false,
    //   autosaver: clear_autosave,
    // });
    if(this.props.quote?.locked) {
      if(this.props.quote.locked.id === this.props.user?.id) {
        this.unlockQuote();
      }
    }
    document.removeEventListener("keydown", this.handleKeyDownSave);
    window.removeEventListener("beforeunload", this.handleWindowBeforeUnload);
  }

  handleUnload = (event) => {
      this.unlockQuote();
  };

  handleKeyDownSave = (event) => {
    //83 is code for S
    if (!this.state.locked) {
      if (
        (window.navigator.platform.match("Mac")
          ? event.metaKey
          : event.ctrlKey) &&
        event.keyCode === 83
      ) {
        event.preventDefault();
        this.handleCreateSave();
      }
    }
  };
  handleWindowBeforeUnload = (event) => {
    if (this.state.change && !this.state.deleteQuote && !this.state.locked) {
      this.unlockQuote();
      event.preventDefault();
      event.returnValue = "";
    }
  };

  getDateString = (quote_date, e) => {
    let date = new Date();
    if (quote_date !== undefined) {
      date = new Date(quote_date.replace(/-/g, "/"));
    }
    return (
      date.getDate() + " " + months[date.getMonth()] + " " + date.getFullYear()
    );
  };

  async fetchQuote() {
    let fetchingQuoteById = null;
    const { quote_id } = this.props.match.params;
    try {
      fetchingQuoteById = await this.props.fetchQuoteById(quote_id);
    if (fetchingQuoteById.data.status === "success") {
      console.log("fetchQuote -", fetchingQuoteById);
      let quoteTmp = { ...this.props.quote };
        let last_id = 0;
        if (quoteTmp.content !== null) {
          if (quoteTmp.content.length > 0) {
            last_id = quoteTmp.content.reduce((maxId, step) => Math.max(step.id, maxId), -1);
          }
        }
        quoteTmp.date_created = this.getDateString(quoteTmp.date_created);
        if (quoteTmp.locked) {
          if (quoteTmp.locked?.id !== this.props.user?.id) {
            this.setState({
              locked: true,
            });
          }
        }

        let dp =
          (parseFloat(quoteTmp.discount) * 100) /
          (parseFloat(quoteTmp.duties_percentage / 100) *
            parseFloat(quoteTmp.total) +
            parseFloat(quoteTmp.total));
        this.setState({
          discount_percentage: dp,
          quote: quoteTmp,
          nda: quoteTmp.nda,
          currency: quoteTmp.currency,
          step_index: last_id,
          change: false,
          q_status: quoteTmp.status,
          entity: quoteTmp.entity,
          initial_status: quoteTmp.status,
        });
    }
  } catch (e) {
    console.log(e);
  }
    return fetchingQuoteById;
  }
  //closing notification after succesful/failed save
  closeNotification(timeout) {
    setTimeout(() => {
      this.setState({
        notification: false,
        saved: false,
        error: false,
      });
    }, timeout);
  }
  //handle change on change of input fields
  handleChange = (event) => {
    let name = event.currentTarget.dataset.ref;
    let value = event.currentTarget.textContent;
    if(name === "account_management_text") value = event.currentTarget.innerHTML
    let quoteTmp = { ...this.state.quote };
    quoteTmp[name] = value;
    this.setState({
      quote: quoteTmp,
      saved: false,
    });
  };

  handleChangeNDACheckbox = (event) => {
    let quoteTmp = { ...this.state.quote };
    quoteTmp["nda"] = !this.state.nda;
    this.setState({
      quote: quoteTmp,
      nda: !this.state.nda,
      saved: false,
    });
  };

  handleChangeDiscountPercentage = (discount = 0, percentage = 0) => {
    let name = "discount";
    let value = discount;

    let quoteTmp = { ...this.state.quote };
    quoteTmp[name] = value;

    this.setState({
      discount_percentage: percentage,
      quote: quoteTmp,
      saved: false,
    });
  };

  handleChangeNumber = (event) => {
    let name = event.currentTarget.dataset.ref;
    let value = event.currentTarget.textContent;
    // console.log(value);
    value = value.replace(/[^\d.-]/g, "");
    // console.log(value);
    let quoteTmp = { ...this.state.quote };
    quoteTmp[name] = value;
    let dp = this.state.discount_percentage;
    if (name === "discount") {
      dp =
        (parseFloat(quoteTmp.discount) * 100) /
        (parseFloat(quoteTmp.duties_percentage / 100) *
          parseFloat(quoteTmp.total) +
          parseFloat(quoteTmp.total));
    }
    if (name === "grand-total-change-tmp") {
      let gtotal = parseFloat(quoteTmp.duties_percentage / 100) *
      parseFloat(quoteTmp.total) +
      parseFloat(quoteTmp.total);

      let gtotalupdated = quoteTmp[name];
      let diff = gtotal - gtotalupdated; 
      if(diff >= 0) { //check if new total is not bigger than total (discount cannot be - XXX)
        document.querySelector('[data-ref="grand-total-change-tmp"]').style.color= "black"
        document.querySelector('[data-ref="discount"]').style.color= "black"
        quoteTmp["discount"] = diff;
        dp =
        (parseFloat(quoteTmp.discount) * 100) /
        (parseFloat(quoteTmp.duties_percentage / 100) *
          parseFloat(quoteTmp.total) +
          parseFloat(quoteTmp.total));
      }
      else {
        document.querySelector('[data-ref="grand-total-change-tmp"]').style.color= "red"
      }
    }
    this.setState({
      discount_percentage: dp,
      quote: quoteTmp,
      saved: false,
    });
  };

  //change on Quill tag, normal handle event is not working with quill
  handleChangeQuill =
    (name, quill = null) =>
    (event, delta, source, editor) => {
      let quoteTmp = { ...this.state.quote };
      quoteTmp[name] = event;
      if (
        name === "company" ||
        name === "company_address" ||
        name === "attn"
      ) {
        this.setState({
          quote: quoteTmp,
          saved: false,
          change: false,
        });
      } else {
        this.setState({
          quote: quoteTmp,
          saved: false,
          change: true,
        });
      }
      let savedRangeCursor = 0;
      if (quill) {
        if (quill.current) {
          savedRangeCursor = quill.current.getEditor().selection.savedRange;
        }
      }
    };

  //change currency of the quote
  handleChangeCurrency = (cname, csymbol, crate) => {
    console.log(cname, csymbol, crate);
    // let index = event.target.selectedIndex;
    let quoteTmp = { ...this.state.quote };

    quoteTmp.currency.name = cname;
    quoteTmp.currency.symbol = csymbol;
    quoteTmp.currency.rate = crate;
    // console.log(quoteTmp.currency.rate);
    this.setState({
      quote: quoteTmp,
      saved: false,
    });

    this.setState({
      currency: {
        name: cname,
        symbol: csymbol,
        rate: crate
      }
    })
    console.log(this.state.currency);
  };

  //change reference field of the quote
  handleChangeReference = (event) => {
    let name = event.currentTarget.dataset.ref;
    let quoteTmp = { ...this.state.quote };
    quoteTmp.reference[name] = event.currentTarget.textContent;
    this.setState({
      quote: quoteTmp,
      saved: false,
    });
  };

  // get change of the content from children(steps)
  handleContentChange = (content, quill = null) => {
    let quoteTmp = { ...this.state.quote };
    const index = quoteTmp.content.findIndex((cont) => cont.id === content.id),
      c = [...quoteTmp.content];
    // console.log(index);

    quoteTmp.content[index] = content;
    // console.log(quoteTmp.content);
    //calculate new price on step change
    let step_sum = parseFloat(0);
    quoteTmp.content.map((step) => {
      if (!isNaN(step.base_price) && step.base_price !== null)
        step_sum += parseInt(step.base_price);
    });
    quoteTmp.total = step_sum;
    // if (this.areChildrenLoaded) {
    //   this.setState({
    //     change: true,
    //   });
    // }
    this.setState({
      quote: quoteTmp,
      //   areChildrenLoaded: true,
      change: true,
    });
    let savedRangeCursor = 0;
    if (quill) {
      if (quill.current) {
        savedRangeCursor = quill.current.getEditor().selection.savedRange;
      }
    }
  };

  handleOptionalItemsChange = (content) => {
    let quoteTmp = { ...this.state.quote };
    const index = quoteTmp.optional_items.findIndex(
        (cont) => cont.id === content.id
      ),
      c = [...quoteTmp.optional_items];
    quoteTmp.optional_items[index] = content;
    this.setState({
      quote: quoteTmp,
      change: true,
    });
  };

  handleTermsChange(terms) {
    let quoteTmp = { ...this.state.quote };
    quoteTmp.terms = terms;
    this.setState({
      quote: quoteTmp,
      change: true,
    });
  }

  handleSelect = (event) => {
    this.setState({
      search: event.target.value,
    });
    let tpl = this.state.step_results.find(
      (step) => step.title === event.target.value
    );
    if (tpl !== undefined) {
      this.setState({
        template: tpl,
        change: true,
      });
    } else
      this.setState({
        template: "",
      });
  };

  handleSettingsChange = (event) => {
    let quoteTmp = { ...this.state.quote };
    this.setState({ show_settings: !this.state.show_settings });

    if (this.state.q_author) {
      quoteTmp.author = this.state.q_author;
      this.setState({ quote: quoteTmp });
    }

    if (this.state.q_status) {
      quoteTmp.status = this.state.q_status;
      this.setState({ quote: quoteTmp });
    }

    if (this.state.entity) {
      quoteTmp.entity = this.state.entity;
      this.setState({ quote: quoteTmp });
    }

    if (this.state.q_duplicate) {
      this.setState({ q_duplicate: !this.state.q_duplicate });
      this.handleDuplicateQuote();
    }

    if (this.state.quote.status === "archive") {
      quoteTmp.has_revision = 1;
      quoteTmp.locked = "";
      this.setState(
        {
          quote: quoteTmp,
        },
        () => {
          this.handleCreateSave();
          this.handleCreateNewRevision();
        }
      );
    }
    // this.handleCreateSave();
  };

  handleStatusChange = (event) => {
    this.setState({ q_status: event.target.value });
  };

  handleChangeAuthor = (e) => {
    let id = e.target.value;
    let user = {};
    this.state.users.map((u) => {
      console.log(u, id);
      if (u.id == id) {
        user = u;
      }
    });
    this.setState({ q_author: user });
  };

  handleDelete = (event) => {
    let quoteTmp = { ...this.state.quote };
    const r = window.confirm(
      "Do you really want to delete this quote (" +
        this.state.quote.project_name +
        ")?"
    );
    if (r === true) {
      quoteTmp.deleted = 1;
      this.setState(
        {
          quote: quoteTmp,
          saved: false,
          deleteQuote: true,
          isLoaded: false,
        },
        () => {
          this.handleCreateSave();
          setTimeout(() => {
            this.props.history.push("/quoteslist");
          }, 500);
        }
      );
    }
  };

  addStep = async (event) => {
    event.persist();
    let quoteTmp = { ...this.state.quote };
    let name = event.currentTarget.dataset.ref;
    let block = "";
    let step_index_count = this.state.step_index + 1;

    if (name === "content") {
      if (this.state.template === "") {
        block = new Block();
        //check if this.state.step_index is correct
        block.id = step_index_count; //general index for all elements in the quote steps, items etc.
        block.type = "step"; //setting type to step
        block.order = this.state.quote.content.length + 1;
        this.setState({
          step_index: step_index_count,
        });
      } else {
        let fetchTemplate = null;
        try {
          fetchTemplate = await this.props.fetchTemplateById(
            this.state.template.id
          );
          if (this.props.template) {
            block = this.props.template;
            block.id = step_index_count;
            block.order = this.state.quote.content.length + 1;
            this.setState({
              step_index: step_index_count,
            });
          }
        } catch (e) {
          console.error(e);
        }
      }
    } else {
      block = new Block();
      block.id = this.state.opt_index; //general index for all elements in the quote steps, items etc.
      block.type = "optional_item"; //setting type to optional item
      block.order = this.state.quote.optional_items.length + 1;
      this.setState({
        opt_index: this.state.opt_index + 1,
      });
    }
    //checking if ID already exists
    let existingId = this.state.quote.content.find((s) => s.id === block.id);
    console.log(block.id, existingId);

    if(existingId) {
      let newId = this.state.quote.content.reduce((maxId, s) => Math.max(s.id, maxId), -1) + 1;
      block.id = newId;
    }

    event.preventDefault();
    quoteTmp[name].push(block);
    this.setState({
      quote: quoteTmp,
      search: "",
      template: "",
      change: true,
    });
    let step_sum = parseFloat(0);
    quoteTmp.content.map((step) => {
      if (!isNaN(step.base_price) && step.base_price !== null)
        step_sum += parseInt(step.base_price);
    });
    quoteTmp.total = step_sum;
    this.setState({
      quote: quoteTmp,
    });
  };

  removeStep = async (step) => {
    let quoteTmp = { ...this.state.quote };
    const r = window.confirm(
      "Do you really want to delete this step (" + step.title + ")?"
    );
    if (r === true) {
      let field = "content";
      if (step.type !== "step") {
        field = "optional_items";
      }
      let tmp_content = [];
      let tmp_step_index = 1;
      quoteTmp[field] = quoteTmp[field].filter((block) => {
        if (block.id !== parseInt(step.id)) {
          let index = tmp_step_index++;
          block.id = index;
          block.order = index;
          tmp_content.push(block);
        }
      });
      quoteTmp[field] = tmp_content;
      //calculate new price on step remove
      if (step.type === "step") {
        let step_sum = parseInt(0);
        quoteTmp.content.map((step) => {
          if (!isNaN(step.base_price) && step.base_price !== null)
            step_sum += parseInt(step.base_price);
        });
        quoteTmp.total = step_sum;
      }
      //reorder steps
      tmp_step_index = quoteTmp[field].length;

      this.setState({
        quote: quoteTmp,
        step_index: tmp_step_index,
        change: true,
      });
    }
  };

  addTerms = async (terms) => {
    let quoteTmp = { ...this.state.quote };
    let fetchTemplate = null;
    try {
      fetchTemplate = await this.props.fetchTemplateById(terms.id);
    } catch (e) {
      console.error(e);
    }
    if (this.props.template) {
      quoteTmp["terms"] = this.props.template;
      this.setState({
        quote: quoteTmp,
        change: true,
      });
    }
  };

  removeTerms = async (term) => {
    let quoteTmp = { ...this.state.quote };
    const r = window.confirm(
      "Do you really want to delete Terms & Conditions (" + term.title + ")?"
    );
    if (r === true) {
      quoteTmp["terms"] = null;
      this.setState({
        quote: quoteTmp,
        change: true,
      });
    }
  };

  onDragEnd = (result) => {
    let quoteTmp = { ...this.state.quote };
    const { destination, source } = result;

    if (!destination) {
      return;
    }

    if (
      destination.droppableId === source.draggableId &&
      destination.index === source.index
    )
      return;
    const steps = quoteTmp.content;

    const dragged = quoteTmp.content[source.index];

    steps.splice(source.index, 1);
    steps.splice(destination.index, 0, dragged);

    quoteTmp.content = steps;
    this.setState({
      quote: quoteTmp,
      save: false,
    });
  };

  // autoSave = async (event) => {
  //   let quoteTmp = { ...this.state.quote };
  //   let saved = new Date(this.state.last_saved).getTime() / 1000;
  //   let current = new Date().getTime() / 1000;
  //   let checkingQuoteLock = null;
  //   let savingLock = null;
  //   //check if quote exists
  //   console.log(quoteTmp);
  //   console.log(this.props.quote_locked)
  //   if (this.state.quote.id !== -1) {
  //     //checking locks function returns project_name and person who is editing quote
  //     checkingQuoteLock = await this.props.quoteLockCheck(this.state.quote.id);
  //     // console.log(checkingQuoteLock)
  //     if (checkingQuoteLock.data.status === "success") {
  //       if (this.props.quote_locked) {
  //         if (this.props.quote_locked.locked?.id !== quoteTmp.locked?.id) {
  //           //check first if user already locked the quote
  //           if (this.props.quote_locked.locked?.id === this.props.user?.id) {
  //             quoteTmp.locked = this.props.user;
  //             this.setState({
  //               quote: quoteTmp,
  //               locked: false,
  //             });
  //           } else {
  //             // console.log(this.props.quote_locked);
  //             quoteTmp.locked = this.props.quote_locked.locked;
  //             this.setState({
  //               quote: quoteTmp,
  //               locked: true,
  //             });
  //           }
  //         }
  //       } else {
  //         quoteTmp.locked = this.props.user;
  //         savingLock = await this.handleSaveLock(quoteTmp);
  //         if (savingLock) {
  //           this.setState({
  //             quote: quoteTmp,
  //             locked: false,
  //           });
  //         }
  //       }
  //       if (this.state.quote.project_name !== "" && !this.state.locked) {
  //         if (isNaN(saved) || parseInt(current - saved) >= 60) {
  //           if (!this.state.saved && this.state.change) {
  //             console.log("saving");
  //             await this.handleCreateSave();
  //           }
  //         }
  //       }
  //     }
  //   }
  // };

  render() {
    let {
      quote,
      isLoaded,
      isLoadedSteps,
      step_results,
      terms_results,
      last_saved,
      saved,
      show_settings,
      locked,
      error,
      errorMessage
    } = this.state;
    if (!isLoaded && this.props.match.params.quote_id !== undefined) {
      return <Loading message="Loading ..." gif="/images/stepworks.gif" />;
    } else {
      return (
        <div>
          <Prompt
            when={
              this.state.change && !this.state.deleteQuote && !locked //if change is true (any of the steps, items, objective etc has been edited, if quote was deleted and if its not locked by other people)
            }
            message={`Changes may not be saved. Continue anyway?`}
          />
          {this.props.match.params.quote_id === undefined ? (
            <Title title="New quote" />
          ) : (
            <Title title="Edit quote" />
          )}
          <div className="quote_header">
            <div className="container">
              <div className="content">
                {locked === false && (
                  <div>
                    <div className="quote_settings">
                      <button
                        className="float-right"
                        onClick={() => {
                          this.setState({
                            show_settings: !this.state.show_settings,
                          });
                        }}
                      >
                        <FontAwesomeIcon icon="cogs" />
                      </button>
                      {/* CREATE SETTINGS COMPONENT */}
                      {show_settings && (
                        <div className="settings_box">
                          <div onChange={this.handleStatusChange}>
                            <div>
                              <input
                                type="radio"
                                name="status"
                                value="active"
                                checked={this.state.q_status === "active"}
                                onChange={this.handleStatusChange}
                              />{" "}
                              Drafting
                            </div>
                            <div>
                              <input
                                type="radio"
                                name="status"
                                value="review"
                                checked={this.state.q_status === "review"}
                                onChange={this.handleStatusChange}
                              />{" "}
                              Ready for internal review
                            </div>
                            <div>
                              <input
                                type="radio"
                                name="status"
                                value="checked"
                                checked={this.state.q_status === "checked"}
                                onChange={this.handleStatusChange}
                              />{" "}
                              Reviewed internally
                            </div>
                            <div>
                              <input
                                type="radio"
                                name="status"
                                value="issued"
                                checked={this.state.q_status === "issued"}
                                onChange={this.handleStatusChange}
                              />{" "}
                              Issued to client
                            </div>
                            <div>
                              <input
                                type="radio"
                                name="status"
                                value="approved"
                                checked={this.state.q_status === "approved"}
                                onChange={this.handleStatusChange}
                              />{" "}
                              Approved by client
                            </div>
                            <div>
                              <input
                                type="radio"
                                name="status"
                                value="archive"
                                checked={this.state.q_status === "archive"}
                                onChange={this.handleStatusChange}
                              />{" "}
                              Archive
                            </div>
                            <div>
                              <input
                                type="radio"
                                name="status"
                                value="lost"
                                checked={this.state.q_status === "lost"}
                                onChange={this.handleStatusChange}
                              />{" "}
                              Lost
                            </div>
                          </div>
                          <br />
                          <div>
                            <input
                              type="checkbox"
                              value="sg"
                              checked={this.state.entity === "sg"}
                              onChange={(e) => {
                                if(this.state.entity === "sg")
                                this.setState({
                                  entity: "hk",
                                });
                                else {
                                  this.setState({
                                    entity: e.target.value,
                                  });
                                }
                              }}
                            />{" "}
                            Singapore entity
                          </div>
                          <br />
                          <div>
                            <input
                              type="checkbox"
                              checked={this.state.q_duplicate}
                              onChange={() => {
                                this.setState({
                                  q_duplicate: !this.state.q_duplicate,
                                });
                              }}
                            />{" "}
                            Duplicate this quote
                          </div>
 
                          {this.state.quote.version_number > 0 && (
                            <Link
                              className="revisions"
                              to={{
                                pathname:
                                  "/revisedquoteslist/" +
                                  this.state.quote.version_quote_id,
                              }}
                            >
                              <FontAwesomeIcon icon="history" />{" "}
                              {this.state.quote.version_number} Revisions
                            </Link>
                          )}
                          <br />
                          <br />
                          <div>
                            Author
                            <br />
                            <select
                              style={{
                                width: "100%",
                                marginTop: "5px",
                                padding: "5px 0px",
                                fontSize: "80%",
                              }}
                              onChange={this.handleChangeAuthor}
                            >
                              {this.state.users.map((user) => {
                                return (
                                  <option
                                    selected={
                                      this.state.quote.author.id === user.id
                                    }
                                    value={user.id}
                                    key={user.id}
                                  >
                                    {user.firstname} {user.lastname}
                                  </option>
                                );
                              })}
                            </select>
                          </div>
                          <br />
                          <br />
                          <button
                            className="cancel"
                            onClick={() => {
                              this.setState({
                                show_settings: !this.state.show_settings,
                              });
                            }}
                          >
                            <FontAwesomeIcon icon="times" />
                          </button>
                          <button
                            className="confirm float-right"
                            onClick={this.handleSettingsChange}
                          >
                            <FontAwesomeIcon icon="check" />
                          </button>
                        </div>
                      )}
                    </div>
                    <button className="float-right" onClick={this.handleDelete}>
                      <FontAwesomeIcon icon="trash" />
                    </button>
                    <button
                      className="float-right"
                      onClick={() => {
                        this.handleCreateSave();
                      }}
                    >
                      <FontAwesomeIcon icon="save" /> <b>SAVE</b>
                    </button>
                  </div>
                )}
                <button
                  onClick={() => {
                    this.unlockQuote().then((x) => {
                      this.props.history.push("/quoteslist");
                    });
                  }}
                >
                  <FontAwesomeIcon icon="arrow-left" />
                </button>
                {quote.id !== -1 && !locked ? (
                  <button
                    onClick={() => {
                      this.handleCreateSave();
                      setTimeout(() => {
                        this.props.history.push(
                          "/quotepreview/" + quote.version_quote_id
                        );
                      }, 500);
                    }}
                  >
                    <FontAwesomeIcon icon="eye" />
                  </button>
                ) : (
                  ""
                )}
                {/* CREATE NOTIFICATION COMPONENT */}
                <div className={`notification_box ${error && 'error'}`}>
                  {last_saved !== "" && saved && (
                    <p>
                      Quote was last saved on: {last_saved.getDate()}.
                      {last_saved.getMonth() + 1}.{last_saved.getFullYear()} at{" "}
                      {last_saved.getHours()}:{last_saved.getMinutes()}:
                      {last_saved.getSeconds()}
                    </p>
                  )}
                  {error && (
                      <span><FontAwesomeIcon icon="exclamation-triangle" /> {errorMessage}</span>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="container relative">
            {/* CREATE LOCKED COMPONENTS */}
            {locked && (
              <div className="locked">
                <div className="locked_centered">
                  <img
                    src={`${process.env.REACT_APP_API}/images/stepworks-lock.gif`}
                    alt="Locked"
                  />
                  <span>
                    This quote is currently being edited by{" "}
                    {quote.locked.firstname}.
                  </span>
                  <br />
                  <br />
                  <button onClick={this.forceUnlock}>Force unlock?</button>
                  <Link style={{border:"1px solid #eb0c38", borderRadius: "5px", fontSize: "14px",color: "#eb0c38",padding: "5px 10px"}} to={`/quotepreview/${quote.revised_quote_id}`}>Preview Quote</Link>
                </div>
              </div>
            )}
            <div id="quote" className="content">
              <div className="stepworks_logo">
                <img
                  src="/images/logo.jpg"
                  alt="Stepworks Logo"
                />
              </div>
              <div className="actions">
                {/* CURRENCY COMPONENT */}
                <CurrencySelector quote={this.state.quote} handleChangeCurrency={this.handleChangeCurrency} />
              </div>
              {/* CREATE QUOTE HEADER COMPONENT */}
              <div className="quote_details_wrapper">
                <div className="quote_details">
                  <section>
                    <div className="row basic">
                      <table className="table-min-width">
                        <tbody>
                          <tr>
                            <td>To:</td>
                            <td>
                              <ReactQuill
                                ref={this.quillRefAdd}
                                theme="bubble"
                                modules={this.notoolbar}
                                onChange={this.handleChangeQuill(
                                  "company",
                                  this.quillRefAdd
                                )}
                                placeholder="[Company Name]"
                                value={quote.company}
                              />
                            </td>
                          </tr>
                          <tr>
                            <td />
                            <td>
                              <ReactQuill
                                ref={this.quillRefAdd1}
                                theme="bubble"
                                modules={this.modules}
                                scrollingContainer='html'
                                onChange={this.handleChangeQuill(
                                  "company_address",
                                  this.quillRefAdd1
                                )}
                                placeholder="[Company Address]"
                                value={quote.company_address}
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>Attn:</td>
                            <td>
                              <ReactQuill
                                ref={this.quillRefAtn}
                                theme="bubble"
                                modules={this.notoolbar}
                                scrollingContainer='html'
                                onChange={this.handleChangeQuill(
                                  "attn",
                                  this.quillRefAtn
                                )}
                                placeholder="[Attn]"
                                value={quote.attn}
                              />
                            </td>
                          </tr>
                          {/* <tr className="height20" /> */}
                          <tr>
                            <td>Date</td>
                            <td>
                              <span
                                contentEditable="true"
                                suppressContentEditableWarning={true}
                                data-ref="date_created"
                                onBlur={this.handleChange}
                              >
                                {quote.date_created}
                              </span>
                            </td>
                          </tr>
                          <tr>
                            <td>Reference:</td>
                            <td>
                              {/* <span
                                contentEditable="true"
                                suppressContentEditableWarning={true}
                                data-ref="company_acronym"
                                onBlur={this.handleChangeReference}
                              >
                                {quote.reference.company_acronym}
                              </span> */}
                              {"Q"}
                              <span
                                contentEditable="true"
                                suppressContentEditableWarning={true}
                                data-ref="quotation_number"
                                onBlur={this.handleChangeReference}
                              >
                                {quote.reference.quotation_number}
                              </span>
                              {parseInt(quote.revision_version) !== 0 &&
                                "." + quote.revision_version}
                              {"/"}
                              <span
                                contentEditable="true"
                                suppressContentEditableWarning={true}
                                data-ref="quotation_date"
                                onBlur={this.handleChangeReference}
                              >
                                {quote.reference.quotation_date}
                              </span>
                            </td>
                          </tr>
                          <tr className="height20" />
                          <tr>
                            <td>Confidentiality:</td>
                            <td>
                              <input
                                type="checkbox"
                                value={this.state.nda}
                                data-ref="nda"
                                onChange={this.handleChangeNDACheckbox}
                                checked={this.state.nda}
                              />
                            </td>
                          </tr>
                          <tr className="height20" />
                          <tr>
                            <td className="project_name">
                              <strong>Project:</strong>
                            </td>
                            <td>
                              <strong>
                                <span
                                  contentEditable="true"
                                  suppressContentEditableWarning={true}
                                  data-ref="project_name"
                                  onBlur={this.handleChange}
                                  placeholder="[Project name]"
                                >
                                  {quote.project_name}
                                </span>
                              </strong>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </section>
                </div>
                <div className="quote_title">
                  <h1>Quotation</h1>
                  <h1 className="second_main">Confidential</h1>
                </div>
              </div>
              {/* CREATE OVERVIEW COMPONENT */}
              <IntroComponent
                quillRef={this.quillRefScope}
                name="Overview"
                modules={this.modules}
                type="scope"
                handleChangeQuill={this.handleChangeQuill}
                scope={quote.scope}
                placeholder="[Add a short paragraph that provides an overview of the current business situation, the sector, and the macro environment affecting the client's business, if appropriate]"
              />
              {/* CREATE OBJECTIVE COMPONENT */}
              <IntroComponent
                quillRef={this.quillRefObjectives}
                name="Objectives"
                modules={this.modules}
                type="objectives"
                handleChangeQuill={this.handleChangeQuill}
                scope={quote.objectives}
                placeholder="[What are we trying to achieve? How will this initiative create expected value for the client? How is it linked to the client’s Key Performance Indicators?]"
              />
              {/* CREATE KEY DATES COMPONENT */}
              <IntroComponent
                quillRef={this.quillRefKeyDates}
                name="Key dates"
                modules={this.modules}
                type="key_dates"
                handleChangeQuill={this.handleChangeQuill}
                scope={quote.key_dates}
                placeholder="[Add Key dates text here]"
              />
              <br />
              <section className="blocks">
                <div className="row">
                  <h2>
                    Scope of work and fee
                    <span style={{ float: "right" }}>
                      {quote.currency.name}
                    </span>
                  </h2>
                  <DragDropContext onDragEnd={this.onDragEnd}>
                    <Droppable droppableId={"1"}>
                      {(provided) => (
                        <div
                          className="steps"
                          {...provided.droppableProps}
                          ref={provided.innerRef}
                        >
                          {quote.content !== null &&
                            quote.content.map((step, i) => {
                              return (
                                <Step
                                  key={i}
                                  updateContent={this.handleContentChange}
                                  removeStep={this.removeStep}
                                  content={step}
                                  currency={this.state.currency}
                                  step_index={i + 1}
                                  index={i}
                                  discount_percentage={
                                    this.state.discount_percentage
                                  }
                                />
                              );
                            })}
                          {provided.placeholder}
                          <div className="add">
                            <input
                              type="text"
                              className="new-step"
                              list="steplist"
                              data-type="step"
                              placeholder="Title"
                              onChange={this.handleSelect}
                              value={this.state.search}
                            />
                            <button data-ref="content" onClick={this.addStep}>
                              Add step
                            </button>
                          </div>
                        </div>
                      )}
                    </Droppable>
                  </DragDropContext>
                </div>
              </section>
              {/* CREATE PROJECT MANAGEMENT COMPONENT */}
              <ProjectManagement
                quote={this.state.quote}
                handleChangeQuill={this.handleChangeQuill}
                handleChangeNumber={this.handleChangeNumber}
                discount_percentage={this.state.discount_percentage}
              />

              <GrandTotal
                title={"Total"}
                subtitle={`${this.state?.quote?.entity === "sg" ? "(before discount)" : "(excluding additional charges)" }`}
                grandTotal={false}
                beforeTax={true}
                quote={this.state.quote}
                handleChangeNumber={this.handleChangeNumber}
              />

              <Discount
                quote={this.state.quote}
                handleChangeNumber={this.handleChangeNumber}
                handleChangeDiscountPercentage={
                  this.handleChangeDiscountPercentage
                }
                handleChange={this.handleChange}
              />
              {this.state?.quote?.entity === "sg" && 
              <>
              <GrandTotal
              title={"Total"}
              subtitle={`(before tax)`}
              grandTotal={true}
              beforeTax={true}
              quote={this.state.quote}
              handleChangeNumber={this.handleChangeNumber}
            />
              <GrandTotal
                title={"GST"}
                subtitle={"(9%)"}
                grandTotal={true}
                entity = {this.state?.quote?.entity}
                quote={this.state.quote}
                handleChangeNumber={this.handleChangeNumber}
              />
              </>
    }
              <GrandTotal
                title={"Grand total"}
                subtitle={""}
                grandTotal={true}
                quote={this.state.quote}
                handleChangeNumber={this.handleChangeNumber}
              />

              <section>
                <h3 className="no_margin_bottom">Optional items</h3>
                <p className="optional_items_note">
                  (Does not include 15% account and project management fee)
                </p>
                <DragDropContext onDragEnd={this.onDragEnd}>
                  <Droppable droppableId={"1"}>
                    {(provided) => (
                      <div
                        className="optional_items"
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                      >
                        {quote.optional_items !== null &&
                          quote.optional_items.map((step, i) => {
                            return (
                              <Step
                                key={i}
                                updateContent={this.handleOptionalItemsChange}
                                removeStep={this.removeStep}
                                content={step}
                                currency={quote.currency}
                                step_index={i + 1}
                                index={i}
                              />
                            );
                          })}
                        {provided.placeholder}
                        <div className="add">
                          <button
                            data-ref="optional_items"
                            onClick={this.addStep}
                          >
                            Add new item
                          </button>
                        </div>
                      </div>
                    )}
                  </Droppable>
                </DragDropContext>
              </section>
              <Terms
                terms={quote.terms}
                updateTerms={this.handleTermsChange.bind(this)}
                removeTerms={this.removeTerms}
                addTerms={this.addTerms}
                termsList={terms_results}
              />
              <Signature
                user={quote.author.id !== -1 ? quote.author : this.props.user}
                entity={quote?.entity}
              />
            </div>
            <div className="container">
              <div className="content">
                <button className="float-right" onClick={this.handleCreateSave}>
                  <b>SAVE</b> <FontAwesomeIcon icon="save" />
                </button>
                <br />
                <br />
              </div>
            </div>
          </div>
          {/*select template from list of steps check*/}
          <datalist id="steplist">
            {isLoadedSteps === true ? (
              step_results.map((step, i) => {
                return <option key={i} value={step.title} data-id={step.id} />;
              })
            ) : (
              <option>No results.</option>
            )}
          </datalist>
          <datalist id="termlist">
            {isLoadedSteps === true ? (
              terms_results.map((term, i) => {
                return <option key={i} value={term.title} data-id={term.id} />;
              })
            ) : (
              <option>No results.</option>
            )}
          </datalist>
          {/* <NotificationBox notification={notification} type='success' title='b' text='a'/> */}
        </div>
      );
    }
  }

  //saving quote
  handleSave = async (quote_parameter, event) => {
    if (event) event.preventDefault();
    let savingQ = null;
    if (
      quote_parameter.project_name !== "" &&
      parseInt(quote_parameter.id) > 0
    ) {
      try {
        savingQ = await this.props.saveQuote(quote_parameter);
      } catch (e) {
        console.error(e);
      }
      if (savingQ) {
        this.setState({
          isLoaded: true,
          saved: true,
          change:false,
          notification: true,
          last_saved: new Date(),
        });
      }
    } else {
      this.setState({
        error: true,
        errorMessage: "Quote could not be saved, please ask administrator for more details."
      });
    }
    return savingQ;
  };
  //putting handleSave and handleCreate function together to reduce code redundancy
  handleCreateSave = async () => {
    let quoteTmp = { ...this.state.quote };
    if (this.state.quote.project_name !== "" && this.props.user.id > 0) {
      if (
        this.state.created ||
        (parseInt(this.state.quote.id) > 0 && !this.state.change)
      ) {
        console.log("Saving quote: " + this.state.quote.id);
        //Saving existing quote
        let saveQ = null;
        try {
          saveQ = await this.handleSave(quoteTmp);
        } catch (e) {
          console.error(e);
        }
        if (saveQ) {
          console.log("Quote was saved");
          // console.log(this.state.initial_status);
          // console.log(this.state.quote.status);
          if (this.state.initial_status !== this.state.quote.status) {
            if (
              this.state.quote.status === "review" ||
              this.state.quote.status === "checked"
            ) {
              //send email to SB or SW for review or send email to the author that the quote has been checked
              this.sendEmail();
            }
          }
          this.setState({
            initial_status: this.state.quote.status,
          });
        }
      } else {
        //creating a new quote!
        console.log(
          "handleCreateSave - Creating quote: Q" +
            quoteTmp.reference.quotation_number
        );
        console.log(
          "handleCreateSave - Creating Quote with user:",
          this.props.user.id
        );

        const unlockingQuote = await this.unlockQuote(); //unlocking old one before changing the attributes

        const creatingQuote = await this.props.createQuote(this.props.user.id);
        if (creatingQuote) {
          // console.log(creatingQuote);
          this.setState({
            created: true,
          });

          console.log(
            "Getting the user that created the new quote",
            this.props.user
          );

          quoteTmp.id = this.props.created_quote_id;
          let historyPush = false;
          if (
            quoteTmp.revised_quote_id === 0 &&
            quoteTmp.version_quote_id === 0
          ) {
            quoteTmp.revised_quote_id = this.props.created_quote_id;
            quoteTmp.version_quote_id = this.props.created_quote_id;
            historyPush = true;
          }
          console.log(quoteTmp.author.id);
          if (quoteTmp.author.id === -1) {
            quoteTmp.author = this.props.user;
          }

          quoteTmp.last_modified_by = this.props.user;

          quoteTmp.version_number = parseInt(quoteTmp.version_number) + 1;

          console.log(
            "handleCreateSave - Saving quote: " + this.props.created_quote_id
          );

          let savingQuote = null;
          try {
            savingQuote = await this.handleSave(quoteTmp);
          } catch (e) {
            console.error(e);
          }
          if (savingQuote) {
            this.setState({
              quote: quoteTmp,
            });

            if (historyPush) {
              this.props.history.push("/quote/" + quoteTmp.id);
            }
          }
        }
      }
    } else {
      if (this.props.user.id < 0)
        alert("Something went wrong, please contact system administrator!");
      else alert("In order to save please add project name");
    }
  };
  //lock up the quote so no other can use it
  lockQuote(user) {
    let quoteTmp = { ...this.state.quote };
    quoteTmp.locked = user;
    this.handleSaveLock(quoteTmp);
    // if (localStorage.getItem('locked_quote') !== null) {
    //     let localStorageLockedQuotes = this.checkLockedQuotesStorage();
    //     localStorage.setItem('locked_quote', JSON.stringify(localStorageLockedQuotes));
    // } else {
    //     localStorage.setItem('locked_quote', JSON.stringify(quoteTmp));
    // }
    this.setState({
      quote: quoteTmp,
    });
  }
  //unlocking quote when leaving quote page
  async unlockQuote() {
    let quoteTmp = { ...this.state.quote };
    let unlockingQuote = null;
    if (quoteTmp.locked) {
      if(quoteTmp.locked.id === this.props.user.id) {
        console.log("unlockQuote - ", quoteTmp);
        quoteTmp.locked = null;
        unlockingQuote = await this.handleSaveLock(quoteTmp);
        // let localStorageLockedQuotes = this.checkLockedQuotesStorage(true);
        // localStorage.setItem('locked_quote', JSON.stringify(localStorageLockedQuotes));
        //prevent setState when unmounting
        //console.log(unlockingQuote);
        if (this._isMounted) {
          this.setState({
            quote: quoteTmp,
            locked: false,
            //isLoaded: false //commented out because it keeps refreshing the quote
          });
        }
      }
    }
    return unlockingQuote;
  }
  // take ownership of the quote
  forceUnlock = (event) => {
    const r = window.confirm(
      "Do you really want to force unlock this quote (" +
        this.state.quote.project_name +
        ")? Have you asked " +
        this.state.quote.locked.firstname +
        " first?"
    );
    if (r === true) {
      this.lockQuote(this.props.user);
      this.setState({
        locked: false,
      });
    }
  };

  async sendEmail() {
    console.log("sending email");
    let sendingEmail = null;
    try {
      sendingEmail = await this.props.sendEmail(this.state.quote);
    } catch (e) {
      console.error(e);
    }

    console.log(sendingEmail);
  }

  //this function is not needed for now 23.10.2019
  //was meant to check lockalstorage if there are any quotes in and unlock them when on other pages
  // checkLockedQuotesStorage(unlock) {
  //     let tmplockedquotes = [];
  //     let lockedQuotes = JSON.parse(localStorage.getItem('locked_quote'));
  //     if (Array.isArray(lockedQuotes)) {
  //         try {
  //             lockedQuotes.map((q) => {
  //                 if (q.id !== this.state.quote.id) tmplockedquotes.push(q);
  //             });
  //         } catch (e) {
  //             console.log(e);
  //         }
  //     } else {
  //         tmplockedquotes.push(lockedQuotes);
  //     }
  //     if (unlock === false || unlock === undefined) {
  //         tmplockedquotes.push(this.state.quote);
  //     }
  //     return tmplockedquotes;
  // }
  //saving lock when lock changes
  async handleSaveLock(quote_parameter) {
    console.log(quote_parameter);
    let savingQuoteLock = null;
    if (quote_parameter.id !== -1) {
      console.log("handleSaveLock - before savingQuoteLock");

      try {
        savingQuoteLock = await this.props.saveQuoteLock(quote_parameter);
        console.log("savingQuoteLock", savingQuoteLock);
        if (savingQuoteLock.data.data.status === "success") {
          if (this._isMounted) {
            this.setState({
              isLoaded: true,
            });
          } else {
            if (this._isMounted) {
              this.setState({
                error: true,
              });
            }
          }
        }
        return savingQuoteLock.data;
      } catch (e) {
        this.setState({
          error: true,
        });
        console.log(e);
      }
    }
  }
  //duplicate quote
  async handleDuplicateQuote(quote) {
    const r = window.confirm(
      "Do you really want to duplicate this quote (" +
        this.state.quote.project_name +
        ")?"
    );
    if (r === true) {
      let duplicated_quote = JSON.parse(JSON.stringify(this.state.quote));
      duplicated_quote.reference.quotation_number =
        this.props.latest_quotation_number;
      console.log(
        "Creating new duplicated quote: Q" +
          duplicated_quote.reference.quotation_number
      );

      const unlockingQuote = await this.unlockQuote(); //unlocking old one before changing the attributes

      let creatingQuote = null;

      try {
        creatingQuote = await this.props.createQuote(this.props.user.id);
      } catch (e) {
        console.error(e);
      }

      if (creatingQuote) {
        console.log(creatingQuote);
        console.log(
          "handleDuplicateQuote - Saving duplicated quote: " +
            this.props.created_quote_id
        );
        duplicated_quote.has_revision = 0;
        duplicated_quote.revision_version = 0;
        duplicated_quote.id = this.props.created_quote_id;
        duplicated_quote.revised_quote_id = this.props.created_quote_id;
        duplicated_quote.version_quote_id = this.props.created_quote_id;
        duplicated_quote.version_number = 0;
        duplicated_quote.author = this.props.user;
        duplicated_quote.last_modified_by = this.props.user;
        duplicated_quote.project_name =
          this.state.quote.project_name + " Duplicate";
        duplicated_quote.status = "active";
        duplicated_quote.date_created = this.getDateString();
        duplicated_quote.reference.quotation_date = this.state.date
          .toISOString()
          .substr(0, 10)
          .replace(/-/g, "");
        duplicated_quote.locked = null;

        let savingQuote = null;

        try {
          savingQuote = await this.handleSave(duplicated_quote);
        } catch (e) {
          console.error(e);
        }

        if (savingQuote) {
          console.log(
            "handleDuplicateQuote - Saving duplicated quote is DONE: " +
              savingQuote
          );
          this.setState({
            isLoaded: false,
          });

          setTimeout(() => {
            this.props.history.push("/quote/" + duplicated_quote.id);
          }, 2000);
        }
      }
    }
  }

  //create new revision after quote has been marked as archieved(that means quote was sent out to the client but client requested changes to the quote)
  handleCreateNewRevision = async () => {
    const r = window.confirm(
      "Do you really want to create new revision of the quote (" +
        this.state.quote.project_name +
        ")?"
    );
    if (r === true) {
      this.setState({
        isLoaded: false,
      });
      let revision_quote = JSON.parse(JSON.stringify(this.state.quote));
      console.log(
        "handleCreateNewRevision - Creating quote: Q" +
          this.state.quotationNumber
      );
      let creatingQuote = null;
      try {
        creatingQuote = await this.props.createQuote(this.props.user.id);
      } catch (e) {
        console.error(e);
      }
      if (creatingQuote) {
        console.log(
          "handleCreateNewRevision - Saving revision_quote quote: " +
            this.props.created_quote_id
        );
        revision_quote.reference.quotation_number =
          this.state.quote.reference.quotation_number;
        revision_quote.has_revision = 0;
        revision_quote.revision_version =
          parseInt(this.state.quote.revision_version) + 1;
        revision_quote.id = this.props.created_quote_id;
        revision_quote.revised_quote_id = this.state.quote.revised_quote_id;
        revision_quote.version_quote_id = this.props.created_quote_id;
        revision_quote.version_number = 0;
        revision_quote.author = this.props.user;
        revision_quote.last_modified_by = this.props.user;
        revision_quote.project_name = this.state.quote.project_name;
        revision_quote.status = "active";
        revision_quote.reference.quotation_date = this.state.date
          .toISOString()
          .substr(0, 10)
          .replace(/-/g, "");

        let savingQuote = null;
        console.log("handleCreateNewRevision - saving Quote");
        try {
          savingQuote = await this.handleSave(revision_quote);
        } catch (e) {
          console.error(e);
        }

        if (savingQuote) {
          console.log("handleCreateNewRevision - quote saved");
          this.unlockQuote();
          setTimeout(() => {
            this.props.history.push("/quote/" + revision_quote.id);
          }, 1000);
        }
      }
    }
  };
  // handleCreateNewVersion = async (event) => {
  //   if (event) event.preventDefault();
  //   let new_version_quote = JSON.parse(JSON.stringify(this.state.quote));
  //   console.log(new_version_quote);
  //   // console.log(
  //   //   "handleCreateNewVersion - Creating quote: Q" +
  //   //     this.state.quote.reference.quotation_number
  //   // );
  //   console.log(this.props.user.id);
  //   let creatingQuote = null;
  //   try {
  //     creatingQuote = await this.props.createQuote(this.props.user.id);
  //   } catch (e) {
  //     console.error(e);
  //   }

  //   if (creatingQuote) {
  //     new_version_quote.reference.quotation_number =
  //       this.state.quote.reference.quotation_number;
  //     new_version_quote.has_revision = 0;
  //     new_version_quote.version_number =
  //       parseInt(this.state.quote.version_number) + 1;
  //     new_version_quote.id = this.props.created_quote_id;
  //     new_version_quote.last_modified_by = this.props.user;
  //     new_version_quote.project_name = this.state.quote.project_name;
  //     if(this.state.quote.revised_quote_id === 0) {
  //       new_version_quote.revised_quote_id = this.props.created_quote_id
  //     }
  //     if(this.state.quote.version_quote_id === 0) {
  //       new_version_quote.version_quote_id = this.props.created_quote_id
  //     }
  //     if(new_version_quote.author.id === -1) {
  //       new_version_quote.author = this.props.user;
  //     }

  //     let savingQuote = null;

  //     try {
  //       savingQuote = await this.handleSave(new_version_quote);
  //     } catch (e) {
  //       console.error(e);
  //     }

  //     // console.log("handleCreateNewVersion - savingQuote", savingQuote);
  //     let unlockingQuote = null;
  //     if (savingQuote) {
  //       unlockingQuote = await this.unlockQuote();
  //       if (unlockingQuote) {
  //         this.setState({
  //           quote: new_version_quote,
  //           change: false,
  //         });
  //         setTimeout(() => {
  //           this.setState({ saved: false });
  //         }, 3000);
  //       }
  //     }
  //   }
  // };
}

const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.auth.isSignedIn,
    user: state.auth.user,
    users: state.user.users,
    quote: state.quotes.quote,
    created_quote_id: state.quotes.created_quote_id,
    saved_quote: state.quotes.saved_quote,
    template: state.templates.template,
    steps: state.templates.step_templates,
    terms: state.templates.terms_templates,
    quote_locked: state.quotes.quote_locked,
    latest_quotation_number: state.quotes.latest_quotation_number,
  };
};

export default connect(mapStateToProps, {
  fetchUser,
  fetchUsers,
  fetchQuoteById,
  createQuote,
  saveQuote,
  saveQuoteLock,
  fetchTemplateById,
  fetchTemplatesByType,
  quoteLockCheck,
  latestQuotationNumber,
  sendEmail,
})(Quote);
