export const AuthorizationService = {
    login,
    logout,
    isAuthenticated,
    getUser
};

async function login(email, password, remember_me) {
    let msg = '';
    await fetch(process.env.REACT_APP_API + '/user/authenticate.php', {
        method: 'POST',
        body: JSON.stringify({
            email: email,
            password: password
        })
    })
        .then((res) => res.json())
        .then((json) => {
            if (json.status === 'success') {
                let id = json.data;
                fetch(process.env.REACT_APP_API + '/user/get.php?id=' + id)
                    .then((res) => res.json())
                    .then((json) => {
                        if (json.status === 'success') {
                            window.localStorage.setItem('name', json.data.firstname);
                            window.localStorage.setItem('user', JSON.stringify(json.data));
                            window.localStorage.setItem('last_login', new Date());
                            window.localStorage.setItem('authenticated', true);
                            window.localStorage.setItem('remember_me', remember_me);
                            msg = json;
                        }
                    });
                msg = json;
            } else {
                msg = json;
            }
        });
    return msg;
}

function isAuthenticated() {
    checkifAuthExpired();

    let user = JSON.parse(window.localStorage.getItem('user'));
    let authenticated = window.localStorage.getItem('authenticated');
    if (user !== null && authenticated !== null) {
        if (user.id !== -1 && authenticated === 'true') {
            return true;
        }
    }
    return false;
}

function getUser() {
    let user = JSON.parse(window.localStorage.getItem('user'));
    if (user !== null && user.id !== -1) {
        return user;
    }
    return null;
}

function checkifAuthExpired() {
    const date = new Date().getTime();
    const last_login = window.localStorage.getItem('last_login');
    let calcDate = (date - last_login) / 1000;
    if (window.localStorage.getItem('remember_me') === 'false') {
        if (calcDate >= 86400) {
            window.localStorage.removeItem('authenticated');
            window.localStorage.removeItem('user');
        }
    } else {
        if (calcDate >= 604800) {
            window.localStorage.removeItem('authenticated');
            window.localStorage.removeItem('user');
        }
    }
}

function logout() {
    window.localStorage.removeItem('authenticated');
    window.localStorage.removeItem('user');
    //window.location.reload();
}
