import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { fetchTemplates } from '../../actions';
import Loading from '../components/Loading';
import Title from '../components/Title';

const TemplatesList = ({ fetchTemplates, templates }) => {
    const [filters, setFilters] = useState({
        showDisabled: false,
        showSteps: true,
        showItems: true,
        showTerms: false,
        filterSearch: '',
    });

    const [dataStats, setDataStats] = useState({
        numOfItems: 0,
        numOfSteps: 0,
        numOfDisabled: 0,
        numOfTerms: 0,
    });
    
    const [filtered, setFiltered] = useState([]);
    const [isLoaded, setIsLoaded] = useState(false);

    async function fetchData() {
        await fetchTemplates();
        calculateData(templates);
        if(templates) {
            filterTemplates(templates);
        }
        setIsLoaded(true);
    }

    useEffect(() => {
        fetchData();
    }, []);

    useEffect(() => {
        if(templates)
            filterTemplates(templates);
            calculateData(templates); 
    }, [templates]);

    useEffect(() => {
        if(templates) {
            filterTemplates(templates);
            calculateData(templates); 
        }
    }, [filters]);

    const calculateData = (templates) => {
        let newState = {
            numOfDisabled: 0,
            numOfItems: 0,
            numOfSteps: 0,
            numOfTerms: 0,
        };
        if(templates && Array.isArray(templates)) {
            templates.forEach((t) => {
                if (t.is_disabled === '1') {
                    newState.numOfDisabled += 1;
                }
                if (t.type === 'item') {
                    newState.numOfItems += 1;
                } else if (t.type === 'step') {
                    newState.numOfSteps += 1;
                } else if (t.type === 'terms') {
                    newState.numOfTerms += 1;
                }
            });
        }
        setDataStats(newState);
    };

     // Separate filtering logic
     const filterTemplates = (templates) => {
        const { showSteps, showItems, showTerms, showDisabled, filterSearch } = filters;

        const filterByType = (type) => {
            return templates.filter(template => template.type == type && template.is_disabled == 0);
        };
    
        const filterDisabled = () => {
            return templates.filter(template => template.is_disabled == 1);
        };
    
        const filterBySearch = (arr) => {
            if (filterSearch.length === 0) return arr;
    
            const searchLower = filterSearch.toLowerCase();
            return arr.filter(template => template.title.toLowerCase().includes(searchLower));
        };
    
        let filteredTemplates = [];
        
        if (showSteps) {
            filteredTemplates.push(...filterByType('step'));
        }
    
        if (showItems) {
            filteredTemplates.push(...filterByType('item'));
        }
    
        if (showTerms) {
            filteredTemplates.push(...filterByType('terms'));
        }
    
        if (showDisabled) {
            filteredTemplates.push(...filterDisabled());
        }

        filteredTemplates = filterBySearch(filteredTemplates);

        setFiltered(filteredTemplates);
    };

    const handleInputChange = (event) => {
        const { id, value, checked } = event.target;
        const newFilters = { ...filters };

        if (id === 'search') {
            newFilters.filterSearch = value;
        } else {
            newFilters[id] = checked;
        }

        setFilters(newFilters);

    };

    const {
        showDisabled,
        showSteps,
        showItems,
        showTerms,
        filterSearch // Added this as it's a part of filters
    } = filters;

    const {
        numOfDisabled,
        numOfSteps,
        numOfItems,
        numOfTerms
    } = dataStats;

    if (!isLoaded) {
        return <Loading gif='/images/stepworks.gif' message='Loading..' />;
    }

    return (
        <div>
            <Title title='Template List' />
            <p className='mb-4' />
            <div className='content-flex'>
                <div className='shadow templates-table'>
                    <div className='card-header'>
                        <h4 className='m-0 font-weight-bold text-primary'>
                            Template records
                        </h4>
                    </div>
                    <div className='card-body'>
                        <div className='table-responsive data-table'>
                            {filtered.length === 0 ? (
                                <div>
                                    <br />
                                    <br />No results. Please try with different filters.
                                </div>
                            ) : (
                                <table
                                    className='table table-bordered'
                                    id='dataTable'
                                    width='100%'
                                    cellSpacing='0'
                                >
                                    <thead>
                                        <tr>
                                            <th>Title</th>
                                            <th>Type</th>
                                            <th>Last modified</th>
                                            <th>Last modified by</th>
                                            {showDisabled && <th>Status</th>}
                                        </tr>
                                    </thead>
                                    <tfoot>
                                        <tr>
                                            <th>Title</th>
                                            <th>Type</th>
                                            <th>Last modified</th>
                                            <th>Last modified by</th>
                                            {showDisabled && <th>Status</th>}
                                        </tr>
                                    </tfoot>
                                    <tbody>
                                        {filtered.map((template, i) => (
                                            <tr key={i}>
                                                <td data-label='Title'>
                                                    <Link to={'/template/' + template.id}>
                                                        {template.title}
                                                    </Link>
                                                </td>
                                                <td data-label='Type'>
                                                    <Link to={'/template/' + template.id}>
                                                        {template.type}
                                                    </Link>
                                                </td>
                                                <td data-label='Last modified'>
                                                    <Link to={'/template/' + template.id}>
                                                        {template.date_modified}
                                                    </Link>
                                                </td>
                                                <td data-label='Last modified by'>
                                                    <Link to={'/template/' + template.id}>
                                                        {template.last_modified_by && template.last_modified_by.firstname + ' ' + template.last_modified_by.lastname}
                                                    </Link>
                                                </td>
                                                {showDisabled && (
                                                    <td>
                                                        {template.is_disabled === '1' ? (
                                                            <div className='status btn-danger text'>disabled</div>
                                                        ) : (
                                                            <div className='status btn-success text'>enabled</div>
                                                        )}
                                                    </td>
                                                )}
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            )}
                        </div>
                    </div>
                </div>
                <div className='filter'>
                    <div className='shadow'>
                        <div className='card-header'>
                            <h4 className='m-0 font-weight-bold text-primary'>Filter</h4>
                        </div>
                        <div className='card-body'>
                            <div className='table-responsive filter-table'>
                                <table
                                    className='table table-bordered'
                                    id='dataTable'
                                    width='100%'
                                    cellSpacing='0'
                                >
                                    <tbody>
                                        <tr className='label'>
                                            <td>
                                                <b>Search</b>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <input
                                                    type='text'
                                                    id='search'
                                                    placeholder='Search for templates...'
                                                    onChange={handleInputChange}
                                                />
                                            </td>
                                        </tr>
                                        <tr className='label'>
                                            <td>
                                                <b>Type</b>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <input type='checkbox' id='showSteps' checked={showSteps} onChange={handleInputChange} /> Steps ({numOfSteps})
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <input type='checkbox' id='showItems' checked={showItems} onChange={handleInputChange} /> Items ({numOfItems})
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <input type='checkbox' id='showTerms' checked={showTerms} onChange={handleInputChange} /> Terms ({numOfTerms})
                                            </td>   
                                        </tr>
                                        <tr>
                                            <td>
                                                <input type='checkbox' id='showDisabled' checked={showDisabled} onChange={handleInputChange} /> Show disabled ({numOfDisabled})
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        templates: state.templates.templates
    };
};

export default connect(mapStateToProps, { fetchTemplates })(TemplatesList);
