import React from "react";

const Title = props => {
  let firstChar = "T";
  let restOfTheTitle = "itle";

  if (props.title) {
    firstChar = props.title.slice(0, 1);
    restOfTheTitle = props.title.slice(1, props.title.length);
  }

  return (
    <h1 className="h4 text-gray-900 mb-4 super">
      <u>{firstChar}</u>
      {restOfTheTitle}
    </h1>
  );
};

export default Title;
