import React from "react";
import { AuthorizationService } from "../utils/auth";
import { User } from "../objects/User";
class Login extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      email: "",
      password: "",
      name: "",
      authenticated: false,
      response_message: "",
      user: new User()
    };
  }

  componentDidMount() {
    const remember_me_local = localStorage.getItem("rememberMe") === "true";
    const email_local = localStorage.getItem("email");
    const name_local = localStorage.getItem("name");
    let authenticated_local = localStorage.getItem("authenticated");

    this.setState({
      name: name_local,
      email: email_local,
      remember_me: remember_me_local,
      authenticated: authenticated_local
    });
  }

  validateForm() {
    return this.state.email.length > 0 && this.state.password.length > 0;
  }

  handleChange = event => {
    this.setState({
      [event.target.id]: event.target.value
    });
  };

  handleChangeCheckbox = event => {
    this.setState({
      [event.target.id]: event.target.checked
    });
  };

  handleSubmit = async event => {
    event.preventDefault();

    const auth = await AuthorizationService.login(
      this.state.email,
      this.state.password,
      this.state.remember_me
    );
    this.setState({
      response_message: auth.message
    });
    if (auth.status === "success") {
      setTimeout(()=> {
        window.location.reload();
      }, 500)
    }
  };
  render() {
    let { name, response_message } = this.state;

    return (
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-xl-10 col-lg-12 col-md-9">
            <div className="card shadow">
              <div className="card-body p-0">
                <div className="row">
                  <div className="bg-login-image" />
                  <div className="col-lg-12">
                    <div className="p-5">
                      <div className="text-center">
                        <h1 className="h4 text-gray-900 mb-4">
                          Welcome{name && " back " + name}!
                        </h1>
                      </div>
                      <form
                        className="user"
                        method="post"
                        onSubmit={this.handleSubmit}
                        encType="multipart/form-data"
                      >
                        <div className="form-group">
                          <input
                            type="email"
                            className="form-control form-control-user"
                            id="email"
                            aria-describedby="emailHelp"
                            placeholder="[Enter Email Address...]"
                            onChange={this.handleChange}
                            required
                          />
                        </div>
                        <div className="form-group">
                          <input
                            type="password"
                            className="form-control form-control-user"
                            id="password"
                            placeholder="[Password]"
                            onChange={this.handleChange}
                            required
                          />
                        </div>
                        <div className="form-group">
                          <div className="custom-control custom-checkbox small">
                            <input
                              type="checkbox"
                              className="custom-control-input"
                              id="remember_me"
                              onChange={this.handleChangeCheckbox}
                            />
                            <label className="custom-control-label">
                              Remember Me (7 days)
                            </label>
                          </div>
                        </div>
                        <input
                          type="submit"
                          value="Login"
                          className="btn btn-primary btn-user btn-block"
                        />
                      </form>
                      <div>
                        <br />
                        <p>{response_message}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default Login;
