import React, { PureComponent } from "react";
import { Link } from "react-router-dom";

//redux
import { connect } from "react-redux";
import {
  fetchQuotes,
} from "../../actions";

//components
import Loading from "../components/Loading";
import { commaFormatting } from "../utils/commaformatting";
// import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';


class Dashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      quotes: [],
      isLoaded: false,
      total_sum_last_month: 0,
      total_sum_this_year:0,
      total_approved_last_month:0,
      total_currently_active:0,
      lost: 0,
      approved: 0,
      active: 0, 
      months: ["January", "February", "March", "April", "May", "June", "July",
         "August", "September", "October", "November", "December"],
      month_year_arr: []     
    };
  }

  async componentDidMount() {
    let active = 0;
    let checked = 0;
    let approved = 0;
    let total_sum_last_month = 0;
    let total_sum_this_year = 0;
    let total_approved_last_month = 0;
    let total_lost_last_month = 0
    let total_currently = 0
    let have_quotes = false;
    let month_year_array = []

    await this.props.fetchQuotes();

    if(this.props.quotes) {
      this.props.quotes.map(quote => {
        if (quote.author.id === this.props.user.id) {
          have_quotes = true;
        }
        // total_sum_last_month += this.checkEarningsLastMonth(quote);
        // total_sum_this_year += this.checkEarningsLastMonth(quote);

        // total_approved_last_month += this.checkApprovedLastMonth(quote);
        // total_lost_last_month += this.checkLostLastMonth(quote);
        // total_currently += this.checkCurrentlyActive(quote);

      });

      // month_year_array = this.checkEarningsByMonthAndYear(this.props.quotes);

      // console.log(month_year_array);

      this.setState({
        isLoaded: true,
        quotes: this.props.quotes,
        total_sum_last_month: commaFormatting(total_sum_last_month),
        total_approved_last_month: total_approved_last_month,
        total_currently_active: total_currently,
        lost: total_lost_last_month,
        month_year_arr: month_year_array,
        have_quotes,
        active,
      });
    }
  }  

  // checkCurrentlyActive(quote) {
  //   let active = 0;
  //   if(quote.status === "active") {
  //     let current_month = new Date().getMonth();
  //     let current_year = new Date().getFullYear();
  //     let prev_month = current_month - 1;
  //     let prev_year = current_year;
  //     if(prev_month < 0) {
  //         prev_month = 11;
  //         prev_year = prev_year - 1;
  //     }
  //     let quote_month = new Date(quote.date_created).getMonth();
  //     let quote_year = new Date(quote.date_created).getFullYear();
  //     if ((quote_month === prev_month && quote_year === prev_year) || (current_month == quote_month && quote_year === current_year)) {
  //       active++;
  //     }
  //   }
  //   return active;
  // }

  // checkApprovedLastMonth(quote) {
  //   let approved = 0;
  //   if(quote.status === "approved") {
  //     let current_month = new Date().getMonth();
  //     let current_year = new Date().getFullYear();
  //     let prev_month = current_month - 1;
  //     let prev_year = current_year;
  //     if(prev_month < 0) {
  //         prev_month = 11;
  //         prev_year = prev_year - 1;
  //     }
  //     let quote_month = new Date(quote.date_created).getMonth();
  //     let quote_year = new Date(quote.date_created).getFullYear();
  //     if (quote_month === prev_month && quote_year === prev_year) {
  //       approved++;
  //     }
  //   }
  //   return approved;
  // }

  // checkLostLastMonth(quote) {
  //   let lost = 0;
  //   if(quote.status === "lost" || quote.status === "archive") {
  //     let current_month = new Date().getMonth();
  //     let current_year = new Date().getFullYear();
  //     let prev_month = current_month - 1;
  //     let prev_year = current_year;
  //     if(prev_month < 0) {
  //         prev_month = 11;
  //         prev_year = prev_year - 1;
  //     }
  //     let quote_month = new Date(quote.date_created).getMonth();
  //     let quote_year = new Date(quote.date_created).getFullYear();
  //     if (quote_month === prev_month && quote_year === prev_year) {
  //       lost++;
  //     }
  //   }
  //   return lost;
  // }

  
  // checkEarningsLastMonth(quote) {
  //   let total = 0;
  //   if(quote.status === "approved") {
  //     let current_month = new Date().getMonth();
  //     let current_year = new Date().getFullYear();
  //     let prev_month = current_month - 1;
  //     let prev_year = current_year;
  //     if(prev_month < 0) {
  //         prev_month = 11;
  //         prev_year = prev_year - 1;
  //     }
  //     let quote_month = new Date(quote.date_created).getMonth();
  //     let quote_year = new Date(quote.date_created).getFullYear();
  //     let exchange_rate = {"HKD": 1, "HK$": 1, "USD": 0.1274, "US$": 0.1274, "CNY": 0.8721, "SGD": 0.1700, "GBP": 0.1052};
  //     if (quote_month === prev_month && quote_year === prev_year) {
  //         let qt = parseInt(quote.total) / parseFloat(exchange_rate[quote.currency.name]);
  //         let qt_dp_d = parseFloat(quote.duties_percentage / 100) * parseFloat(qt) + parseFloat(qt) - parseFloat(quote.discount)
  //         total += qt_dp_d;
  //     }
  //   }
  //   return total;
  // }

  // checkEarningsByMonthAndYear(quotes) {
  //   let totals = Array(12).fill().map((_, i) => {
  //     return {month: new Date(0, i).toLocaleString("default", { month: "short" })};
  //   });
  //   let exchange_rate = {"HKD": 1, "HK$": 1, "USD": 0.1274, "US$": 0.1274, "CNY": 0.8721, "SGD": 0.1700, "GBP": 0.1052};
  //   for (let i = 0; i < quotes.length; i++) {
  //     let quote = quotes[i];
  //     if (quote.status === "approved") {
  //       let quote_month = new Date(quote.date_created).getMonth();
  //       let quote_year = new Date(quote.date_created).getFullYear();
  //       if(totals[quote_month][quote_year] === undefined) {
  //         totals[quote_month][quote_year] = 0;
  //       } 
  //         let qt = parseInt(quote.total) / parseFloat(exchange_rate[quote.currency.name]);
  //         let qt_dp_d = parseFloat(quote.duties_percentage / 100) * parseFloat(qt) + parseFloat(qt) - parseFloat(quote.discount)

  //         if(totals[quote_month][quote_year] != NaN)
  //           totals[quote_month][quote_year] += parseInt(qt_dp_d);
  //     }
  //   }
  //   return totals;
  // }


  render() {
    let {
      isLoaded,
      quotes,
      have_quotes,
      lost,
      total_approved_last_month,
      total_sum_last_month,
      total_currently_active
    } = this.state;
    
    if (!isLoaded) {
      return <Loading message="Loading ..." gif="/images/stepworks.gif" />;
    } else {
      return (
        <div className="dashboard">
          <div className="container-fluid">
            <h2 className="h3 mb-2 text-gray-800 heading">
              Hello {this.props.user.firstname}, this is your
            </h2>
            <h1 className="h3 mb-4 super">
              <b>
                <u>D</u>ashboard.
              </b>
            </h1>
            {
              // <div className="row">
              //   <div className="col-xl-6 col-md-12 mb-4" style={{display:"flex", flexWrap:"wrap"}}>
              //     <div className="col-xl-6 col-md-12 mb-4">
              //       <div className="card border-left-primary">
              //         <div className="row">
              //           <div className="col mr-2">
              //             <div className="">Earnings (last month)</div>
              //             <div className="">
              //               <b>{"HKD" + total_sum_last_month}</b>
              //             </div>
              //           </div>
              //         </div>
              //       </div>
              //     </div>
              //     <div className="col-xl-6 col-md-12 mb-4">
              //       <div className="card border-left-default">
              //         <div className="row ">
              //           <div className="col mr-2">
              //             <div>Active (this & last month)</div>
              //             <div>
              //               <b>{total_currently_active}</b>
              //             </div>
              //           </div>
              //         </div>
              //       </div>
              //     </div>
              //     <div className="col-xl-6 col-md-12 mb-4">
              //       <div className="card border-left-success">
              //         <div className="row ">
              //           <div className="col mr-2">
              //             <div>Won (last month)</div>
              //             <div>
              //               <b>{total_approved_last_month}</b>
              //             </div>
              //           </div>
              //         </div>
              //       </div>
              //     </div>
              //     <div className="col-xl-6 col-md-12 mb-4">
              //       <div className="card border-left-warning">
              //         <div className="row ">
              //           <div className="col mr-2">
              //             <div>Lost (last month)</div>
              //             <div>
              //               <b>{lost}</b>
              //             </div>
              //           </div>
              //         </div>
              //       </div>
              //     </div>
              //   </div>
              //   <div className="col-xl-6 col-md-12">
              //       <ResponsiveContainer width="100%" height="100%">
              //         <AreaChart
              //           data={this.state.month_year_arr}
              //           margin={{
              //             top: 20,
              //             right: 20,
              //             left: 20,
              //             bottom: 30,
              //           }}
              //         >
              //           {/* <CartesianGrid strokeDasharray="3 3" /> */}
              //           <XAxis dataKey="month" />
              //           <YAxis />
              //           <Tooltip content={<CustomTooltip />}/>
              //           <Legend />
              //           <Area connectNulls type="monotone" dataKey="2023" stroke="#eb0c38" fill="#eb0c38" activeDot={{ r: 1 }} />
              //           <Area connectNulls type="monotone" dataKey="2022" stroke="#ff456a8c" fill="#ff456a8c" />
              //         </AreaChart>
              //       </ResponsiveContainer>
              //     </div>

              // </div>



            }

            <div className="row">
              <div className="col-xl-8 col-lg-12">
                {!have_quotes ? (
                  <p>
                    You haven't created any quotes yet. Create one{" "}
                    <Link to={"quote"}>here.</Link>
                  </p>
                ) : (
                  <div className="shadow quotes-template">
                    <div>
                      <div className="card-header">
                        <h4 className="">
                          Last quotes created or modified by you
                        </h4>
                      </div>
                      <div className="card-body table-responsive data-table">
                        <table
                          className="table table-bordered"
                          id="dataTable"
                          width="100%"
                          cellSpacing="0"
                        >
                          <thead>
                            <tr>
                              <th>Title</th>
                              <th>Last modified</th>
                              <th>Status</th>
                            </tr>
                          </thead>
                          <tfoot>
                            <tr>
                              <th>Title</th>
                              <th>Last modified</th>
                              <th>Status</th>
                            </tr>
                          </tfoot>
                          <tbody>
                            {isLoaded & (quotes.length !== 0) &&
                              quotes.map((quote, i) => {
                                if (i < 10) {
                                  if (quote.author.id === this.props.user.id) {
                                    return (
                                      <tr key={i}>
                                        <td data-label="Project name">
                                          <Link
                                            to={{
                                              pathname:
                                                "/quote/" +
                                                quote.version_quote_id
                                            }}
                                          >
                                            {quote.project_name}
                                          </Link>
                                        </td>
                                        <td data-label="Date modified">
                                          <Link
                                            to={{
                                              pathname:
                                                "/quote/" +
                                                quote.version_quote_id
                                            }}
                                          >
                                            {quote.date_modified}
                                          </Link>
                                        </td>
                                        <td data-label="Status">
                                          {quote.status === "active" && (
                                            <div className="status btn-drafting text">
                                              Drafting
                                            </div>
                                          )}
                                          {quote.status === "approved" && (
                                            <div className="status btn-approved text">
                                              Approved by client
                                            </div>
                                          )}
                                          {quote.status === "review" && (
                                            <div className="status btn-internal text">
                                              Ready for internal review
                                            </div>
                                          )}
                                          {quote.status === "non-active" && (
                                            <div className="status btn-archive text">
                                              Archive
                                            </div>
                                          )}
                                          {quote.status === "checked" && (
                                            <div className="status btn-reviewed text">
                                              Reviewed internally
                                            </div>
                                          )}
                                          {quote.status === "archive" && (
                                            <div className="status btn-archive text">
                                              Archive
                                            </div>
                                          )}
                                          {quote.status === "lost" && (
                                            <div className="status btn-lost text">
                                              Lost
                                            </div>
                                          )}
                                          {quote.status === "issued" && (
                                            <div className="status btn-issued text">
                                              Issued to client
                                            </div>
                                          )}
                                        </td>
                                      </tr>
                                    );
                                  }
                                }
                              })}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                )}
              </div>

              <div className="col-xl-4 col-lg-12">
                <div className="shadow last-modified">
                  <div className="card-header">
                    <h4>Last modified quotes</h4>
                  </div>

                  <div className="card-body table-responsive data-table">
                    <table
                      className="table table-bordered"
                      id="dataTable"
                      width="100%"
                      cellSpacing="0"
                      style={{ tableLayout: "fixed"}}
                    >
                      <thead>
                        <tr>
                          <th>Title</th>
                        </tr>
                      </thead>
                      <tfoot>
                        <tr>
                          <th>Title</th>
                        </tr>
                      </tfoot>
                      <tbody>
                        {isLoaded & (quotes.length !== 0) ? (
                          quotes.map((quote, i) => {
                            if (i < 5) {
                              return (
                                <tr key={i}>
                                  <td>
                                    <Link
                                      to={{
                                        pathname:
                                          "/quote/" + quote.version_quote_id
                                      }}
                                    >
                                      {quote.project_name}
                                    </Link>
                                  </td>
                                </tr>
                              );
                            }
                          })
                        ) : (
                          <div>Loading quotes..</div>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
  }
}

const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    return (
      <div className="custom-tooltip">
        <p className="label">{`${label} : ${commaFormatting(payload[0].value)}`}</p>
      </div>
    );
  }

  return null;
};

const mapStateToProps = state => {
  return {
    isAuthenticated: state.auth.isSignedIn,
    user: state.auth.user,
    quotes: state.quotes.quotes
  };
};

export default connect(mapStateToProps, {fetchQuotes})(Dashboard);
