import React from "react";
import { Link } from "react-router-dom";

//components
import ReactHtmlParser from "react-html-parser";
import StepPreview from "./blocks/previews/StepPreview";
import TermsPreview from "./blocks/previews/TermsPreview";
import Loading from "../components/Loading";
import Signature from "./blocks/Signature";

//redux
import {connect} from "react-redux";
import {saveQuote, createQuote} from "../../actions"

//assets
import { commaFormatting } from "../utils/commaformatting";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

class CompareQuotes extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoadedFirst: false,
      isLoadedSecond: false,
      quote: "",
      quote_version: ""
    };
  }

  componentDidMount() {
    this.fetchQuoteCurrent();
    this.fetchQuoteToCompare();
  }

  async fetchQuoteCurrent() {
    const { version } = this.props.match.params;
    if(version) {
    await fetch(
      process.env.REACT_APP_API + "/quote/get.php?id=" + version
    )
      .then(res => res.json())
      .then(json => {
        if (json.status === "success") {
          try {
            this.setState({
              isLoadedFirst: true,
              quote: json.data
            });
          } catch (e) {
            console.log(e);
          }
        }
      });
    }
  }

  async fetchQuoteToCompare() {
    const { id } = this.props.match.params;
    if(id) {
    await fetch(
      process.env.REACT_APP_API + "/quote/get.php?id=" + id + "&ver"
    )
      .then(res => res.json())
      .then(json => {
        if (json.status === "success") {
          try {
            this.setState({
              isLoadedSecond: true,
              quote_version: json.data
            });
          } catch (e) {
            console.log(e);
          }
        }
      });
    }
  }

  //create new quote
  handleCreate = async event => {
    if (event) event.preventDefault();

    await this.props.createQuote(this.props.user)

    if(this.props.created_quote_id) {
      this.setState({
        created: true
      });
      return this.props.created_quote_id;
    }
    else {
      return false;
    }

    // const create = await QuoteServices.createQuote(this.props.user)
    //   .then(res => res.json())
    //   .then(json => {
    //     if (json.status === "success") {
    //       this.setState({
    //         created: true
    //       });
    //       return json.data;
    //     }
    //   });
    // return create;
  };

  //saving quote
  handleSave = async (quote_parameter, event) => {
    if (event) event.preventDefault();
    console.log(quote_parameter);
    if (quote_parameter.project_name !== "") {
      await this.props.saveQuote(quote_parameter);
      if(this.props.saved_quote) {
        this.setState({
          isLoaded: true,
          saved: true,
          notification: true,
          last_saved: new Date()
        });
      }
      else {
        this.setState({
          error: true,
          notification: true
        });
      }
      // const save = await QuoteServices.saveQuote(quote_parameter)
      //   .then(res => res.json())
      //   .then(json => {
      //     if (json.status === "success") {
      //       this.setState({
      //         isLoaded: true,
      //         saved: true,
      //         notification: true,
      //         last_saved: new Date()
      //       });
      //     } else {
      //       this.setState({
      //         error: true,
      //         notification: true
      //       });
      //     }
      //   });
    }
  };

  restorerevision = async event => {
    event.preventDefault();
    const r = window.confirm(
      "Do you really want to make change current verison to this revision?"
    );
    if (r === true) {
      if (event) event.preventDefault();
      let new_version_quote = JSON.parse(
        JSON.stringify(this.state.quote_version)
      );
      console.log(
        "Creating quote: Q" +
          this.state.quote_version.reference.quotation_number
      );
      let create = await this.handleCreate();
      console.log("Saving new version of the quote: " + create);
      new_version_quote.reference.quotation_number = this.state.quote_version.reference.quotation_number;
      new_version_quote.has_revision = 0;
      new_version_quote.version_number =
        parseInt(this.state.quote.version_number) + 1; //get version number to get the latest version number
      new_version_quote.id = create;
      new_version_quote.last_modified_by = this.props.user;

      await this.handleSave(new_version_quote);

      console.log(new_version_quote);

      alert(
        "Previous version has been restored, you will be redirected to the new quote!"
      );

      this.props.history.push("/quote/" + new_version_quote.version_quote_id);
    }
  };

  render() {
    let { quote, isLoadedFirst, isLoadedSecond, quote_version } = this.state;
    return (
      <div className="quote_preview">
        <div className="quote_header">
          <div className="content">
            <Link to={"/revisedquoteslist/" + quote.version_quote_id}>
              <button
                className=""
                onClick={() => {
                  document.getElementById("accordionSidebar").style.display =
                    "flex";
                  document.getElementById("topnav").style.display = "flex";
                }}
              >
                <FontAwesomeIcon icon="arrow-left" />
              </button>
            </Link>
            <Link to={"/quote/" + quote.version_quote_id}>
              <button className="float-right" onClick={this.restorerevision}>
                <FontAwesomeIcon icon="history" /> <b>RESTORE THIS REVISION</b>
              </button>
            </Link>
          </div>
        </div>
        <div className="compare_quote">
          {!isLoadedFirst ? (
            <div className="quote_latest">
              <Loading gif="/images/stepworks.gif" message="Loading ..." />
            </div>
          ) : (
            <div className="quote_latest">
              <h1>Current version</h1>
              <div id="quote" className="content">
                <div className="stepworks_logo">
                  <img src="/images/logo.jpg" />
                </div>
                <div className="quote_details_wrapper">
                  <div className="quote_details">
                    <section>
                      <div className="row basic">
                        <table>
                          <tbody>
                            <tr>
                              <td>To:</td>
                              <td>
                                <span>
                                  {ReactHtmlParser(quote.company)}
                                </span>
                              </td>
                            </tr>
                            <tr>
                              <td />
                              <td>{ReactHtmlParser(quote.company_address)}</td>
                            </tr>
                            <tr>
                              <td>Attn:</td>
                              <td>{ReactHtmlParser(quote.attn)}</td>
                            </tr>
                            <tr className="height20"></tr>
                            <tr>
                              <td>Date:</td>
                              <td>{quote.date_created}</td>
                            </tr>
                            <tr>
                              <td>Reference:</td>
                              <td>
                                <span>
                                  Q
                                  {quote.reference.quotation_number}
                                  {parseInt(quote.revision_version) !== 0 &&
                                    "." + quote.revision_version}
                                  /{quote.reference.quotation_date}
                                </span>
                              </td>
                            </tr>
                            <tr className="height20"></tr>
                            <tr>
                              <td className="project_name">
                                <strong>Project:</strong>
                              </td>
                              <td>
                                <strong>{quote.project_name}</strong>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </section>
                  </div>
                  <div className="quote_title">
                    <h1 className="main">Quotation</h1>
                    <h1 className="second_main">Confidential</h1>
                  </div>
                </div>
                {quote.scope !== null && (
                  <div className="row intro">
                    <h2>Overview</h2>
                    <div>{ReactHtmlParser(quote.scope)}</div>
                  </div>
                )}
                {quote.objectives !== null && (
                  <div className="row objectives">
                    <h2>Objectives</h2>
                    <div>{ReactHtmlParser(quote.objectives)}</div>
                  </div>
                )}
                {quote.key_dates !== null && (
                  <div className="row keydates">
                    <h2>Key dates</h2>
                    <div>{ReactHtmlParser(quote.key_dates)}</div>
                  </div>
                )}
                {quote.content.length !== 0 && (
                  <section className="blocks">
                    <div className="row">
                      <h2>
                        Scope of work and fee
                        <span style={{ float: "right" }}>
                          {quote.currency.name}
                        </span>
                      </h2>
                      <div className="steps">
                        {quote.content !== null &&
                          quote.content.map((step, i) => {
                            return (
                              <StepPreview
                                key={i}
                                content={step}
                                currency={quote.currency}
                                step_index={step.order}
                                num_of_steps={quote.content.length}
                                compare={"true"}
                              />
                            );
                          })}
                      </div>
                    </div>
                  </section>
                )}
                <div className="project_management_fee no_page_break_pm">
                  <section className="pm">
                    <div className="row">
                      <div className="duties">
                        <div className="">
                          <h3>Project and account management</h3>
                          <div className="duties_item">
                            <strong>
                              Overall project and account management duties
                              charged at&nbsp;
                              <span className="pmcut">
                                {quote.duties_percentage}
                              </span>
                              % of<br></br>
                              the total creative fee{" "}
                              <span className="no_bold">
                                ({quote.currency.symbol}
                                {commaFormatting(quote.total)}
                                <span className="currency_symbol" />
                                <span className="subtotal" />)
                              </span>
                            </strong>

                            <div className="number">
                              {quote.currency.symbol}
                              {commaFormatting(
                                (quote.duties_percentage / 100) * quote.total
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                  {parseInt(quote.discount) !== 0 && (
                    <section className="discount_section">
                      <div className="row">
                        <div className="discount">
                          <div className="">
                            <h4>
                              <span>Discount</span>
                              <span className="price float-right">
                                <span className="currency_symbol">
                                  {quote.currency.symbol}
                                </span>
                                <span>{commaFormatting(quote.discount)}</span>
                              </span>
                            </h4>
                          </div>
                        </div>
                      </div>
                    </section>
                  )}
                  <section className="grandtotal">
                    <div className="row">
                      <h2>
                        Grand total&nbsp;
                        <span className="excl">
                          (excluding additional charges)
                        </span>
                        <span className="price">
                          <span className="currency_code">
                            {quote.currency.name}
                          </span>
                          <span>
                            {commaFormatting(
                              parseFloat(quote.duties_percentage / 100) *
                                parseFloat(quote.total) +
                                parseFloat(quote.total) -
                                parseFloat(quote.discount)
                            )}
                          </span>
                        </span>
                      </h2>
                    </div>
                  </section>
                </div>
                {quote.optional_items.length !== 0 && (
                  <div className="optional_items">
                    <section className="optional_items_section">
                      <h3 className="optional_items_heading no_margin_bottom">
                        Optional items
                      </h3>
                      <p className="optional_items_note">
                        (Does not include 15% account and project management
                        fee)
                      </p>
                      <div className="optional_items">
                        {quote.optional_items !== null &&
                          quote.optional_items.map((step, i) => {
                            return (
                              <StepPreview
                                key={i}
                                content={step}
                                currency={quote.currency}
                                step_index={step.order}
                                num_of_steps={quote.content.lenght}
                                compare={"true"}
                              />
                            );
                          })}
                      </div>
                    </section>
                  </div>
                )}
                <div className="terms_section">
                  <TermsPreview compare={"true"} terms={quote.terms} />
                </div>
                <div className="signature_section">
                  <Signature user={quote.author} />
                </div>
              </div>
            </div>
          )}
          {!isLoadedSecond ? (
            <div className="quote_version">
              <Loading gif="/images/stepworks.gif" message="Loading ..." />
            </div>
          ) : (
            <div className="quote_version">
              <h1>Previous version</h1>
              <div id="quote" className="content">
                <div className="stepworks_logo">
                  <img src="images/logo.jpg" />
                </div>
                <div className="quote_details_wrapper">
                  <div className="quote_details">
                    <section>
                      <div className="row basic">
                        <table>
                          <tbody>
                            <tr>
                              <td>To:</td>
                              <td>
                                <span>
                                  {ReactHtmlParser(quote_version.company)}
                                </span>
                              </td>
                            </tr>
                            <tr>
                              <td />
                              <td>
                                {ReactHtmlParser(quote_version.company_address)}
                              </td>
                            </tr>
                            <tr>
                              <td>Attn:</td>
                              <td>{ReactHtmlParser(quote_version.attn)}</td>
                            </tr>
                            <tr className="height20"></tr>
                            <tr>
                              <td>Date:</td>
                              <td>{quote_version.date_created}</td>
                            </tr>
                            <tr>
                              <td>Reference:</td>
                              <td>
                                <span>
                                  Q
                                  {quote_version.reference.quotation_number}
                                  {parseInt(quote_version.revision_version) !==
                                    0 && "." + quote_version.revision_version}
                                  /{quote_version.reference.quotation_date}
                                </span>
                              </td>
                            </tr>
                            <tr className="height20"></tr>
                            <tr>
                              <td className="project_name">
                                <strong>Project:</strong>
                              </td>
                              <td>
                                <strong>{quote_version.project_name}</strong>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </section>
                  </div>
                  <div className="quote_title">
                    <h1 className="main">Quotation</h1>
                    <h1 className="second_main">Confidential</h1>
                  </div>
                </div>
                {quote_version.scope !== null && (
                  <div className="row intro">
                    <h2>Overview</h2>
                    <div>{ReactHtmlParser(quote_version.scope)}</div>
                  </div>
                )}
                {quote_version.objectives !== null && (
                  <div className="row objectives">
                    <h2>Objectives</h2>
                    <div>{ReactHtmlParser(quote_version.objectives)}</div>
                  </div>
                )}
                {quote_version.key_dates !== null && (
                  <div className="row keydates">
                    <h2>Key dates</h2>
                    <div>{ReactHtmlParser(quote_version.key_dates)}</div>
                  </div>
                )}
                {quote_version.content.length !== 0 && (
                  <section className="blocks">
                    <div className="row">
                      <h2>
                        Scope of work and fee
                        <span style={{ float: "right" }}>
                          {quote_version.currency.name}
                        </span>
                      </h2>
                      <div className="steps">
                        {quote_version.content !== null &&
                          quote_version.content.map((step, i) => {
                            return (
                              <StepPreview
                                key={i}
                                content={step}
                                currency={quote_version.currency}
                                step_index={step.order}
                                num_of_steps={quote_version.content.length}
                                compare={"true"}
                              />
                            );
                          })}
                      </div>
                    </div>
                  </section>
                )}
                <div className="project_management_fee no_page_break_pm">
                  <section className="pm">
                    <div className="row">
                      <div className="duties">
                        <div className="">
                          <h3>Project and account management</h3>
                          <div className="duties_item">
                            <strong>
                              Overall project and account management duties
                              charged at&nbsp;
                              <span className="pmcut">
                                {quote_version.duties_percentage}
                              </span>
                              % of<br></br>
                              the total creative fee{" "}
                              <span className="no_bold">
                                ({quote_version.currency.symbol}
                                {commaFormatting(quote_version.total)}
                                <span className="currency_symbol" />
                                <span className="subtotal" />)
                              </span>
                            </strong>

                            <div className="number">
                              {quote_version.currency.symbol}
                              {commaFormatting(
                                (quote_version.duties_percentage / 100) *
                                  quote_version.total
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                  {quote_version.discount !== "0" && (
                    <section className="discount_section">
                      <div className="row">
                        <div className="discount">
                          <div className="">
                            <h4>
                              <span>Discount</span>
                              <span className="price float-right">
                                <span className="currency_symbol">
                                  {quote_version.currency.symbol}
                                </span>
                                <span>
                                  {commaFormatting(quote_version.discount)}
                                </span>
                              </span>
                            </h4>
                          </div>
                        </div>
                      </div>
                    </section>
                  )}
                  <section className="grandtotal">
                    <div className="row">
                      <h2>
                        Grand total&nbsp;
                        <span className="excl">
                          (excluding additional charges)
                        </span>
                        <span className="price">
                          <span className="currency_code">
                            {quote_version.currency.name}
                          </span>
                          <span>
                            {commaFormatting(
                              parseFloat(
                                quote_version.duties_percentage / 100
                              ) *
                                parseFloat(quote_version.total) +
                                parseFloat(quote_version.total) -
                                parseFloat(quote_version.discount)
                            )}
                          </span>
                        </span>
                      </h2>
                    </div>
                  </section>
                </div>
                {quote_version.optional_items.length !== 0 && (
                  <div className="optional_items">
                    <section className="optional_items_section">
                      <h3 className="optional_items_heading no_margin_bottom">
                        Optional items
                      </h3>
                      <p className="optional_items_note">
                        (Does not include 15% account and project management
                        fee)
                      </p>
                      <div className="optional_items">
                        {quote_version.optional_items !== null &&
                          quote_version.optional_items.map((step, i) => {
                            return (
                              <StepPreview
                                key={i}
                                content={step}
                                currency={quote_version.currency}
                                step_index={step.order}
                                num_of_steps={quote_version.content.lenght}
                                compare={"true"}
                              />
                            );
                          })}
                      </div>
                    </section>
                  </div>
                )}
                <div className="terms_section">
                  <section className="terms">
                    <TermsPreview
                      compare={"true"}
                      terms={quote_version.terms}
                    />
                  </section>
                </div>
                <div className="signature_section">
                  <section className="signature">
                    <Signature user={quote_version.author} />
                  </section>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    saved_quote: state.quotes.saved_quote,
    created_quote_id: state.quotes.created_quote_id
  }
}

export default connect(mapStateToProps, {saveQuote, createQuote})(CompareQuotes);
