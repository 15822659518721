import history from "../history";
import QEAxios from "../axios";
import BAxios from "../axios/axios-block";
import EAxios from "../axios/axios-email";
import UAxios from "../axios/axios-user";
import CAxios from "../axios/axios-currency";

import { AuthorizationService } from "../quote-engine/utils/auth";

import {
  SIGN_IN,
  SIGN_OUT,
  FETCH_USER,
  FETCH_USERS,
  FETCH_QUOTES,
  FETCH_REVISED_QUOTES,
  FETCH_QUOTE_VERSIONS,
  FETCH_QUOTES_BY_USERID,
  FETCH_QUOTES_BY_USERID_AND_APPROVED,
  FETCH_QUOTE_BY_ID,
  CREATE_QUOTE,
  UPDATE_QUOTE,
  UPDATE_QUOTE_LOCK,
  FETCH_TEMPLATES,
  FETCH_TEMPLATE_BY_ID,
  UPDATE_TEMPLATE,
  CREATE_TEMPLATE,
  DELETE_TEMPLATE,
  FETCH_ITEM_TEMPLATES,
  FETCH_STEP_TEMPLATES,
  FETCH_TERMS_TEMPLATES,
  SHOW_HIDE_TOPNAV,
  SHOW_HIDE_SIDEBAR,
  SHOW_HIDE_FOOTER,
  QUOTE_LOCK_CHECK,
  FILTER_SEARCH,
  FILTER_ARRAY,
  FILTER_RESET,
  FILTER_INIT,
  UPDATE_USER,
  FETCH_USER_FROM_DB,
  LATEST_QUOTATION_NUMBER,
  SEND_EMAIL,
  SHOW_HIDE_DISCOUNT_PERCENTAGE,
  FETCH_CURRENCIES
} from "./types";

export const signIn = (user) => {
  return {
    type: SIGN_IN,
    payload: user,
  };
};
export const signOut = () => (dispatch) => {
  dispatch({
    type: SIGN_OUT,
  });
  history.push("/login");
};

export const fetchUser = () => (dispatch) => {
  const authenticated = AuthorizationService.isAuthenticated();
  const user = AuthorizationService.getUser();
  dispatch({
    type: FETCH_USER,
    isSignedIn: authenticated,
    payload: user,
  });
  return user;
};

export const fetchCurrencies = () => async (dispatch) => {
  const response = await CAxios.get(`get.php`);
  console.log(response);
  dispatch({
    type: FETCH_CURRENCIES,
    payload: response.data.data,
  });
  return response;
};

export const fetchUsers = () => async (dispatch) => {
  const response = await UAxios.get(`get.php?allUsers`);
  dispatch({
    type: FETCH_USERS,
    payload: response.data.data,
  });
  return response;
};

export const fetchUserFromDB = (id) => async (dispatch) => {
  const response = await UAxios.get(`get.php?id=${id}`);
  if (response.data) {
    window.localStorage.setItem("user", JSON.stringify(response.data.data)); //updating user in localstorage
  }
  dispatch({
    type: FETCH_USER_FROM_DB,
    payload: response.data.data,
    userId: id,
  });
};

export const fetchQuotes = () => async (dispatch) => {
  const response = await QEAxios.get("list.php");
  dispatch({
    type: FETCH_QUOTES,
    payload: response.data.data,
  });
};

export const fetchQuotesByUserId = (id) => async (dispatch) => {
  const response = await QEAxios.get(`list.php?id=${id}`);
  dispatch({
    type: FETCH_QUOTES_BY_USERID,
    payload: response.data.data,
    userId: id,
  });
  return response;
};

export const fetchRevisedQuotesByUserId = (id) => async (dispatch) => {
  const response = await QEAxios.get(`list.php?id=${id}`);
  dispatch({
    type: FETCH_QUOTES_BY_USERID,
    payload: response.data.data,
    userId: id,
  });
};

export const fetchQuotesByUserIdAndApproved = (id) => async (dispatch) => {
  const response = await QEAxios.get(`list.php?id=${id}`);
  dispatch({
    type: FETCH_QUOTES_BY_USERID_AND_APPROVED,
    payload: response.data.data,
    userId: id,
  });
};

export const fetchRevisedQuotes = () => async (dispatch) => {
  const response = await QEAxios.get(`list.php?revised`);
  dispatch({
    type: FETCH_REVISED_QUOTES,
    payload: response.data.data,
  });
};

export const fetchQuoteVersions = (id) => async (dispatch) => {
  const response = await QEAxios.get(`list.php?version=${id}`);
  dispatch({
    type: FETCH_QUOTE_VERSIONS,
    payload: response.data.data,
  });
};

export const fetchQuoteById = (id) => async (dispatch) => {
  const response = await QEAxios.get(`get.php?id=${id}`);
  dispatch({
    type: FETCH_QUOTE_BY_ID,
    payload: response.data.data,
    quoteId: id,
  });
  return response;
};

export const createQuote = (id) => async (dispatch) => {
  const response = await QEAxios.post(`create.php?author=${id}`);
  dispatch({
    type: CREATE_QUOTE,
    payload: response.data.data,
  });
  return response;
};

export const saveQuote = (quote) => async (dispatch) => {
  const response = await QEAxios.post(`save.php?id=${quote.id}`, {
    body: JSON.stringify({
      quote: quote,
    }),
  });
  dispatch({
    type: UPDATE_QUOTE,
    payload: response.data.data,
  });
  return response;
};

export const saveQuoteLock = (quote) => async (dispatch) => {
  const response = await QEAxios.post(`save.php?id=${quote.id}&lock`, {
    body: JSON.stringify({
      quote: quote,
    }),
  });
  dispatch({
    type: UPDATE_QUOTE_LOCK,
    payload: response.data.data,
  });
  return response;
};

export const latestQuotationNumber = () => async (dispatch) => {
  const response = await QEAxios.get(`list.php?quote_number`);
  dispatch({
    type: LATEST_QUOTATION_NUMBER,
    payload: response.data.data,
  });
  return response;
};

export const fetchTemplates = () => async (dispatch) => {
  const response = await BAxios.get("/list.php?all");
  dispatch({
    type: FETCH_TEMPLATES,
    payload: response.data.data,
  });
};

export const fetchTemplateById = (id) => async (dispatch) => {
  const response = await BAxios.get(`get.php?id=${id}`);
  dispatch({
    type: FETCH_TEMPLATE_BY_ID,
    payload: response.data.data,
    templateId: id,
  });
};

export const fetchTemplatesByType = (type) => async (dispatch) => {
  const response = await BAxios.get(`list.php?type=${type}`);
  if (type === "item") {
    dispatch({
      type: FETCH_ITEM_TEMPLATES,
      payload: response.data.data,
    });
  } else if (type === "terms") {
    dispatch({
      type: FETCH_TERMS_TEMPLATES,
      payload: response.data.data,
    });
  }
  else {
    dispatch({
      type: FETCH_STEP_TEMPLATES,
      payload: response.data.data,
    });
  }
};

export const saveTemplate = (template) => async (dispatch) => {
  const response = await BAxios.post(`/save.php?id=${template.id}`, {
    body: JSON.stringify({
      template: template,
    }),
  });
  dispatch({
    type: UPDATE_TEMPLATE,
    payload: response.data.data,
  });
};
export const createTemplate = (template) => async (dispatch) => {
  const response = await BAxios.post(`/create.php`, {
    body: JSON.stringify({
      template: template,
    }),
  });
  dispatch({
    type: CREATE_TEMPLATE,
    payload: response.data.data,
  });
};

export const sendEmail = (quote) => async (dispatch) => {
  const response = await EAxios.post(`/email.php`, {
    body: JSON.stringify({
      quote: quote,
    }),
  });
  dispatch({
    type: SEND_EMAIL,
    payload: response.data,
  });
  return response.data;
};

export const deleteTemplate = (id) => {
  return {
    type: DELETE_TEMPLATE,
    payload: id,
  };
};

export const showHideTopNav = () => (dispatch, getState) => {
  dispatch({
    type: SHOW_HIDE_TOPNAV,
    payload: !getState().global.topNavShow,
  });
};

export const showHideSidebar = () => (dispatch, getState) => {
  dispatch({
    type: SHOW_HIDE_SIDEBAR,
    payload: !getState().global.sidebarShow,
  });
};

export const showHideFooter = () => (dispatch, getState) => {
  dispatch({
    type: SHOW_HIDE_FOOTER,
    payload: !getState().global.footerShow,
  });
};

export const handlePercentageText = () => (dispatch, getState) => {
  let textClass = getState().global.discountPercentageShow;
  if(textClass === "visible") {
    textClass = 'hide';
  }
  else {
    textClass = 'visible';
  }
  console.log(textClass);
  dispatch({
    type: SHOW_HIDE_DISCOUNT_PERCENTAGE,
    payload: textClass,
  });
};


export const quoteLockCheck = (quote_id) => async (dispatch) => {
  const response = await QEAxios.get(`/get.php?id=${quote_id}&lockCheck`);
  dispatch({
    type: QUOTE_LOCK_CHECK,
    payload: response.data.data,
  });
  return response;
};

export const filterInit = () => (dispatch, getState) => {
  let filterResults = getState().quotes.quotes;
  const { filterSearch, filterArray } = getState().filter;

  if (filterSearch.length !== 0) {
    filterResults = filterResults.filter(function (arr) {
      return (
        arr.project_name.toLowerCase().search(filterSearch.toLowerCase()) !== -1
      );
    });
  }

  if (filterArray.length !== 0) {
    filterResults = filterResults.filter(function (arr, i) {
      return filterArray.includes(arr.status);
    });
  }
  dispatch({
    type: FILTER_INIT,
    payload: filterResults,
  });
};

export const filterSearch = (query) => (dispatch, getState) => {
  let filterResults = getState().quotes.quotes;
  if (query.length !== 0) {
    filterResults = filterResults.filter(function (arr) {
      let ref = arr.reference.quotation_number.toString();
      let projectName = arr.project_name.toLowerCase();
      let company = arr.company.toLowerCase();
      let queryLower = query.toLowerCase();
      return (
        projectName.includes(queryLower) ||
        company.includes(queryLower) ||
        ref.includes(queryLower)
      );
    });
  }
  dispatch({
    type: FILTER_SEARCH,
    payload: {
      query,
      filterResults,
    },
  });
};

export const filterArray = (options) => (dispatch, getState) => {
  let filterResults = getState().quotes.quotes;
  if (options.length !== 0) {
    filterResults = filterResults.filter(function (arr, i) {
      return options.includes(arr.status);
    });
  }
  dispatch({
    type: FILTER_ARRAY,
    payload: {
      options,
      filterResults,
    },
  });
};

export const filterReset = (array) => (dispatch, getState) => {
  let filterResults = getState().quotes.quotes;
  dispatch({
    type: FILTER_RESET,
    payload: filterResults,
  });
};

export const saveUser = (user) => async (dispatch) => {
  const response = await UAxios.post(`/save.php?id=${user.id}`, {
    body: JSON.stringify({
      user: user,
    }),
  });
  dispatch({
    type: UPDATE_USER,
    payload: response.data.data,
  });
};
