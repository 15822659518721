import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { fetchCurrencies} from "../../actions";

function CurrencySelector(props) {

  const {quote} = props;

  const [currencies, setCurrencies] = useState([]);

  useEffect(() => {
      const fetchCurrencies = async () => {
        const data = await props.fetchCurrencies();
        if(data.statusText === "OK") {
          if(data.data.data) {
            setCurrencies(data.data.data);
          }
        }  
      }
      fetchCurrencies().catch(console.error);
  }, [])

  let handleCurrency = (e) => {
    let index = e.target.selectedIndex;
    let name = e.target.childNodes[index].textContent;
    let symbol = e.target.childNodes[index].dataset.symbol;
    let rate = parseFloat(e.currentTarget.value);

    props.handleChangeCurrency(name, symbol, rate);
  }

  return (
    <div className="curr">
      <select
        data-ref="currency"
        id="currency"
        onChange={handleCurrency}
      >
        {currencies.map((currency) => {
          return (
            <option
              value={currency.rate}
              data-symbol={currency.symbol}
              data-rate={currency.rate}
              selected={quote.currency.name === currency.name} 
              key={currency.id}
            >
              {currency.name}
            </option>
          )
        })}
      </select>
  </div>
  );
}

const mapStateToProps = state => {
  return {
    currencies: state.global.currencies,
  };
};

export default connect(mapStateToProps, { fetchCurrencies })(CurrencySelector);
