import React from "react";
import { commaFormatting, commaFormattingWithDecimals } from "../utils/commaformatting";

function GrandTotal({ quote, title, subtitle, grandTotal, entity, beforeTax, handleChangeNumber }) {
  const TotalNoDiscount = parseFloat(quote.duties_percentage / 100) * parseFloat(quote.total) +
                          parseFloat(quote.total);

  const SingaporeGST = (TotalNoDiscount - parseFloat(quote.discount)) * 0.09;
  const finalGrandTotal = quote.entity === "sg" && !beforeTax ? TotalNoDiscount - parseFloat(quote.discount) + SingaporeGST : TotalNoDiscount - parseFloat(quote.discount);

  const formattedTotal = !grandTotal ? TotalNoDiscount : entity === "sg" ? SingaporeGST : finalGrandTotal;

  const formattedCurrency = quote.currency.name !== "HKD" ?
                            "HKD" + commaFormatting(formattedTotal / quote.currency.rate) : "";

  return (
    <section className="grandtotal">
      <div className="row">
        <h2>
          {title}&nbsp;
          <span className="sub">{subtitle}</span>
          <span className="price">
            <span className="currency_code">
              {(entity !== "sg" && !beforeTax) ? quote.currency.name : quote.currency.symbol}
            </span>
            <span
              className="number"
              contentEditable={(entity !== "sg" && !beforeTax)}
              data-ref={grandTotal ? "grand-total-change-tmp" : ''}
              suppressContentEditableWarning={true}
              onBlur={(entity !== "sg" && !beforeTax) ? handleChangeNumber : () => {}}
              data-converted={formattedCurrency}
            >
              {quote.entity === "sg" ? commaFormattingWithDecimals(formattedTotal) : commaFormatting(formattedTotal)}
            </span>
          </span>
        </h2>
      </div>
    </section>
  );
}

export default GrandTotal;
