import React, {useRef} from "react";
import ReactQuill from "react-quill";

function IntroComponent(props) {

  const quillRef = useRef(null);
  const type = props.type;

  let height = props.placeholder.length > 160 && props.scope.length < 12 ? {height: '60px'} : {};

  return (
    <section>
        <div className="row intro">
        {props.name && <h2>{props.name}</h2>}
            <ReactQuill
            ref={quillRef}
            theme="bubble"
            scrollingContainer='html'
            modules={props.modules}
            value={props.scope}
            onChange={props.handleChangeQuill(type, quillRef)}
            placeholder={props.placeholder}
            style={height}
            />
        </div>
    </section>
  );
}

export default IntroComponent;